import { makeStyles } from "@mui/styles";
import { ArrowDropDown } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useRef, useState } from "react";
import styled from "@emotion/styled";
import { generate_tempid, parse_db_timestamp } from "../../../../tools";
import { _TableInput } from "./base";

// Imports for complex fields
import SelectTableField from "./select";
import MultiSelectTableField from "./multiselect";

// Then export those fields
export { SelectTableField as SelectCell };
export { MultiSelectTableField as MultiSelectCell };

export const TextCell = ({ data, setChange, disabled, type }) => {
  return (
    <TableInput
      value={data ?? ""}
      onChange={(e) =>
        setChange((ex) => {
          if (type === "number") {
            return e.target.value === ""
              ? e.target.value
              : parseFloat(e.target.value);
          }
          return e.target.value;
        })
      }
      disabled={disabled}
      type={type ?? "text"}
    />
  );
};

// export const SelectCell = ({ data, setChange, options, disabled }) => {
//   return (
//     <TableSelect
//       value={data ?? ""}
//       onChange={(e) => setChange((ex) => e.target.value)}
//     >
//       <option value=""></option>
//       {Object.keys(options ?? {}).map((key) => (
//         <option key={key} value={key}>
//           {options[key]}
//         </option>
//       ))}
//     </TableSelect>
//   );
// };

export const DateCell = ({ data, setChange, disabled }) => {
  const st = useDateCellStyle();
  const adst = useDateCellAdornmentStyle();

  let convertedDate = parse_db_timestamp(data);

  return (
    <DatePicker
      //  Actual Properties
      format="dd/MM/yyyy"
      value={convertedDate}
      onChange={(date) => setChange((ex) => date)}
      error={false}
      helperText={null}
      renderInput={(params) => <TextField {...params} variant="standard" />}
      // Properties to make the style palatable
      InputProps={{
        classes: st,
        error: false,
        disableUnderline: true,
      }}
      InputAdornmentProps={{
        classes: adst,
      }}
    />
  );
};

export const CheckboxCell = ({ data, setChange, disabled }) => {
  return (
    <TableBooleanContainer>
      <TableBoolean
        type="checkbox"
        checked={data ?? false}
        onChange={(e) => setChange((ex) => !ex)}
        disabled={disabled}
      />
    </TableBooleanContainer>
  );
};

export const RadioCell = ({ data, setChange, options, disabled }) => {
  // Generate a unifying "name" for the input group which is unique (and irrelevant)
  const radioName = generate_tempid();

  return (
    <TableBooleanContainer>
      {Object.keys(options ?? {}).map((option) => (
        <TableBooleanContainer key={option}>
          <TableBoolean
            type="radio"
            name={radioName}
            value={option}
            checked={data === option}
            onChange={(e) => setChange((ex) => option)}
          />
        </TableBooleanContainer>
      ))}
    </TableBooleanContainer>
  );
};

const TableInput = styled.input`
  ${_TableInput}
`;

const TableSelect = styled.select`
  padding: 0;
  margin: 0;
  border: none;

  height: 100%;
  width: 100%;
  font-size: 14px;

  text-overflow: ellipsis;
  cursor: pointer;
`;

// Style to clean up date picker
const useDateCellAdornmentStyle = makeStyles({
  root: {
    "& button": {
      padding: "0 10px 0 0",
    },
    "& svg": {
      fontSize: "1.3rem",
    },
  },
});

const useDateCellStyle = makeStyles({
  root: {
    fontSize: "14px",
    width: "130px",
  },
  input: {
    padding: "0 0 0 5px",
  },
});

const TableBooleanContainer = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const TableBoolean = styled.input``;
