import React, { useState, useEffect } from "react";
import q from "query-it";
import { InteractiveTable } from "../../../ui/table";
import { Tile } from "../../../ui/containers";

export default () => {
  const [errors, setErrors] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    const unsub = q.devlogger.listen((errors) => {
      if (mounted) setErrors(errors);
    });

    return () => {
      unsub();
      mounted = false;
    };
  }, []);

  return (
    <Tile>
      <InteractiveTable data={errors} columns={errorTableColumns} />
    </Tile>
  );
};

// time and date, user, message
const errorTableColumns = [
  { name: "Time", index: "timestamp" },
  { name: "User", index: "user" },
  { name: "Message", index: "error" },
];
