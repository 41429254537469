import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Navigate } from "react-router-dom";

import q from "query-it";
import { Button2 } from "./buttons";

export default class IntelligentErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  refreshFromError() {
    // Note: If this doesn't provide sufficient error recovery, we can change the KEY of each child manually :)
    this.setState({ hasError: false, error: undefined });
  }

  render() {
    if (this.state.hasError) {
      // If we hit an error, log it!!
      q.devlogger.add({
        type: "crash",
        area: this.props.area ?? "", // Should this not be `this.props.area`? It's never passed in as `appArea={...}`
        url: window.location.href,
        error: this.state.error?.message ?? "",
        boundcode: this.props.boundcode ?? "",
        user: this.props.userId ?? "",
        timestamp: new Date(),
      });

      if (this.props.clearParams) {
        // Clear parameters to avoid error bound
        this.props.history?.replace({ search: undefined });
      }

      if (this.props.redirect) {
        return <Navigate to={this.props.redirect} />;
      }

      return (
        <MainDiv style={{ flexDirection: "column" }}>
          <Typography sx={{ color: "text.primary" }}>
            An error has occurred in this component of the application. Please
            try again later.
          </Typography>
          <Button2
            label="Try Again"
            onClick={this.refreshFromError.bind(this)}
          />
        </MainDiv>
      );
    }

    return this.props.children ?? null;
  }
}

const MainDiv = styled.div`
  display: flex;
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
`;
