// Validation types for each field type
export const VALIDATIONS_BY_TYPE = {
  number: {
    number_range: { type: "string", message: "Number not in range" },
  },
  string: {
    character_range: {
      type: "minMax",
      message: "Invalid number of characters",
    },
  },
  textarea: {
    character_range: {
      type: "minMax",
      message: "Invalid number of characters",
    },
  },
  date: {
    date_range: { type: "dateTimeRange", message: "Invalid date selection" },
    date_limit: { type: "minMax", message: "Invalid date selection" },
  },
  time: {
    time_range: { type: "dateTimeRange", message: "Invalid time selection" },
  },
  multiselect: {
    ms_count_range: {
      type: "minMax",
      message: "Number of selections not in range",
    },
  },
  boxset: {
    ms_count_range: {
      type: "minMax",
      message: "Number of selections not in range",
    },
  },
  table: {
    tbl_entry_range: {
      type: "minMax",
      message: "Number of entries (rows) not in range",
    },
  },
  tableadv: {
    tbl_entry_range: {
      type: "minMax",
      message: "Number of entries (rows) not in range",
    },
  },
  files: {
    allowed_extensions: { type: "string", message: "Invalid file extension" },
  },
  photos: {
    allowed_extensions: { type: "string", message: "Invalid file extension" },
  },
  userlist: {
    userlist_count_range: {
      type: "minMax",
      message: "Number of selections not in range",
    },
  },
};

// The following functions generate the validation function (as a string) for certain fields
export const funcGenerator = (validationId, constraint, fieldType) => {
  // maybe don't need field type and copy isConstraintValid function
  let func = "return true";
  switch (fieldType) {
    case "number":
      if (validationId === "number_range") {
        func = numberRange(constraint);
      }
      break;
    case "string":
    case "textarea":
      if (validationId === "character_range") {
        func = characterRange(constraint);
      }
      break;
    case "date":
      if (validationId === "date_range") {
        func = dateRange(constraint);
      } else if (validationId === "date_limit") {
        func = dateLimit(constraint);
      }
      break;
    case "time":
      if (validationId === "time_range") {
        func = timeRange(constraint);
      }
      break;
    case "multiselect":
    case "boxset":
      if (validationId === "ms_count_range") {
        func = selectionCount(constraint);
      }
      break;
    case "table":
    case "tableadv":
      if (validationId === "tbl_entry_range") {
        func = tableEntryRange(constraint, fieldType);
      }
      break;
    case "files":
    case "photos":
      if (validationId === "allowed_extensions") {
        func = fileExtension(constraint, fieldType);
      }
      break;
    case "userlist":
      if (validationId === "userlist_count_range") {
        // I THINK THIS IS THE FUNCTION, PLS VERIFY
        func = selectionCount(constraint);
      }
      break;
    default:
      return func;
  }
  return func;
};

// Checks if the constraint or all of its attributes are undefined, null, or empty ([], {}, "")
// Does not check for semantics (i.e. if number range makes mathematical sense)
// Certain attributes (eg. min/max) may be left with undefined/null values. This will be handled by the function generators.
export const isConstraintValid = (validationId, constraint) => {
  switch (validationId) {
    case "number_range":
    case "allowed_extensions":
      // invalid if empty string
      return !!constraint;
    case "character_range":
    case "date_limit":
    case "ms_count_range":
    case "tbl_entry_range":
    case "userlist_count_range":
      // valid if EITHER min or max are valid
      return constraint.min !== undefined || constraint.max !== undefined;
    case "date_range":
    case "time_range":
      // NOTE: CONSTRAINT CAN BE {} OR a combination of {start:null, end:null}
      // valid if any element of array has a valid start or end value
      return constraint.some(
        (c) => Object.keys(c).length !== 0 && c.start != null && c.end != null
      );

    default:
      // constraint invalid by default
      return false;
  }
};

// does not check if constraint is semantically correct
const numberRange = (constraint) => {
  // replace OR and AND
  constraint = constraint.replaceAll("AND", "&&").replaceAll("OR", "||");
  const conditional = [...constraint]
    .flatMap((c) => ([">", "<"].includes(c) ? ["data", c] : c))
    .join("");
  return `return ${conditional}`;
};

const characterRange = (constraint) => {
  // only handles min chars constraint because max chars is handled by the MUI field itself
  return `return ${constraint.min} == null || data.length >= ${constraint.min}`;
};

// Does not use exact dates (sets time to midnight 00:00:00)
const dateRange = (constraint) => {
  let func = "";
  const cleanData = removeTimeFromDate("data");
  constraint.forEach((c) => {
    if (c?.start) {
      func += `(${cleanData}).getTime() - (new Date(${c.start})).getTime() >= 0`;
      func += c?.end ? " && " : "";
    }
    if (c?.end) {
      func += `(${cleanData}).getTime() - (new Date(${c.end})).getTime() <= 0`;
    }
    // add OR to separate constraints
    if (c?.start || c?.end) {
      func += " || ";
    }
  });

  return func ? `return ${func}` : "return true";
};

// Only uses the time, with no timezone
const timeRange = (constraint) => {
  let func = "";
  constraint.forEach((c) => {
    if (c?.start) {
      func += `(${minutesSinceMidnight("data")}) - ${minutesSinceMidnight(
        `(new Date(${c.start}))`
      )} >= 0`;
      func += c?.end ? " && " : "";
    }
    if (c?.end) {
      func += `(${minutesSinceMidnight("data")}) - ${minutesSinceMidnight(
        `(new Date(${c.end}))`
      )} <= 0`;
    }
    // add OR to separate constraints
    if (c?.start || c?.end) {
      func += " || ";
    }
  });

  return func ? `return ${func}` : "return true";
};

// Currently uses exact dates. Alternatively, if we only care about the DAY and not the time, we can just
//    set the times to midnight (00:00:00) before running getTime() and the calculation
const dateLimit = (constraint) => {
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const constraintComparison = `((${parseTimestamp(
    "data"
  )}).getTime() - (new Date()).getTime()) / ${millisecondsPerDay}`;
  let func = "";
  if (constraint?.min) {
    func += `${constraintComparison} >= ${constraint.min}`;
    func += constraint?.max ? " && " : "";
  }
  if (constraint?.max) {
    func += `${constraintComparison} <= ${constraint.max}`;
  }
  return func ? `return ${func}` : "return true";
};

const tableEntryRange = (constraint, type) => {
  let func = "";
  if (constraint?.min) {
    func += `(data${type === "tableadv" ? ".body?" : ""}.length ?? 0) >= ${
      constraint.min
    }`;
    func += constraint?.max ? " && " : "";
  }
  if (constraint?.max) {
    func += `(data${type === "tableadv" ? ".body?" : ""}.length ?? 0) <= ${
      constraint.max
    }`;
  }
  return func ? `return ${func}` : "return true";
};

const selectionCount = (constraint) => {
  let func = "";
  if (constraint?.min) {
    func += `data.length >= ${constraint.min}`;
    func += constraint?.max ? " && " : "";
  }
  if (constraint?.max) {
    func += `data.length <= ${constraint.max}`;
  }
  return func ? `return ${func}` : "return true";
};

const fileExtension = (constraint, type) => {
  const types = constraint
    .toLowerCase()
    .split(",")
    .map((type) => `'${type.trim()}'`)
    .toString();

  let dataCheckFunc = "true";
  if (type === "files") {
    dataCheckFunc = `data?.data?.every((file) => [${types}].includes(file.fileType))`;
  } else if (type === "photos") {
    dataCheckFunc = `data.every((file) => [${types}].includes(file.fileType))`;
  }
  return `return ${dataCheckFunc}`;
};

const removeTimeFromDate = (dateVarName) => {
  return `new Date(${dateVarName}.getFullYear(), ${dateVarName}.getMonth(), ${dateVarName}.getDate())`;
};

const minutesSinceMidnight = (dateVarName) => {
  return `${dateVarName}.getHours() * 60 + ${dateVarName}.getMinutes()`;
};

const parseTimestamp = (timestampName) => {
  // this is just parse_db_timestamp() in tools but as a ternary and without the null check
  return `typeof ${timestampName} === "object" && ${timestampName}.nanoseconds !== undefined ? ${timestampName}.toDate() : (typeof ${timestampName} === "number" ? new Date(${timestampName}) : ${timestampName})`;
};
