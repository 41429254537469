/**
 * This file defines the panel styles outside of those defined for Mui. This mostly defines chart colors and a few minor changes that are otherwise necessary
 */

export default {
  chartDefaults: [
    "#4c48a7",
    "#6e56cf",
    "#a897e9",
    "#29825c",
    "#4ca17a",
    "#8ad1b3",
  ],
  tileBackground: "#dfdfdf",
  text: "black",
};
