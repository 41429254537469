import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { IconButton, Tooltip, TextField } from "@mui/material";
import {
  Add,
  Delete,
  Edit,
  Check,
  ArrowUpward,
  ArrowDownward,
} from "@mui/icons-material";

import Draggable from "react-draggable";

import EditableField from "./resolver";

import { QuerySectionHeader } from "../../query";

import {
  FloatingFieldEditButtons,
  InputContainer,
  InputShim,
  DropInputContainer,
} from "./tools";
import { useHotkey } from "../../../../../hooks/system";

import {
  INPUT_TYPES,
  PERSONAL_FIELD_EDITOR_TYPES,
} from "../../../../../common/inputs";

const DRAG_FIELD_DIMENSIONS = { width: 120, height: 80 };

/**
 * The primary function of this component is to determine the type of field and provide the
 * EDIT version of the component.
 *
 * No data entry, and we'll render a special component wrapper
 */
export const EditableFieldResolver = React.memo(
  ({
    field,
    onFieldChange,
    onFieldDelete,
    onFieldDuplicate,
    onFieldDrag,
    topIndex,
    rowIndex,
    secIndex,
    isBeingDragged,
    custom,
  }) => {
    const [showEditButtons, setshowEditButtons] = useState(false);
    const [dragStartOffset, setDragStartOffset] = useState(undefined);

    // Field state management
    const fieldState = useRef(undefined);

    // Field state auto-modifier
    useEffect(() => {
      if (fieldState.current) {
        // fieldState has a previous value, let's see if we need to update it
      }
      // The last thing we do is modify the field state
      fieldState.current = field;
    }, [field]);

    //draggable functions
    const handleOnStart = (e, data) => {
      const { x, y } = data.node.getBoundingClientRect();

      onFieldDrag(e, data, field, topIndex, rowIndex, secIndex);
      setDragStartOffset({
        x: e.clientX - x - DRAG_FIELD_DIMENSIONS.width / 2,
        y: e.clientY - y - DRAG_FIELD_DIMENSIONS.height / 2,
      });
    };

    const handleOnStop = (e, data) => {
      onFieldDrag(e, data, field, topIndex, rowIndex, secIndex);
      setDragStartOffset(undefined);
    };

    // Now let's build the actual field based on its resolution
    return (
      <div style={{ position: "relative", padding: "10px" }}>
        <Draggable
          handle="strong"
          onStart={(e, data) => handleOnStart(e, data)}
          onStop={(e, data) => handleOnStop(e, data)}
          position={{ x: 0, y: 0 }} //snap back to original position
          positionOffset={
            dragStartOffset
              ? { x: dragStartOffset.x, y: dragStartOffset.y }
              : undefined
          }
        >
          {dragStartOffset ? (
            <InputContainer
              style={{
                zIndex: -100,
                border: "1px solid grey",
                borderRadius: "5px",
                width: `${DRAG_FIELD_DIMENSIONS.width}px !important`,
                height: DRAG_FIELD_DIMENSIONS.height,
              }}
            >
              <EditableField isDragPreview={true} field={field} />
            </InputContainer>
          ) : (
            <InputContainer
              onMouseEnter={() => setshowEditButtons(true)}
              onMouseLeave={() => setshowEditButtons(false)}
              style={{
                border: isBeingDragged ? "1px solid grey" : "1px dashed grey",
                borderRadius: isBeingDragged ? "5px" : "0px",
                width: isBeingDragged ? DRAG_FIELD_DIMENSIONS.width : "100%",
                height: isBeingDragged ? DRAG_FIELD_DIMENSIONS.height : "100%",
                zIndex: 1,
              }}
            >
              <EditableField
                isDragPreview={isBeingDragged}
                field={field}
                onFieldChange={onFieldChange}
                custom={custom}
              />
              {showEditButtons && !isBeingDragged && (
                <FloatingFieldEditButtons
                  onFieldDelete={onFieldDelete}
                  onFieldChange={onFieldChange}
                  onFieldDuplicate={onFieldDuplicate}
                  // custom indicates that we're in the Personal Field Editor
                  // - if so, limit the input types available
                  types={custom ? PERSONAL_FIELD_EDITOR_TYPES : INPUT_TYPES}
                />
              )}
            </InputContainer>
          )}
        </Draggable>
      </div>
    );
  },
  discardEventMemoize
);

export const DragHoverPlaceHolder = ({
  hidden,
  setIsDroppable,
  setDropData,
  topIndex,
  rowIndex,
  secIndex,
  nest,
  fieldBeingDragged,
}) => {
  const MAX_FIELDS_PER_ROW = 4;
  const [hiddenActive, setHiddenActive] = useState(false);
  const hoverTimeout = useRef(false);

  const triggerShowNFP = () => {
    hoverTimeout.current = true;
    setTimeout(() => {
      if (hoverTimeout.current) {
        setHiddenActive(true);
        setIsDroppable(true);
        setDropData({
          topIndex: topIndex,
          rowIndex: rowIndex,
          secIndex: secIndex,
        });
      }
    }, 400);
  };

  const inBetweenRows = secIndex === -1 || rowIndex === -1;

  // Limit maximum fields per row
  if (nest !== undefined) {
    const limit = nest.every((fld) => fld.id !== fieldBeingDragged.id)
      ? MAX_FIELDS_PER_ROW
      : MAX_FIELDS_PER_ROW + 1;
    if (nest.length >= limit) {
      return;
    }
  }

  if (hidden && !hiddenActive) {
    return (
      <DropInputContainer
        onMouseEnter={triggerShowNFP}
        onMouseLeave={() => (hoverTimeout.current = false)}
        style={{
          alignItems: "center",
          justifyContent: "center",
          maxWidth: inBetweenRows ? "100%" : "35px",
          maxHeight: inBetweenRows ? "20px" : "none",
          cursor: "pointer",
          alignSelf: "stretch",
          marginTop: inBetweenRows ? "5px" : "none",
        }}
      >
        <div
          style={{
            fontSize: hidden ? "16px" : "18px",
            userSelect: "none",
            opacity: "0.5",
          }}
        >
          Drop Field Here
        </div>
      </DropInputContainer>
    );
  }

  return (
    <InputContainer
      style={{
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid grey",
        borderRadius: "5px",
        height: DRAG_FIELD_DIMENSIONS.height,
        marginTop: inBetweenRows ? "5px" : "none",
      }}
      onMouseLeave={() => {
        setIsDroppable(false), setTimeout(() => setHiddenActive(false), 200);
      }}
    >
      <EditableField
        lessenOpacity
        isDragPreview={true}
        field={fieldBeingDragged}
      />
    </InputContainer>
  );
};

export const NewFieldPlaceholder = ({ hidden, addFieldCallback }) => {
  //todo: add this to tools
  const [hiddenActive, setHiddenActive] = useState(false);
  const hoverTimeout = useRef(false);

  const triggerShowNFP = () => {
    hoverTimeout.current = true;
    setTimeout(() => {
      if (hoverTimeout.current) {
        setHiddenActive(true);
      }
    }, 400);
  };

  // If we're using hidden and it's not active, show a sliver div with hover available
  if (hidden && !hiddenActive) {
    return (
      <InputShim
        onMouseEnter={triggerShowNFP}
        onMouseLeave={() => (hoverTimeout.current = false)}
      />
    );
  }

  // Now return the full add button if we're not hidden
  return (
    <InputContainer
      style={{
        alignItems: "center",
        justifyContent: "center",
        maxWidth: hidden ? "75px" : "100%",
        cursor: "pointer",
        alignSelf: "stretch",
      }}
      onMouseLeave={() => setTimeout(() => setHiddenActive(false), 200)}
      onClick={addFieldCallback}
    >
      <div style={{ fontSize: hidden ? "16px" : "18px", userSelect: "none" }}>
        New Field
      </div>
      <Add />
    </InputContainer>
  );
};

export const EditableSectionHeaderResolver = ({
  name,
  updateName,
  index,
  deleteSection,
  moveSection,
  isLastSection,
}) => {
  const [showEditButtons, setshowEditButtons] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [newText, setNewText] = useState(undefined);

  const onTextChange = () => {
    if (index !== undefined) {
      updateName(newText, index);
    }
  };

  const prepDelete = () => {
    deleteSection(index);
  };

  const prepMove = (upOrDown) => {
    moveSection(upOrDown, index);
  };

  return (
    <InputContainer
      style={{ border: "none" }}
      onMouseEnter={() => setshowEditButtons(true)}
      onMouseLeave={() => setshowEditButtons(false)}
    >
      {isEditable ? (
        <QuerySectionHeader
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            height: "10px",
          }}
        >
          <TextField
            onChange={(e) => setNewText(e.target.value)}
            defaultValue={name}
            label="Section Name"
            placeholder={name}
          />
          {showEditButtons && (
            <FloatingSectionEditButtons
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              onTextChange={onTextChange}
              deleteSection={prepDelete}
              moveSection={prepMove}
              isFirstSection={index === 0}
              isLastSection={isLastSection}
            />
          )}
        </QuerySectionHeader>
      ) : (
        <QuerySectionHeader
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            height: "10px",
          }}
        >
          {name}
          {showEditButtons && (
            <FloatingSectionEditButtons
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              onTextChange={onTextChange}
              deleteSection={prepDelete}
              moveSection={prepMove}
              isFirstSection={index === 0}
              isLastSection={isLastSection}
            />
          )}
        </QuerySectionHeader>
      )}
    </InputContainer>
  );
};

const FloatingSectionEditButtons = ({
  isEditable,
  setIsEditable,
  onTextChange,
  deleteSection,
  moveSection,
  isFirstSection,
  isLastSection,
}) => {
  useHotkey("Enter", () => {
    onTextChange(), setIsEditable(false);
  });

  return (
    <SectionFloatingButtonContainer>
      {isEditable ? (
        <Tooltip title="Confirm">
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              onTextChange(), setIsEditable(false);
            }}
          >
            <Check />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Edit Section Name">
          <IconButton
            size="small"
            color="primary"
            onClick={() => setIsEditable(true)}
          >
            <Edit />
          </IconButton>
        </Tooltip>
      )}
      {!isFirstSection ? (
        <Tooltip title="Move Section Up">
          <IconButton
            size="small"
            color="primary"
            onClick={() => moveSection("up")}
          >
            <ArrowUpward />
          </IconButton>
        </Tooltip>
      ) : null}
      {!isLastSection ? (
        <Tooltip title="Move Section Down">
          <IconButton
            size="small"
            color="primary"
            onClick={() => moveSection("down")}
          >
            <ArrowDownward />
          </IconButton>
        </Tooltip>
      ) : null}
      <Tooltip title="Delete Section">
        <IconButton
          size="small"
          color="primary"
          onClick={() => deleteSection()}
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </SectionFloatingButtonContainer>
  );
};

export const NewSectionPlaceHolder = ({ insertSectionCallback }) => {
  return (
    <InputContainer
      style={{
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        alignSelf: "stretch",
      }}
      onClick={insertSectionCallback}
    >
      <div style={{ fontSize: "18px", userSelect: "none" }}>New Section</div>
      <Add />
    </InputContainer>
  );
};

const SectionFloatingButtonContainer = styled.div`
  left: 5px;

  padding: 2px;
  border-radius: 6px;
  background: #f2f2f2;
`;

function discardEventMemoize(previousProps, nextProps) {
  let propsChanged = false;
  let isBeingDragged = false;

  Object.keys(previousProps).forEach((key) => {
    if (key === "field" && previousProps[key] !== nextProps[key]) {
      propsChanged = true;
    }

    if (
      (key === "isBeingDragged" ||
        key === "topIndex" ||
        key === "rowIndex" ||
        key === "secIndex" ||
        key === "onFieldDrag") &&
      previousProps[key] !== nextProps[key]
    ) {
      isBeingDragged = true;
    }
  });

  if (propsChanged || isBeingDragged) {
    return false;
  }

  return true;
}
