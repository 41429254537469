import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { parse_on_fields } from "../tools/forms";

import q from "query-it";
import { UserDataContext } from "../App";

/**
 * Form user auth has two pathways, one for a new anon user, and one for when the anon user is still logged in.
 *
 * If this is the sign in (authStateChanged will fire without a user object):
 * - The user is logged in with anonymously sign in
 * - The user is allowed to create a new document in the `formaccessrequests` collection
 * - The document is queried, and we await changes in the document
 * - The backend picks up the document and changes its state to `accepted` or `rejected`
 *  - If accepted, then the authState is set to ready and we proceed to try and view the form (backend has set up the anon user's id for read)
 *  - If rejected, then the token and Id info did not match, so authState is set to error and we show user prompt
 *
 * If the user is already logged in (authStateChanged will fire with a user object):
 * - The previous anon user is used
 * - The authState changes immediately to blindready and we try to access the form
 * - If this fails, then signout is called and the user is sent back to start the anon process
 */
export const useFormUser = (projectId, queryId, formId, token) => {
  const [user, setUser] = useState(undefined);
  const [authState, setAuthState] = useState("loading"); // "loading", "ready", "blindready", "error", "blinderror"
  const authAnonAttempted = useRef(false);

  // Handle blinderror state which can be set from inside the view
  useEffect(() => {
    if (authState === "blinderror") {
      // Basically reset the state and try without blind
      setAuthState("loading");
      q.auth.signOut();
    }
  }, [authState]);

  // Handle the firebase auth change
  useEffect(() => {
    let mounted = true;
    q.auth.onAuthStateChanged((e) => {
      if (mounted) {
        setUser(e);
        if (e && authAnonAttempted.current) {
          // This means we're trying an anon attempt right now, so we need to follow up on the anon login here!
          // Start by getting the form
          const requests = q.projects
            .project(projectId)
            .queries.query(queryId)
            .forms.form(formId).requests;
          requests
            .add({
              token: token,
              requester: e.uid,
            })
            .then((snap) => {
              // Now we need to wait for the request to be accepted or rejected
              requests.request(snap.id).listen((v) => {
                // Change to snap should be its delete
                // That means its time to try! (processed flag is set immediately before delete goes through)
                if (v.processed) {
                  setAuthState("ready");
                }
              });
            });
        }
      }
    });
    return () => (mounted = false);
  }, [projectId, queryId, formId, token]);

  // Handle the user state change
  useEffect(() => {
    if (authAnonAttempted.current) {
      return;
    } // We only make one anon attempt per instance
    if (user === null) {
      // In this case we have no user yet :) (case 1)
      // First we'll set the authAnonAttempted (to flag that we're attempting case 1)
      authAnonAttempted.current = true;
      // Then we'll try to sign in anonymously
      q.auth.signInAnonymously().catch((e) => {
        // If this fails, then we'll set the authState to error
        setAuthState("error");
      });
    } else if (user !== undefined) {
      // In this case a user exists! (case 2)
      setAuthState("blindready");
    }
  }, [user]);

  return [authState, setAuthState];
};

export const useFormComposite = (
  projectId,
  queryId,
  formId,
  noPermsCallback
) => {
  const [formComposite, setFormComposite] = useState(undefined);
  const [formData, setFormData] = useState(undefined);

  useEffect(() => {
    if (projectId && queryId && formId) {
      setFormComposite(
        q.projects.project(projectId).queries.query(queryId).forms.form(formId)
      );
    }
  }, [projectId, queryId, formId]);

  useEffect(() => {
    let mounted = true;
    if (formComposite) {
      let unsubF = formComposite.listen(
        (data) => {
          if (mounted) setFormData(data);
        },
        (e) => (noPermsCallback ? noPermsCallback() : null)
      );
      return () => {
        unsubF();
        mounted = false;
      };
    }
  }, [formComposite, noPermsCallback]);

  return [formComposite, formData];
};

export const useFormDatasets = (formComposite) => {
  // Pull the plurality of datasets from the form for non-targetted access
  // (This will be un-logged because it's done externally through the user array)
  const [datasets, setDatasets] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    if (formComposite) {
      let unsubD = formComposite.data.listen((data) => {
        if (mounted) setDatasets(data);
      });
      return () => {
        unsubD();
        mounted = false;
      };
    }
  }, [formComposite]);

  return datasets;
};

// TODO: This is where we need to integrate secure first viewer flagging
export const useFormData = (formComposite, formId) => {
  const [form, setForm] = useState(undefined);
  const [formData, setFormData] = useState(undefined);
  const formDataStatic = useRef(undefined);

  useEffect(() => {
    if (formComposite) {
      setForm(formComposite.data.form(formId));
    }
  }, [formComposite, formId]);

  useEffect(() => {
    let mounted = true;
    if (form) {
      const unsubQ = form.listen((data) => {
        if (mounted) {
          preserveClean.current = true;
          formDataStatic.current = data;
          setDirty(false);
          setFormData(data);
        }
      });
      return () => {
        unsubQ();
        mounted = false;
      };
    }
  }, [form]);

  // Now we'll also add a dirty handler on any open query
  const [dirty, setDirty] = useState(false);
  const preserveClean = useRef(true);

  const clean = () => {
    setDirty(false);
  };

  // And the effect that makes it dirty in the first place
  useEffect(() => {
    // Protector for when data is fresh during updates
    if (preserveClean.current) {
      preserveClean.current = false;
      return;
    }
    // Now we move to add dirty
    setDirty(true);
  }, [formData]);

  return [form, formData, setFormData, dirty, clean, formDataStatic.current];
};

export const useFormDataViewRequest = (form) => {
  const userData = useContext(UserDataContext);

  return () => {
    if (form === undefined) {
      return;
    }
    form.exists().then((formExists) => {
      // If we can read that the form exists then it doesn't need to be view requested!
      if (formExists) {
        return;
      }
      // Otherwise request the viewing :)
      form.update({
        firstViewTime: new Date(),
        firstViewBy: userData?.id,
      });
    });
  };
};

export const useMessages = (formComposite) => {
  const [messages, setMessages] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    if (formComposite) {
      const unsubM = formComposite.messages.listen((data) => {
        if (mounted) setMessages(data);
      });
      return () => {
        unsubM();
        mounted = false;
      };
    }
  }, [formComposite]);

  return messages;
};

// export const useFormSchema = (formSchemaPath) => {
//   const [formSchema, setFormSchema] = useState(undefined);

//   useEffect(() => {
//     let mounted = true;
//     if (formSchemaPath) {
//       let unsubF = q.projects.formSchema(formSchemaPath).listen(data => {
//         if (mounted)
//           setFormSchema(data);
//       });
//       return () => { unsubF(); mounted = false; }
//     }
//   }, [formSchemaPath]);

//   return formSchema; // form is gonna be the formSchema and formData will be the submission
// }

export const useFormSchemaset = (formSchemaPaths) => {
  const [formSchemaset, setFormSchemaset] = useState(undefined);

  const lastFormSchemaPaths = useRef([]);

  useEffect(() => {
    let mounted = true;
    if (formSchemaPaths) {
      let formSchemas = {};
      let unsubs = [];
      // Guard for rerender
      if (
        Array.isArray(formSchemaPaths) &&
        formSchemaPaths?.length === lastFormSchemaPaths.current.length &&
        formSchemaPaths.every(
          (path, ind) => path === lastFormSchemaPaths.current?.[ind]
        ) &&
        lastFormSchemaPaths.current?.length > 0
      ) {
        return; // No need to do anything if the array hasn't actually changed (should be short)
      }
      // Set the current array for tracking next attempt at remapping these
      lastFormSchemaPaths.current = formSchemaPaths;
      // Now get all the schemas :)
      formSchemaPaths.forEach((formSchemaPath) => {
        if (formSchemaPath === undefined) return;
        unsubs.push(
          q.projects.formSchema(formSchemaPath).listen((data) => {
            if (mounted) {
              formSchemas[formSchemaPath.split("/")[5]] = data; // projects/{projectId}/schemas/{schemaId}/forms/{formId}
            }
            // Now if we have the appropriate number of formSchemas, we'll set them
            if (Object.values(formSchemas).length === formSchemaPaths.length) {
              setFormSchemaset(formSchemas);
            }
          })
        );
      });
      return () => {
        unsubs.forEach((usub) => usub());
        mounted = false;
      };
    }
  }, [formSchemaPaths]);

  return formSchemaset; // NOTE: This is a dict of formSchemas
};

// DEFECT: This doesn't work for multiple stages of forms
export const useClarifications = (projectId, queryId) => {
  const [clarifications, setClarifications] = useState(undefined);
  const [clarificationsData, setClarificationsData] = useState(undefined);

  useEffect(() => {
    if (projectId && queryId) {
      setClarifications(
        q.projects.project(projectId).queries.query(queryId).clarifications
      );
    }
  }, [projectId, queryId]);

  useEffect(() => {
    if (clarifications) {
      clarifications.listen((clarsList) => {
        if (clarsList)
          // Should already be limited here, but just in case why not :)
          setClarificationsData(clarsList);
      });
    }
  }, [clarifications]);

  const newClarification = useCallback(
    async (data) => {
      return await clarifications.add({ ...data });
    },
    [clarifications]
  );

  const updateClarification = useCallback(
    (id, data) => {
      return clarifications.clarification(id).update(data);
    },
    [clarifications]
  );

  return [clarificationsData, newClarification, updateClarification];
};

export const useForms = (query) => {
  const [forms, setForms] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    if (query) {
      query.forms.listen((forms) => {
        if (mounted) setForms(forms);
      });
    }
    return () => (mounted = false);
  }, [query]);

  return forms;
};

export const useFormNegotiables = (formSchemas, schemaData) => {
  const [formNegotiables, setFormNegotiables] = useState(undefined);

  useEffect(() => {
    // First make sure verything is defined
    if (!formSchemas || !schemaData) {
      return;
    }
    // Compile negotiables from the formSchemas
    let negotiables = {};
    Object.values(formSchemas ?? {}).forEach((formSchema) => {
      if (formSchema?.negotiables) {
        negotiables = { ...negotiables, ...formSchema.negotiables };
      }
    });
    if (Object.keys(negotiables)?.length < 1) {
      return;
    }
    // Now we know there is a negotiable. Let's find the package field for each of them
    const negotiablePackages = [];
    // DEFECT: I'm not sure if schemaData can have negotiables because they aren't shared with the form users? Unless this is preview specific?
    // It hasn't been asked for tho, so leave this for now...
    // parse_on_fields(schemaData?.fields, (fld) => {
    //   if (fld.type === "package") {
    //     if (Object.keys(negotiables).includes(fld.id)) {
    //       negotiablePackages.push(fld);
    //     }
    //   }
    // });
    // Now we'll gather from the formSchemas as well
    Object.values(formSchemas ?? {}).forEach((formSchema) => {
      parse_on_fields(formSchema?.fields, (fld) => {
        if (fld.type === "package") {
          if (Object.keys(negotiables).includes(fld.id)) {
            negotiablePackages.push(fld);
          }
        }
      });
    });
    // Now we have all the negotiable packages in one place!
    // Let's pull out the individual negotiables and give them their package id
    const negotiableDocuments = negotiablePackages
      .map((pkg) => {
        const targets = negotiables[pkg.id];
        return targets.map((target) => ({
          ...pkg?.schema?.find((dk) => dk.id === target),
          packageId: pkg.id,
        }));
      })
      .flat();
    setFormNegotiables(negotiableDocuments);
  }, [formSchemas, schemaData]);

  return formNegotiables;
};
