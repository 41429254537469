import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { QueryButtons } from "../../query";
import { QueryFields } from "../../fields";
import { Button } from "../../../../ui/buttons";
import { SignatureField } from "../../../../ui/inputs2";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ProjectUsersContext } from "../../..";

const Approval = ({
  query,
  approvalData,
  approvalSchema,
  approvalUser,
  approvalStage,
  userData,
  settings,
}) => {
  const [data, setData] = useState(approvalData.data || {});
  const [intent, setIntent] = useState(undefined);

  // If the approval is submitted by the signature field,
  // need to check it on change
  useEffect(() => {
    if (!settings?.signToApprove) {
      return;
    }
    if (intent?.signature) {
      if (intent.signature.confirmed && approvalData.status === "assigned") {
        query.approvals.approval(approvalData.id).update({
          status: intent.status,
          data: data,
          signature: intent.signature,
        });
        setIntent(undefined);
      }
    }
  }, [intent, settings, query, approvalData, data]);

  if (!approvalUser || !approvalData) {
    return null;
  }

  const userCanEdit =
    approvalUser &&
    approvalUser.id === userData.id &&
    approvalData.stage === approvalStage;
  const isPending = ["assigned", "pending"].includes(approvalData.status);

  const onFieldChange = (fieldId) => (val) => {
    setData((ex) => ({ ...ex, [fieldId]: val }));
  };

  return (
    <Accordion
      disabled={isPending && !userCanEdit}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography style={{ width: "85%", flexShrink: 0 }}>
          {approvalUser.name.first} {approvalUser.name.last}
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          {approvalData.status.toUpperCase()}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ApprovalBody>
          {/* Prompt user for approval if pending */}
          {isPending && userCanEdit && !intent && (
            <>
              <div>
                Upon selecting &quot;Approve&quot; or &quot;Reject&quot;, you
                will be prompted for more detail. Once submitted, you will no
                longer be able to edit these details.
                <br />
                Note that if this is the final approval, the Query could also be
                closed upon your decision&apos;s submission, making it
                unavailable for further editing.
              </div>
              <QueryButtons>
                <Button
                  text="Reject"
                  onClick={() => {
                    setIntent({ status: "rejected", reason: "", files: [] });
                  }}
                />
                <Button
                  text="Approve"
                  onClick={() => {
                    setIntent({ status: "approved", reason: "", files: [] });
                  }}
                />
              </QueryButtons>
            </>
          )}

          {/* Show schema if already filled out or intending to approve */}
          {((!isPending && approvalData.status === "approved") ||
            (userCanEdit && intent && intent.status == "approved")) && (
            <QueryFields
              data={data}
              onFieldChange={onFieldChange}
              fieldSet={approvalSchema ?? APPROVAL_SCHEMA_DEFAULT}
              status={userCanEdit && isPending ? "editable" : "readonly"}
              noHeaders
            />
          )}
          {((!isPending && approvalData.status === "rejected") ||
            (userCanEdit && intent && intent.status === "rejected")) && (
            <QueryFields
              data={data}
              onFieldChange={onFieldChange}
              fieldSet={REJECT_SCHEMA}
              status={userCanEdit && isPending ? "editable" : "readonly"}
              noHeaders
            />
          )}

          {(intent !== undefined || !isPending) &&
            (settings?.requireApprovalSignature || settings?.signToApprove) && (
              <>
                {settings?.signToApprove && (
                  <div>
                    By confirming your signature, you submit your approval. Do
                    not sign before confirming your approval.
                  </div>
                )}
                <SignatureField
                  label={"Approval Signature"}
                  onChange={(e) => {
                    const { value } = e.target;
                    setIntent((ex) => ({ ...ex, signature: value }));
                  }}
                  data={intent ? intent.signature : approvalData.signature}
                  drawable
                  signingUser={{
                    id: userData.id,
                    name: `${userData.name.first} ${userData.name.last}`,
                  }}
                  disabled={!userCanEdit || !isPending}
                />
              </>
            )}

          {intent != undefined && (
            <QueryButtons>
              <Button
                text="Cancel"
                onClick={() => {
                  setIntent(undefined);
                }}
              />
              {!settings?.signToApprove && (
                <Button
                  text="Submit"
                  onClick={() => {
                    query.approvals
                      .approval(approvalData.id)
                      .update({ status: intent.status, data: data });
                    setIntent(undefined);
                  }}
                />
              )}
            </QueryButtons>
          )}
        </ApprovalBody>
      </AccordionDetails>
    </Accordion>
  );
};

export const APPROVAL_SCHEMA_DEFAULT = [
  {
    name: "Justification",
    id: "justification",
    type: "textarea",
    editableWhile: ["editable"],
  },
  {
    name: "Attachments",
    id: "attach",
    type: "files",
    editableWhile: ["editable"],
  },
];

// export default ViewApprovals;

const REJECT_SCHEMA = [
  {
    name: "Rejection Reason",
    id: "reason",
    type: "string",
    editableWhile: ["editable"],
  },
];

const ApprovalBody = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

/*
  This default export is a NEW version that consolidates the single and multi stage approvals
  into a single view. The render requirements aren't nearly different enough to warrant disjoint components
*/

export default ({
  query,
  approvalSchema,
  approvalStage,
  userData,
  approvals,
  settings,
}) => {
  const users = useContext(ProjectUsersContext);

  if (!approvals || !users) {
    return null;
  }

  const usersById = {};
  for (let user of users) {
    usersById[user.id] = user;
  }

  return (
    <div>
      {/* Start with section for active approvals if available */}
      {approvalStage ? (
        <ApprovalSection>
          <ApprovalSectionHeader>Available Approvals</ApprovalSectionHeader>
          {approvals
            .filter((appr) => appr.stage === approvalStage)
            .map((approvalData) => {
              const user = usersById[approvalData.assignedTo];
              if (!user) {
                return null;
              } // This should always pass, but just in case :)
              return (
                <Approval
                  key={`appr-${approvalData.id}`}
                  query={query}
                  approvalData={approvalData}
                  approvalUser={user}
                  approvalStage={approvalStage}
                  approvalSchema={approvalSchema ?? APPROVAL_SCHEMA_DEFAULT}
                  userData={userData}
                  settings={settings}
                />
              );
            })}
        </ApprovalSection>
      ) : null}
      {/* Then section for past and future approvals */}
      {approvals.some((appr) => appr.stage !== approvalStage) && (
        <ApprovalSection>
          <ApprovalSectionHeader>
            Past &amp; Future Approvals
          </ApprovalSectionHeader>
          {approvals
            .filter((appr) => appr.stage !== approvalStage)
            .sort((a, b) => a.stage - b.stage)
            .map((approvalData) => {
              const user = usersById[approvalData.assignedTo];
              if (!user) {
                return null;
              } // This should always pass, but just in case :)
              return (
                <Approval
                  key={`appr-${approvalData.id}`}
                  query={query}
                  approvalData={approvalData}
                  approvalUser={user}
                  approvalStage={approvalStage}
                  approvalSchema={approvalSchema ?? APPROVAL_SCHEMA_DEFAULT}
                  userData={userData}
                  settings={settings}
                />
              );
            })}
        </ApprovalSection>
      )}
    </div>
  );
};

const ApprovalSection = styled.div`
  margin-bottom: 32px;
  border-top: solid 1px #ddd;

  :first-of-type {
    border-top: none;
  }
  width: 99%;
`;

const ApprovalSectionHeader = styled.div`
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 8px;
`;
