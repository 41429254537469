import { useState, useEffect } from "react";
import q from "query-it";
import { log_error } from "../tools/logger";

let cachedCompanyData = undefined;
export const useCompany = (id) => {
  const [company, setCompany] = useState(undefined);
  const [companyData, setCompanyData] = useState(cachedCompanyData);

  useEffect(() => {
    if (id) {
      setCompany(q.companies.company(id));
    }
    return;
  }, [id]);

  useEffect(() => {
    let mounted = true;
    if (company) {
      const unsubPr = company.listen((companyData) => {
        if (mounted) {
          setCompanyData(companyData);
          cachedCompanyData = companyData;
        }
      });
      return () => {
        mounted = false;
        unsubPr();
      };
    }
    return;
  }, [company]);

  return [company, companyData];
};

export const useCompanies = () => {
  const [companies, setCompanies] = useState(undefined);

  const addCompany = async (name) => {
    try {
      q.companies.add({ name: name, users: [], projects: [] });
    } catch (error) {
      log_error("Company Creation failed");
    }
  };

  useEffect(() => {
    let mounted = true;
    const unsub = q.companies.listen((companies) => {
      if (mounted) setCompanies(companies);
    });

    return () => {
      unsub();
      mounted = false;
    };
  }, []);

  return [companies, addCompany];
};

export const useAddCompanyUser = () => {
  const addCompanyUser = async (companyID, userID) => {
    try {
      const company = await q.companies.doc(companyID).get();
      let newCompanyUsers = company.users;
      if (!newCompanyUsers.includes(userID)) {
        newCompanyUsers.push(userID);
      }
      await q.companies.doc(companyID).update({ users: newCompanyUsers });
    } catch (error) {
      log_error("Add User to Company failed");
    }
  };

  return addCompanyUser;
};

export const useRemoveCompanyUser = () => {
  const removeCompanyUser = async (companyID, userID) => {
    try {
      const company = await q.companies.doc(companyID).get();
      const newCompanyUsers = company.users.filter((user) => user !== userID);
      await q.companies.doc(companyID).update({ users: newCompanyUsers });
    } catch (error) {
      log_error("Remove User from Company failed");
    }
  };

  return removeCompanyUser;
};
