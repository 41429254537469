import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { Skeleton, Box } from "@mui/material";

import QueryTable from "./querytable";

import { ControlPanel } from "../controls";
import { ProjectRouteContainer, SelectedSchemaContext } from "..";

import { UserDataContext } from "../../../App";

import { FilteredQueriesState } from "..";

/** The Home page showing a table of queries. */

export default ({
  setFiltersPaneVisible,
  schemas,
  projectData,
  projectActions,
  filterManager,
  project,
}) => {
  const userData = useContext(UserDataContext);
  const queries = useRecoilValue(FilteredQueriesState);
  const selectedSchema = useContext(SelectedSchemaContext);
  const [editMode, setEditMode] = useState(false);
  const [columnsPreview, setColumnsPreview] = useState(undefined);
  const [disableConfirm, setDisableConfirm] = useState(false);

  // If queries haven't loaded yet, show nothing.
  if (queries === undefined) {
    return null;
  }

  const handleEditAction = (action) => {
    if (action === "edit") {
      setEditMode(true);
    } else if (action === "confirm") {
      if (columnsPreview && selectedSchema.tableSchema) {
        selectedSchema.tableSchema = columnsPreview;
        selectedSchema.tableSchema.forEach((k, i) => {
          if (Object.keys(k).includes("sortable")) {
            //the table does not support booleans, update here
            if (k.sortable === "true") {
              k.sortable = true;
            }
            if (k.sortable === "false") {
              k.sortable = false;
            }
          }
        });

        project.schemas
          .schema(selectedSchema.id)
          .update({ tableSchema: selectedSchema.tableSchema });
      }
      setEditMode(false);
    } else if (action === "cancel") {
      setEditMode(false);
    }
  };

  return (
    <ProjectRouteContainer>
      <ControlPanel
        setFiltersPaneVisible={setFiltersPaneVisible}
        settings={{
          broad: "standard",
          status: true,
        }}
        actions={projectActions
          ?.filter((a) => a.assignedTo === userData.id)
          .filter((a) => a.schemaId === selectedSchema?.id)
          .filter((a) => a.query)}
        queryFilterManager={filterManager}
        editAction={handleEditAction}
        isEditMode={editMode}
        disableConfirm={disableConfirm}
      />
      <ProjectQueriesDiv>
        <QueryTable
          queries={queries}
          projectData={projectData}
          schemas={schemas}
          userData={userData}
          editMode={editMode}
          setColumnsPreview={setColumnsPreview}
          setDisableConfirm={setDisableConfirm}
        />
      </ProjectQueriesDiv>
    </ProjectRouteContainer>
  );
};

const ProjectQueriesDiv = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
