import React, { useState } from "react";
import q from "query-it";
import styled from "@emotion/styled";
import { ThemeProvider } from "styled-components";
import themes from "../../../themes";
import { BooleanField, GenericField } from "../../ui/inputs2";
import { DialogContent, Modal, Box } from "@mui/material";
import { ModalViewFullScreen } from "../../ui/containers";
import { isMobile } from "react-device-detect";
import { Close } from "@mui/icons-material";

export default ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    company: "",
    position: "",
    newsletter: false,
  });
  const [formHasSubmit, setFormHasSubmit] = useState(false);
  const [formError, setFormError] = useState(false);

  const formEdit = (ind) => (e) => {
    const { value } = e.target;
    setFormData((ex) => ({ ...ex, [ind]: value }));
  };

  const submitForm = (e) => {
    e.preventDefault();
    // validate
    if (Object.values(formData).some((v) => v.length < 1)) {
      setFormError(true);
      return;
    }
    // submit
    q.interests.add(formData).then((_) => {
      setFormHasSubmit(true);
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setFormError(false);
      }}
    >
      <DialogContent>
        <ThemeProvider theme={themes.purple}>
          <ModalViewFullScreen>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "190px",
              }}
            >
              <Box
                sx={{
                  width: isMobile ? "70%" : "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "40px" }}></div>
                  <p className="lead">Download our Promotional Materials</p>
                  <Close
                    style={{ fontSize: "40px", cursor: "pointer" }}
                    onClick={() => {
                      onClose();
                      setFormError(false);
                    }}
                  />
                </div>
                <form
                  style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {formHasSubmit && (
                    <>
                      <p className="lead">
                        Thank you for your interest in Query-It!
                      </p>
                      <p>
                        The contents of our promotional material have been
                        forwarded to you by email
                      </p>
                    </>
                  )}
                  {!formHasSubmit && (
                    <>
                      {formError && (
                        <p className="lead" style={{ color: "red" }}>
                          Please fill all fields
                        </p>
                      )}

                      <CenteredInputs>
                        <GenericField
                          label="Name"
                          fill
                          data={formData.name}
                          onChange={formEdit("name")}
                          style={{ marginRight: "7px" }}
                        />
                        <GenericField
                          label="Email"
                          type="email"
                          fill
                          data={formData.id}
                          onChange={formEdit("id")}
                          style={{ marginLeft: "7px" }}
                        />
                      </CenteredInputs>
                      <CenteredInputs>
                        <GenericField
                          label="Company Name"
                          fill
                          data={formData.company}
                          onChange={formEdit("company")}
                          style={{ marginRight: "7px" }}
                        />
                        <GenericField
                          label="Position"
                          fill
                          data={formData.position}
                          onChange={formEdit("position")}
                          style={{ marginLeft: "7px" }}
                        />
                      </CenteredInputs>
                      <BooleanField
                        checkbox
                        label="Signup for our newsletter"
                        data={formData.newsletter}
                        onChange={() =>
                          setFormData((ex) => ({
                            ...ex,
                            newsletter: !ex.newsletter,
                          }))
                        }
                      />
                      <CenteredInputs
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button onClick={submitForm} type="submit">
                          Submit
                        </Button>
                      </CenteredInputs>
                    </>
                  )}
                </form>
              </Box>
            </div>
          </ModalViewFullScreen>
        </ThemeProvider>
      </DialogContent>
    </Modal>
  );
};

const CenteredInputs = styled.div`
  display: flex;
  width: ${isMobile ? "100%" : "50%"};

  & * {
    box-sizing: content-box !important;
  }
`;

const Button = styled.button`
  height: 40px;
  width: 150px;
  margin-top: 0;
  margin-bottom: 20px;
  border: solid #37227a;
  border-radius: 5px;
  background-color: #37227a;
  color: white;
  user-select: none;

  font-size: 105%;

  -webkit-transition-duration: 0.4s; // Safari
  transition-duration: 0.4s;
  cursor: pointer;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      background-color: white;
      color: #37227a;
    }
  }
`;
