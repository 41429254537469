import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Add, Delete, ArrowUpward, ArrowDownward } from "@mui/icons-material";

import { useSchema, useProjectsUsers } from "../../../../hooks/projects";
import { QueryContent } from "../query";
import { SaveQueryBar } from "../workflow";
import { Centerer } from "./fields";
import {
  InputContainer,
  FloatingButtonContainer,
  FloatingSelectList,
} from "./fields/tools";

import { GenericField, SelectField } from "../../../ui/inputs2";
import { Button2 } from "../../../ui/buttons";
import { generate_tempid } from "../../../../tools";
import { parse_on_fields } from "../../../../tools/forms";

const SHORT_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const USABLE_FIELD_TYPES = new Set([
  "string",
  "number",
  "date",
  "checkbox",
  "select",
  "userlist",
  "radio",
  "boxset",
]);

const SEGMENT_TYPES = {
  static: { label: "static" },
  preset: { label: "preset" },
  data: { label: "data" },
};

export default ({ project, schemaId, queryData, setStatusMessage }) => {
  const [schema, schemaData] = useSchema(schemaId, project);
  const users = useProjectsUsers([project.ref.id]);

  const [dynamicIdSchema, setDynamicIdSchema] = useState(undefined);
  const [showFieldList, setShowFieldList] = useState(false);
  const [showError, setShowError] = useState(false);

  // Copy current dynamicIdSchema into state
  useEffect(() => {
    if (Array.isArray(schemaData?.dynamicIdSchema)) {
      setDynamicIdSchema(
        schemaData?.dynamicIdSchema.map((config) => {
          // type is a new field to support presets
          const type =
            config.type || (config.format === "static" ? "static" : "data");
          return { ...config, type, tempId: generate_tempid() };
        })
      );
    } else {
      // convert the legacy format string to object parsing
      const schemaAsObject = schemaData?.dynamicIdSchema
        .split("-")
        .map((segment) => {
          const tempId = generate_tempid();
          if (
            segment.charAt(0) === "`" &&
            segment.charAt(segment.length - 1) === "`"
          ) {
            // static string format
            return {
              format: "static",
              type: "static",
              field: segment.slice(1, -1),
              tempId,
            };
          } else {
            // segment is based on query data
            let newFormat = "";
            parse_on_fields(schemaData?.fields, (fld) => {
              if (segment === fld.id) {
                switch (fld.type) {
                  case "date":
                    newFormat = "date";
                    break;
                  case "userlist":
                    newFormat = "user";
                    break;
                  default:
                  // segment has no format
                }
              }
            });
            return {
              field: `data.${segment}`,
              type: "data",
              tempId,
              ...(newFormat && { format: newFormat }),
            };
          }
        });
      setDynamicIdSchema(schemaAsObject);
    }
  }, [schemaData]);

  const saveChanges = () => {
    setShowError(true);
    // check if there are blank fields
    if (dynamicIdSchema.findIndex((config) => !config.field) !== -1) {
      setStatusMessage("There cannot be blank fields. Please try again.");
    } else {
      setStatusMessage("Changes Saved!");
      // delete tempIds before saving
      schema.update({
        dynamicIdSchema: dynamicIdSchema.map(({ tempId, ...rest }) => rest),
      });
    }
  };

  const handleFieldChange =
    (index, type = undefined) =>
    (e) => {
      const newSchema = dynamicIdSchema.map((elem, i) => {
        if (i === index) {
          let newFormat = "";
          if (type === "static") {
            newFormat = "static";
          } else {
            const fieldFormat =
              type === "preset"
                ? presetOptions[e.target.value]
                : schemaFieldOptions[e.target.value];
            // switch for special formats
            if (Array.isArray(fieldFormat)) {
              switch (fieldFormat[1]) {
                case "date":
                  newFormat = "date";
                  break;
                case "user":
                case "userlist":
                  newFormat = "user";
                  break;
                default:
                // segment has no format
              }
            }
          }
          return {
            field: e.target.value,
            type: elem.type,
            tempId: elem.tempId,
            ...(newFormat && { format: newFormat }),
          };
        } else {
          return elem;
        }
      });
      setDynamicIdSchema(newSchema);
    };

  const addSegment = (type, index) => {
    setDynamicIdSchema([
      ...dynamicIdSchema.slice(0, index),
      {
        type,
        tempId: generate_tempid(),
        ...(type === "static" && { format: type }),
      },
      ...dynamicIdSchema.slice(index),
    ]);
  };

  const deleteSegment = (id) => {
    setDynamicIdSchema(
      dynamicIdSchema.filter((segment) => segment.tempId !== id)
    );
  };

  const moveSegment = (upOrDown, index) => {
    let schemaCopy = [...dynamicIdSchema];
    let temp = schemaCopy[index];
    if (upOrDown === "up" && schemaCopy[index - 1]) {
      schemaCopy[index] = schemaCopy[index - 1];
      schemaCopy[index - 1] = temp;
    } else if (upOrDown === "down" && schemaCopy[index + 1]) {
      schemaCopy[index] = schemaCopy[index + 1];
      schemaCopy[index + 1] = temp;
    }
    setDynamicIdSchema(schemaCopy);
  };

  // preset and field options are of the form key: [label, type]
  const presetOptions = {
    createTime: ["Creation Date", "date"],
    creator: ["Query Creator", "user"],
  };

  const schemaFieldOptions = useMemo(() => {
    const fields = {};
    parse_on_fields(schemaData?.fields, (fld) => {
      if (USABLE_FIELD_TYPES.has(fld.type)) {
        // don't allow userlists with multi set to true
        if (fld.type === "userlist" && fld.multi) {
          return;
        }
        fields[`data.${fld.id}`] = [fld.id, fld.type];
      }
    });
    return fields;
  }, [schemaData?.fields]);

  const preview = useMemo(() => {
    let dynamicId = "";
    dynamicIdSchema?.forEach((config, index) => {
      if (config.field === null || config.field === undefined) {
        return;
      }

      // First we'll check if the format type is static (in which case no parse and we continue)
      if (config.format === "static") {
        dynamicId += config.field + "-";
        return;
      }

      let dynamicComponent = "";
      // Now parse the data field
      let data = queryData;
      config.field.split(".").forEach((field) => {
        data = data[field];
      });

      // Now we have the data
      // Use switch for special formats
      switch (config.format) {
        case "date":
          // need to convert other time representations to JS Date object
          if (!(data instanceof Date)) {
            if (typeof data === "number") {
              data = new Date(data);
            } else {
              data = data.toDate();
            }
          }
          // In this case we use the month
          dynamicComponent = `${
            SHORT_MONTHS[data.getMonth()]
          }${data.getDate()}`;
          break;
        case "user":
          // Parse as a user ID, checking the list of available users
          var usr = users[project.ref.id]?.find((usr) => usr.id === data);
          if (usr) {
            dynamicComponent = `${usr?.name?.first[0]}${usr?.name?.last[0]}`;
          } else {
            dynamicComponent = "Undefined";
          }
          break;
        default:
          // In this case just insert the data
          dynamicComponent = data && data !== "" ? data : "Undefined";
          break;
      }
      // Finish by appending
      dynamicId +=
        dynamicComponent + (index === dynamicIdSchema.length - 1 ? "" : "-");
    });

    return dynamicId;
  }, [dynamicIdSchema, queryData, users]);

  return (
    <>
      {schemaData ? (
        <>
          <QueryContent>
            <div style={{ marginTop: "10px" }}>
              <b>Preview:</b> {preview}
            </div>
            <div
              style={{
                position: "relative",
                margin: "8px 0",
                width: "fit-content",
              }}
            >
              <Button2
                label="Add Segment"
                onClick={() => setShowFieldList(true)}
              />
              {/* TODO: for the floating field list */}
              <FloatingSelectList
                setField={(type) => addSegment(type, 0)}
                show={showFieldList}
                onHide={() => setShowFieldList(false)}
                types={SEGMENT_TYPES}
                position="right"
              />
            </div>
            {(dynamicIdSchema ?? []).map((config, index) => (
              <DynamicIdSegment
                key={config.tempId}
                config={config}
                index={index}
                options={
                  config.type === "preset" ? presetOptions : schemaFieldOptions
                }
                deleteSegment={deleteSegment}
                moveSegment={moveSegment}
                addSegment={addSegment}
                onChange={handleFieldChange}
                showError={showError}
                isLastSegment={index === dynamicIdSchema.length - 1}
              />
            ))}
          </QueryContent>
          <SaveQueryBar saveQuery={saveChanges} />
        </>
      ) : (
        <Centerer>
          <CircularProgress />
        </Centerer>
      )}
    </>
  );
};

const DynamicIdSegment = ({
  config,
  index,
  options,
  deleteSegment,
  moveSegment,
  addSegment,
  onChange,
  isLastSegment,
  showError,
}) => {
  const errorMessage =
    showError && !config.field ? "This field cannot be left blank" : undefined;

  return (
    <InputContainer
      style={{
        border: "none",
        flexDirection: "row",
        alignItems: "center",
        gap: "20px",
      }}
    >
      {config.format === "static" ? (
        <GenericField
          data={config.field}
          label="Static String"
          onChange={onChange(index, "static")}
          error={errorMessage}
        />
      ) : (
        <SelectField
          label={config.type === "preset" ? "Preset" : "Schema field"}
          options={Object.keys(options).reduce((acc, key) => {
            return { ...acc, [key]: options[key][0] };
          }, {})}
          data={config.field}
          onChange={onChange(index, config.type)}
          error={errorMessage}
          contrast
          autocomplete
        />
      )}
      <FloatingEditButtons
        deleteSegment={() => deleteSegment(config.tempId)}
        moveSegment={(direction) => moveSegment(direction, index)}
        addSegment={(type) => {
          addSegment(type, index + 1);
        }}
        isFirstSegment={index === 0}
        isLastSegment={isLastSegment}
      />
    </InputContainer>
  );
};

const FloatingEditButtons = ({
  deleteSegment,
  moveSegment,
  addSegment,
  isFirstSegment,
  isLastSegment,
}) => {
  const [showFieldList, setShowFieldList] = useState(false);
  return (
    <FloatingButtonContainer style={{ position: "relative" }}>
      <FloatingSelectList
        setField={(type) => addSegment(type)}
        show={showFieldList}
        onHide={() => setShowFieldList(false)}
        types={SEGMENT_TYPES}
        position="right"
      />
      {!isFirstSegment ? (
        <Tooltip title="Move Segment Up">
          <IconButton
            size="small"
            color="primary"
            onClick={() => moveSegment("up")}
          >
            <ArrowUpward />
          </IconButton>
        </Tooltip>
      ) : null}
      {!isLastSegment ? (
        <Tooltip title="Move Segment Down">
          <IconButton
            size="small"
            color="primary"
            onClick={() => moveSegment("down")}
          >
            <ArrowDownward />
          </IconButton>
        </Tooltip>
      ) : null}
      <Tooltip title="Delete Segment">
        <IconButton
          size="small"
          color="primary"
          onClick={() => deleteSegment()}
        >
          <Delete />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add Segment Below">
        <IconButton
          size="small"
          color="primary"
          onClick={() => setShowFieldList(true)}
        >
          <Add />
        </IconButton>
      </Tooltip>
    </FloatingButtonContainer>
  );
};
