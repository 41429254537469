import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";

import Charts from "./charts.js";

import { ControlPanel } from "../controls";
import {
  CurrentProjectContext,
  FilteredQueriesState,
  ProjectRouteContainer,
  SelectedSchemaContext,
} from "..";
import { UserContext } from "../../../App";
import PrintPreview from "./preview";

import { useRecoilValue } from "recoil";
import { log_warning } from "../../../tools/logger";
import { isMobile } from "react-device-detect";
import { Print } from "@mui/icons-material";
import { Fab } from "@mui/material";

export default ({
  setFiltersPaneVisible,
  project,
  projectActions,
  aggregateData,
}) => {
  // const users = useContext(ProjectUsersContext);
  // const theme = (userData && userData.theme) ? themes[userData.theme] : themes.purple;

  const selectedSchema = useContext(SelectedSchemaContext);
  const projectData = useContext(CurrentProjectContext);
  const queries = useRecoilValue(FilteredQueriesState);
  const user = useContext(UserContext);

  // We don't want to use ALL actions though, just the ones for this schema should show here :)
  const [actions, setActions] = useState([]);
  const [filteredQueries, setFilteredQueries] = useState([]);

  const chartsImages = useRef({});

  const [previewPrint, setPreviewPrint] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [chartSchemaSetPreview, setChartSchemaSetPreview] = useState(undefined);

  let printComponent;

  // Apply migration to add chartSchema IDs
  useEffect(() => {
    if (selectedSchema && project) {
      if (
        selectedSchema?.analyticsSchema &&
        !selectedSchema?.analyticsSchema[0].id
      ) {
        // Well then let's fix that
        log_warning("Chart Schemas are missing IDs, applying migration...");
        project.schemas.schema(selectedSchema.id).update({
          analyticsSchema: selectedSchema.analyticsSchema.map((schema) => ({
            ...schema,
            id: schema.name.toLowerCase().replaceAll(" ", "-"),
          })),
        });
      }
    }
  }, [selectedSchema, project]);

  // If user has personal analytics schema, use that instead
  useEffect(() => {
    if(user.ref.id && selectedSchema && project) {
      return project.schemas
        .schema(selectedSchema.id)
        .users.user(user.ref.id)
        .listen((data)=>{
          if(data.customAnalyticsSchema) {
            selectedSchema.analyticsSchema = data.customAnalyticsSchema;
          }
        });
    }
  }, [selectedSchema, project, user]);
  

  // Pre-filter the queries here so we can use them in the charts and actions calcs
  // useEffect(() => {
  //   if (queries && selectedSchema && filters) {
  //     setFilteredQueries(filters.$applyFilters(queries));
  //   }
  // }, [queries, selectedSchema, filters]);

  // Small filter to take in project actions and just count the ones from this schema
  useEffect(() => {
    if (projectActions && queries) {
      const schemaActions = projectActions.filter((action) =>
        queries.map((q) => q.id).includes(action.queriesId)
      );
      setActions(schemaActions);
    }
  }, [projectActions, queries]);

  const chartBlobCollector = useCallback(
    (name, url) =>
      (chartsImages.current = { ...chartsImages.current, [name]: url }),
    [chartsImages]
  );

  // Edited analytics schemas are saved as custom/personal
  const handleEditAction = (action) => {
    if (action === "edit") {
      setEditMode(true);
    } else if (action === "confirm") {
      if (chartSchemaSetPreview) {
        selectedSchema.analyticsSchema = chartSchemaSetPreview;
        project.schemas
          .schema(selectedSchema.id)
          .users.user(user.ref.id)
          .set({ 
            customAnalyticsSchema: chartSchemaSetPreview
          }, { merge: true });
      }
      setEditMode(false);
    } else if (action === "cancel") {
      setEditMode(false);
    }
  };

  return (
    <ProjectRouteContainer>
      {previewPrint && (
        <PrintPreview
          onClose={() => setPreviewPrint(false)}
          data={queries}
          actions={actions}
          chartSchemaSet={selectedSchema?.analyticsSchema ?? []}
          selectedSchema={selectedSchema}
          projectName={projectData?.name}
        />
      )}
      <ControlPanel
        isAnalytics
        setFiltersPaneVisible={setFiltersPaneVisible}
        settings={{
          broad: "extended",
        }}
        // printAction={() => saveAnalyticsPDFAsync(selectedSchema, chartsImages.current, `${project?.name}-${selectedSchema?.name}-analytics.pdf`)}
        printAction={() => setPreviewPrint(true)}
        editAction={handleEditAction}
        isEditMode={editMode}
      />
      {queries && (
        <SchemaCharts ref={(el) => (printComponent = el)}>
          <Charts
            data={queries}
            aggregateData={aggregateData}
            actions={actions}
            chartSchemaSet={selectedSchema?.analyticsSchema ?? []}
            chartBlobCollector={chartBlobCollector}
            editMode={editMode}
            setChartSchemaSetPreview={setChartSchemaSetPreview}
          />
        </SchemaCharts>
      )}
      {isMobile && (
        <Fab
          size="small"
          style={{ position: "absolute", bottom: 180, right: 8 }}
          color="primary"
          onClick={() => setPreviewPrint(true)}
        >
          <Print />
        </Fab>
      )}
    </ProjectRouteContainer>
  );
};

const SchemaCharts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  margin-bottom: ${isMobile ? "70px" : "0"};
`;
