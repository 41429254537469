import { useCallback, useEffect, useState } from "react";

import q from "query-it";

const hotkeys = {}; // Data structure of [key]: Array<Callbacks>

export const useHotkey = (keyname, callback) => {
  const register = useCallback(
    (event) => {
      if (event.key === keyname) {
        callback();
        if (hotkeys[keyname].length > 1) {
          event.stopPropagation();
        }
      }
    },
    [keyname, callback]
  );

  useEffect(() => {
    window.addEventListener("keydown", register, { once: true, capture: true });
    // Also append to the hotkeys dict
    if (!hotkeys[keyname]) {
      hotkeys[keyname] = [];
    }
    hotkeys[keyname].push(register);
    // Then return a cleanup function
    return () => {
      window.removeEventListener("keydown", register, { capture: true });
      hotkeys[keyname] = hotkeys[keyname].filter((cb) => cb !== register);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]); // Exclude keyname intentionally, no one cares
};

export const useDisableHotkey = (key) => {};

export const useUpdateCheck = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    q.systeminfo.listen((info) => {
      if (info.currentVersion !== process.env.APP_VERSION) {
        setUpdateAvailable(true);
      }
    });
  }, []);

  return updateAvailable;
};
