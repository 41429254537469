import React, { useState } from "react";
import styled from "styled-components";

import { ProjectRouteContainer } from "../../../project";
import { InteractiveTable } from "../../../ui/table";
import { Button2, ButtonSection } from "../../../ui/buttons";
import { useCurrentUsers } from "../../../../hooks/projects";
import { SelectField } from "../../../ui/inputs2";
import { useUsers } from "../../../../hooks/users";

export default ({ project, projectData }) => {
  const users = useCurrentUsers(project);
  const [allUsers, addUser] = useUsers();
  const [selectedUser, setSelectedUser] = useState(null);
  const [showEscalationOnly, setShowEscalationOnly] = useState(false);

  const newUser = () => {
    const findUser = users.find((user) => user.id === selectedUser);
    // Don't add if the user is already in the project
    if (selectedUser === null || findUser) {
      return;
    }
    project.users.doc(selectedUser).set({});
    project.update({
      users: [...(projectData?.users || []), selectedUser],
    });
    setSelectedUser(null);
  };

  const removeUser = async (id) => {
    const isUserInEscalation = !!projectData?.escalationUsers?.find(
      (usrId) => usrId === id
    );
    await project.users.doc(id).delete();
    await project.update({
      users: projectData.users.filter((usrId) => usrId !== id),
      ...(isUserInEscalation && {
        escalationUsers: projectData.escalationUsers.filter(
          (usrId) => usrId !== id
        ),
      }),
    });
  };

  const addEscalationUser = (id) => {
    const findUser = projectData?.escalationUsers?.find(
      (usrId) => usrId === id
    );
    // Don't add if the user is already an escalation user
    if (!id || findUser) {
      return;
    }
    project.update({
      escalationUsers: [...(projectData?.escalationUsers || []), id],
    });
  };

  const removeEscalationUser = (id) => {
    project.update({
      escalationUsers: projectData.escalationUsers.filter(
        (usrId) => usrId !== id
      ),
    });
  };

  if (!projectData || !allUsers) {
    return null;
  }

  return (
    <ProjectRouteContainer>
      <Message>
        Hey this is where you can add and remove users from a project
      </Message>
      <ButtonSection>
        <Button2 label={"Add Selected User"} onClick={newUser} />
      </ButtonSection>
      <SelectField
        label={"New User"}
        data={selectedUser}
        options={allUsers
          ?.map((usr) => ({
            [usr.id]:
              !usr?.name?.first && !usr?.name?.last
                ? `${usr?.email || usr?.phone}`
                : `${usr?.name?.first ?? ""} ${usr?.name?.last ?? ""}`,
          }))
          ?.reduce((a, b) => ({ ...a, ...b }), {})}
        onChange={(e) => setSelectedUser(e.target.value)}
        autocomplete
        contrast
      />
      <div style={{ display: "flex" }}>
        <Message>
          Current {showEscalationOnly ? "Escalation" : ""} User Count:{" "}
          {showEscalationOnly
            ? projectData?.escalationUsers?.length ?? 0
            : users?.length ?? 0}
        </Message>
        <Button2
          size="small"
          label={
            showEscalationOnly ? "Show All Users" : "Show Escalation Users Only"
          }
          onClick={() => setShowEscalationOnly((ex) => !ex)}
        />
      </div>
      <InteractiveTable
        data={
          showEscalationOnly
            ? users?.filter(
                (usr) =>
                  Array.isArray(projectData?.escalationUsers) &&
                  projectData.escalationUsers.includes(usr.id)
              ) ?? []
            : users ?? []
        }
        columns={analyticsColumns(
          projectData,
          removeUser,
          addEscalationUser,
          removeEscalationUser
        )}
      />
    </ProjectRouteContainer>
  );
};

const analyticsColumns = (
  projectData,
  removeUser,
  addEscalationUser,
  removeEscalationUser
) => [
  {
    name: "Name",
    index: "name",
    format: "return `${data.name.first} ${data.name.last}`",
  },
  { name: "Email", index: "email" },
  {
    name: "...",
    component: (row) => (
      <div style={{ display: "flex" }}>
        <Button2 label={"Remove User"} onClick={() => removeUser(row.id)} />
        {Array.isArray(projectData?.escalationUsers) &&
        projectData.escalationUsers.includes(row.id) ? (
          <Button2
            label="Remove From Escalation Users"
            onClick={() => removeEscalationUser(row.id)}
          />
        ) : (
          <Button2
            label="Make User an Escalation User"
            onClick={() => addEscalationUser(row.id)}
          />
        )}
      </div>
    ),
  },
];

const Message = styled.div`
  margin: 12px;
  font-size: 18px;
  color: ${(props) => props.theme.text};
`;
