import React, { useState, useCallback, useContext, useEffect } from "react";
import styled from "@emotion/styled";

// Styles
import {
  QueryContent,
  QuerySectionHeader,
  LogoDiv,
  Logo,
  LinkedQueryString,
} from "./query";
import { QUERYVIEWS } from "../../../common/query";
import { QueryFields } from "./fields";

// Views
import QueryLinker from "./linking";
import { PackageDoc, PackageDocContext } from "./fields/packagedoc";
import { useSearchParams } from "react-router-dom";
import { ProjectLogo } from "./logo";
import { UserContext } from "../../../App";
import { CircularProgress } from "@mui/material";
import { useSchemaCustomizations } from "../../../hooks/projects";

/**
 * This Query component is imported exclusively for new_query,
 * and its role is to implement components of the Query that are
 * only used by Queries that do not yet exist.
 */
export default React.memo(
  ({
    queryData,
    schema,
    schemaData,
    setQueryData,
    queries,
    project,
    setQueryValidationErrors,
    setStatusMessage,
    // From the Query manager
    viewState,
  }) => {
    // Pull query route param
    const [search] = useSearchParams();
    const queryRoute = search.get("qr");

    // No view if trying to print
    if (search.get("qr") === QUERYVIEWS.PRINT) {
      return null;
    }

    switch (queryRoute) {
      case QUERYVIEWS.QUERY:
        return (
          <NewQueryContentPane
            queryData={queryData}
            setQueryData={setQueryData}
            schema={schema}
            schemaData={schemaData}
            queries={queries}
            project={project}
            setQueryValidationErrors={setQueryValidationErrors}
            setStatusMessage={setStatusMessage}
          />
        );
      default:
        return null;
    }
  }
);

const NewQueryContentPane = ({
  queryData,
  setQueryData,
  schema,
  schemaData,
  queries,
  project,
  setQueryValidationErrors,
  setStatusMessage,
}) => {
  // Prompting states
  const [promptQueryLinking, setPromptQueryLinking] = useState(false);

  const currentUser = useContext(UserContext);
  const userCustomSchema = useSchemaCustomizations(
    project,
    schema,
    currentUser.ref.id
  );

  const packageDoc = useContext(PackageDocContext);

  const [fieldSet] = useState(() => {
    if (schemaData.settings?.enablePersonalFields && userCustomSchema) {
      const customFields = userCustomSchema.customFields
        ? userCustomSchema.customFields
        : [];
      return [...(schemaData?.fields || []), ...customFields];
    } else {
      return schemaData?.fields || [];
    }
  });

  // Now add callbacks
  const onFieldChange = useCallback(
    (fieldId) => (val) => {
      setQueryData((ex) => ({ ...ex, data: { ...ex.data, [fieldId]: val } }));
    },
    [setQueryData]
  );

  const setFieldChange = useCallback(
    (callback) => {
      setQueryData((ex) => ({ ...ex, data: callback(ex.data) }));
    },
    [setQueryData]
  );

  if (promptQueryLinking) {
    return (
      <QueryLinker
        setQueryData={setQueryData}
        project={project}
        queries={queries}
        schemaData={schemaData}
        queryData={queryData}
        setPromptQueryLinking={setPromptQueryLinking}
      />
    );
  }

  if (packageDoc !== undefined) {
    return (
      <PackageDoc
        data={queryData.data?.[packageDoc.fieldId]?.[packageDoc?.doc?.id] ?? {}}
        setQueryData={setQueryData}
        setFieldChange={setFieldChange}
        status={"new"}
        setStatusMessage={setStatusMessage}
      />
    );
  }

  return (
    <QueryContent>
      <ProjectLogo />

      {schemaData.linkedQueries && (
        <LinkedQueryString>
          Linked:{" "}
          {queryData.linkedQueries?.from[0].dynamicId
            ? queryData.linkedQueries?.from[0].dynamicId
            : queryData.linkedQueries?.from[0].id ?? "None"}
          <LinkedEditLink onClick={() => setPromptQueryLinking(true)}>
            Edit
          </LinkedEditLink>
        </LinkedQueryString>
      )}

      {/* Data fields for the query. */}
      {fieldSet ? (
        <QueryFields
          data={queryData.data}
          queryData={queryData}
          onFieldChange={onFieldChange}
          setFieldChange={setFieldChange}
          fieldSet={fieldSet}
          setQueryValidationErrors={setQueryValidationErrors}
          status={"new"}
          // queries={queries}
          project={project}
        />
      ) : (
        <Centerer>
          <CircularProgress />
        </Centerer>
      )}
    </QueryContent>
  );
};

const LinkedEditLink = styled.a`
  color: #309ceb;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    color: #3292d9;
  }
`;

const Centerer = styled.div`
  width: 100%;
  height: calc(100% - 127px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
