import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import FileUploader from "react-firebase-file-uploader";
import { CircularProgress, Box, Button } from "@mui/material";

import { SettingDiv, SectionTitle, PhotoPreview } from "./index";
import { Button as CustomButton, Button2 } from "../ui/buttons";
import { SelectField, Inputs } from "../ui/inputs2";
import { ConfirmationModal, AcknowledgeModal } from "../ui/modals";

import { LocationInput } from "../ui/location";

import { generate_tempid } from "../../tools";

import q from "query-it";

export default ({ photoLink, user, userData }) => {
  const [confirmDeleteProfilePhoto, setConfirmDeleteProfilePhoto] =
    useState(false);
  const [loadingProfilePhoto, setLoadingProfilePhoto] = useState(false);
  const [location, setLocation] = useState({ ...userData.location });
  const [locationConfirm, setLocationConfirm] = useState(false);
  const storagePath = "/profiles";

  const FUN_COLOR_SET = [
    "#D32F2F",
    "#FF4081",
    "#303F9F",
    "#448AFF",
    "#388E3C",
    "#8BC34A",
    "#E64A19",
    "#FF8D4D",
    "#0288D1",
    "#00BCD4",
    "#AFB42B",
    "#00796B",
    // "#7C4DFF",
  ];

  const themes = {
    // midnight: 'Midnight',
    purple: "Purple",
    // default: 'Default',
    // light: 'Light',
    // dev: 'Development'
    dark: "Dark",
  };

  const nameHash = (userData.name.first + userData.name.last)
    .split("")
    .reduce((acc, c) => acc + c.charCodeAt(0), 0);

  // Add a useEffect to check if photolink has updated, then clear the loading flag
  useEffect(() => {
    setLoadingProfilePhoto(false);
  }, [photoLink]);

  const makeSetLocation = async () => {
    await user.update({ location });
    setLocationConfirm(true);
  };

  return (
    <>
      <SettingDiv>
        <ConfirmationModal
          open={confirmDeleteProfilePhoto}
          body="Are you sure you want to delete your profile photo? You can always upload a new photo later."
          onConfirm={() => {
            user.update({ ...userData, profilePhoto: "" });
            setConfirmDeleteProfilePhoto(false);
          }}
          onCancel={() => setConfirmDeleteProfilePhoto(false)}
        />
        <ProfilePhotoDiv
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <SectionTitle style={{ marginBottom: "5px" }}>
            Set Profile Photo
          </SectionTitle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              width: 1,
            }}
          >
            <PhotoPreviewContainer
              color={FUN_COLOR_SET[nameHash % FUN_COLOR_SET.length]}
            >
              {loadingProfilePhoto && (
                <PhotoDefault>
                  <CircularProgress />
                </PhotoDefault>
              )}
              {!loadingProfilePhoto && (
                <>
                  {photoLink && <PhotoPreview src={photoLink} />}
                  {!photoLink && (
                    <PhotoDefault>
                      {userData.name.first[0]}
                      {userData.name.last[0]}
                    </PhotoDefault>
                  )}
                </>
              )}
            </PhotoPreviewContainer>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                rowGap: "15px",
                justifyContent: "space-evenly",
              }}
            >
              <Button variant="contained" component="label">
                {!photoLink ? "Upload" : "Change"} Photo
                <FileUploader
                  hidden
                  onUploadStart={() => setLoadingProfilePhoto(true)}
                  onUploadSuccess={(fileName) => {
                    user.update({ ...userData, profilePhoto: fileName });
                  }}
                  onUploadError={() => setLoadingProfilePhoto(false)}
                  metadata={{}}
                  filename={() => "p" + generate_tempid().substring(1, 7)}
                  storageRef={q.storage?.compatibleRef(storagePath)}
                  disabled={false}
                  accept=".jpg, .jpeg, .png, .gif"
                />
              </Button>
              <Button
                variant="contained"
                onClick={() => setConfirmDeleteProfilePhoto(true)}
              >
                Delete Photo{" "}
              </Button>
            </Box>
          </Box>
        </ProfilePhotoDiv>
      </SettingDiv>

      <SettingDiv>
        <LocationDiv>
          <SectionTitle style={{ marginBottom: "5px" }}>
            Set Your Location
          </SectionTitle>
          <LocationInput contrast data={location} setLocation={setLocation} />
          <Button2
            disabled={!(location && location.country && location.state)}
            onClick={makeSetLocation}
            label="Set Location"
          />
        </LocationDiv>
      </SettingDiv>
      <SettingDiv>
        <ThemeDiv>
          <SectionTitle style={{ marginBottom: "5px" }}>
            Set Theme
          </SectionTitle>
          <Inputs>
            <SelectField
              label="Theme"
              options={themes}
              data={userData.theme}
              onChange={(e) => {
                const { value } = e.target;
                user.update({ theme: value });
              }}
              contrast
              fill
              allowNone={false}
            />
          </Inputs>
        </ThemeDiv>
      </SettingDiv>
      <AcknowledgeModal
        isOpen={locationConfirm}
        autoHideDuration={6000}
        onClose={() => {
          setLocationConfirm(false);
        }}
        acknowledgeMessage={"Location has been saved."}
      />
    </>
  );
};

const PhotoUploader = styled.label`
  padding: 8px 16px;
  height: fit-content;
  width: fit-content;
  background: ${(props) => props.theme.button.inactive};
  color: ${(props) => props.theme.textAlt};

  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 2px;

  min-width: 50px;
  justify-content: center;
  border-radius: 25px;

  user-select: none;
  text-decoration: none;

  white-space: nowrap;
  flex-shrink: 0;

  margin-left: 8px;

  :first-of-type {
    margin-left: 0;
  }

  transition: all 0.1s;
  :hover:not([disabled]) {
    cursor: pointer;
    transform: scale(1.05);
  }

  :active:not([disabled]) {
    transform: scale(0.95);
  }

  &[disabled] {
    background: ${(props) => props.theme.button.disabled};
    color: ${(props) => props.theme.textDisabled};
  }
`;

const PhotoDefault = styled.div`
  color: "${(props) => props.theme.text}";
`;

const PhotoPreviewContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 100px;
  overflow: hidden;
  margin-bottom: 10px;

  user-select: none;
  font-family: ${(props) => props.theme.font};
  background: ${(props) => props.color};

  &:hover {
    cursor: pointer;
  }
`;

const ProfilePhotoDiv = styled.div`
  background: ${(props) => props.theme.step50};
  padding: 20px;
  border-radius: 5px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;

  flex-direction: ${isMobile ? "column" : "row"};
  overflow: hidden;
`;

const LocationDiv = styled.div`
  background: ${(props) => props.theme.step50};
  padding: 20px;
  border-radius: 5px;
`;

const ThemeDiv = styled.div`
  background: ${(props) => props.theme.step50};
  padding: 20px 20px 0 20px; 
  border-radius: 5px;
`;
