import React, { useCallback, useState, useContext, useRef } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Button2 } from "../buttons";
import { IconButton, Menu, MenuItem, MenuList } from "@mui/material";
import { withTheme } from "@mui/styles";
import { DragIndicator, Edit, Check } from "@mui/icons-material";
import Draggable from "react-draggable";
import themes from "../../../themes";

import {
  ChangeContext,
  GlobalDisablerContext,
  SchemaEditOnlyContext,
  TableEditorContext,
} from ".";
import {
  CheckboxCell,
  DateCell,
  RadioCell,
  SelectCell,
  MultiSelectCell,
  TextCell,
} from "./fields";
import { log_error, log_warning } from "../../../tools/logger";
import { useQueryDataStatus } from "./hooks";

const TableBody = ({ columns, data, noHover }) => {
  return (
    <TableBodyMain>
      {data.map((row, rowIndex) => (
        <TableBodyRow
          key={rowIndex}
          rowIndex={rowIndex}
          data={row}
          columns={columns}
          noHover={noHover}
        />
      ))}
    </TableBodyMain>
  );
};

const TableBodyRow = ({
  rowIndex,
  data,
  columns,
  noHover, // Disables the hovering on the table row edit (the buttons become permanently visible on rows)
}) => {
  const [showModifyPrompter, setShowModifyPrompter] = useState(
    noHover ? true : false
  );
  const [isDragging, setIsDragging] = useState(false);

  const { setChange } = useContext(ChangeContext);
  const schemaEditOnly = useContext(SchemaEditOnlyContext);

  const duplicateRow = () => {
    setChange((ex) => ({
      ...ex,
      body: [
        // All indices BEFORE row
        ...(ex.body ?? [{}]).slice(0, rowIndex),
        // The row itself (twice)
        (ex.body ?? [{}])[rowIndex],
        (ex.body ?? [{}])[rowIndex],
        // All indices AFTER row
        ...(ex.body ?? [{}]).slice(rowIndex + 1),
      ],
    }));
  };

  const deleteRow = () => {
    setChange((ex) => ({
      ...ex,
      body: [
        // All indices BEFORE row
        ...(ex.body ?? [{}]).slice(0, rowIndex),
        // All indices AFTER row
        ...(ex.body ?? [{}]).slice(rowIndex + 1),
      ],
    }));
  };

  const moveRow = (oldRowIndex, newRowIndex) => {
    if (newRowIndex > oldRowIndex) {
      setChange((ex) => ({
        ...ex,
        body: [
          // All indices BEFORE the OLD row position
          ...(ex.body ?? [{}]).slice(0, oldRowIndex),
          // All indices BEFORE the NEW row position but AFTER the OLD row position
          ...(ex.body ?? [{}]).slice(oldRowIndex + 1, newRowIndex),
          // The row itself
          (ex.body ?? [{}])[oldRowIndex],
          // All indices AFTER the NEW row position
          ...(ex.body ?? [{}]).slice(newRowIndex),
        ],
      }));
    } else if (newRowIndex < oldRowIndex) {
      newRowIndex += rowIndex;
      newRowIndex = newRowIndex < 0 ? 0 : newRowIndex;
      setChange((ex) => ({
        ...ex,
        body: [
          // All indices BEFORE the NEW row position
          ...(ex.body ?? [{}]).slice(0, newRowIndex),
          // The row itself
          (ex.body ?? [{}])[oldRowIndex],
          // All indices AFTER the NEW row position but BEFORE the old row position
          ...(ex.body ?? [{}]).slice(newRowIndex, oldRowIndex),
          // All indices AFTER the OLD row position
          ...(ex.body ?? [{}]).slice(oldRowIndex + 1),
        ],
      }));
    }
  };

  return (
    <Draggable
      handle=".handle"
      key={rowIndex}
      onStart={() => setIsDragging(true)}
      onStop={
        (e, { y }) =>
          !setIsDragging(false) && y >= 0
            ? moveRow(rowIndex, rowIndex + Math.round(y / 17)) //move row down
            : moveRow(rowIndex, Math.round(y / 30)) //move row up
      }
      position={{ x: 0, y: 0 }}
      axis="y"
    >
      <TableBodyTRow
        onMouseEnter={() => setShowModifyPrompter(true)}
        onMouseLeave={() => setShowModifyPrompter(false)}
        style={
          isDragging
            ? { position: "relative", zIndex: 100 }
            : { position: "relative" }
        }
        onContextMenu={() => log_warning("SHOW MODIFY MENU not ready yet!")} // TODO: Implement
      >
        {/* We always render the modify prompter first, but it may never be visible in the modifyprompter cell of the table */}
        <ModifyPrompter>
          {!schemaEditOnly && showModifyPrompter && (
            <ModifyPrompterButton
              noHoverIcon={noHover}
              options={[
                { label: "Duplicate", action: duplicateRow },
                { label: "Delete", action: deleteRow },
              ]}
              isDragging={isDragging}
            />
          )}
        </ModifyPrompter>
        {columns.map((field, colIndex) => (
          <TableFieldGenerator
            field={field}
            data={data}
            rowIndex={rowIndex + 1}
            key={colIndex}
          />
        ))}
      </TableBodyTRow>
    </Draggable>
  );
};

// NOTE: rowIndex is 1-index'ed here!
const TableFieldGenerator = React.memo(({ data, field, rowIndex }) => {
  // Manage style
  const [isFocus, setIsFocus] = useState(false);
  const colSpan = field.type === "radio" ? field?.options?.length : 1; // TODO: Maybe this resolver should move to fields to standardize?

  const { setChange } = useContext(ChangeContext);
  const schemaEditOnly = useContext(SchemaEditOnlyContext);
  const { tableColumnEditor, setTableColumnEditor, setTableHeaderEditor } =
    useContext(TableEditorContext);
  const queryStatus = useQueryDataStatus();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editableCondition = useCallback(
    new Function("row", field.editableCondition ?? "return true"),
    [field]
  );

  // Now we'll check at all appropriate levels to figure out if this field is disabled!
  const globalDisabled = useContext(GlobalDisablerContext); // 1. Global disabler
  const editableWhileDisabled =
    !field.editableStatusSet?.includes(queryStatus) &&
    !!field.editableStatusSet &&
    queryStatus !== undefined; // 2. Status disabler
  const editableConditionDisabled = !editableCondition(data);
  const disabled =
    globalDisabled || editableWhileDisabled || editableConditionDisabled;

  const setRowChange = useCallback(
    (callback) => {
      setChange((ex) => ({
        ...ex,
        body: [
          // All indices BEFORE row
          ...(ex?.body ?? [{}]).slice(0, rowIndex - 1),
          // The row itself
          {
            ...(ex?.body ?? [{}])[rowIndex - 1],
            [field.id]: callback(
              (ex?.body ?? [{}])[rowIndex - 1][field.id] ?? undefined
            ),
          },
          // All indices AFTER row
          ...(ex?.body ?? [{}]).slice(rowIndex),
        ],
      }));
    },
    [setChange, rowIndex, field]
  );

  // Let's use a switch to resolve the field type and determine what we need to put here
  let readyComponent = null;
  switch (field.type) {
    case "text":
      readyComponent = (
        <TextCell
          data={data[field.id]}
          setChange={setRowChange}
          disabled={false}
        />
      );
      break;
    case "number":
      readyComponent = (
        <TextCell
          data={data[field.id]}
          setChange={setRowChange}
          type="number"
          disabled={false}
        />
      );
      break;
    case "select":
      readyComponent = (
        <SelectCell
          data={data[field.id]}
          setChange={setRowChange}
          options={field.options}
          disabled={false}
        />
      );
      break;
    case "mselect":
      readyComponent = (
        <MultiSelectCell
          data={data[field.id]}
          setChange={setRowChange}
          options={field.options}
          disabled={false}
        />
      );
      break;
    case "date":
      readyComponent = (
        <DateCell
          data={data[field.id]}
          setChange={setRowChange}
          disabled={false}
        />
      );
      break;
    case "checkbox":
      readyComponent = (
        <CheckboxCell
          data={data[field.id]}
          setChange={setRowChange}
          disabled={false}
        />
      );
      break;
    case "radio":
      readyComponent = (
        <RadioCell
          data={data[field.id]}
          setChange={setRowChange}
          options={field.options}
          disabled={false}
        />
      );
      break;
    case "button":
      // only render if type has editable options
      readyComponent = ["boxset", "tableradio", "select", "files"].includes(
        data.type
      ) && (
        <Button2
          sx={{
            padding: "2px",
            fontSize: "11px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          label="Edit"
          onClick={() => field.action(data.id)}
        />
      );
      break;
    case "hidden":
      break; // noop
    case "rowindex":
      // In this case it's just an uneditable rowIndex displayed
      readyComponent = rowIndex;
      break;
    default:
      log_error(
        "The table input could not be rendered successfully, ID: " +
          field.id +
          " with type: '" +
          field.type +
          "'"
      );
      readyComponent = "";
      break;
  }

  if (disabled) {
    // This may not always work, might need to put more effort in here
    readyComponent = readyComponent?.data ?? data[field.id];
  }

  if (readyComponent === null) {
    return null;
  }

  return (
    <TableBodyTCell
      colSpan={colSpan}
      isFocus={isFocus}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
    >
      <EditButtonContainer>
        {schemaEditOnly && (
          <IconButton
            onClick={() => {
              if (tableColumnEditor?.id === field.id) {
                setTableColumnEditor(undefined);
                setIsFocus(false);
              } else {
                setTableHeaderEditor(undefined);
                setTableColumnEditor(field);
              }
            }}
            style={{ padding: 0 }}
          >
            {tableColumnEditor?.id === field.id ? <Check /> : <Edit />}
          </IconButton>
        )}
      </EditButtonContainer>
      {!schemaEditOnly && readyComponent}
    </TableBodyTCell>
  );
}, tableFieldMemoizeChecker);

export const ModifyPrompterButton = ({ noHoverIcon, options }) => {
  const [showPrompt, setShowPrompt] = useState(undefined);
  const [isClickAndHold, setIsClickAndHold] = useState(false);
  const timerRef = useRef(null);

  const handleMouseDown = () => {
    timerRef.current = setTimeout(() => {
      setIsClickAndHold(true);
    }, 200); // 1/5th a second
  };

  const handleMouseUp = (e) => {
    clearTimeout(timerRef.current);
    if (!isClickAndHold) {
      //Clicked without holding, open the menu
      setShowPrompt(e.currentTarget);
    } else {
      //Clicked and held, do not open menu (interfere with dragging)
    }
    setIsClickAndHold(false);
  };

  return (
    <>
      <Menu
        open={!!showPrompt}
        onClose={() => setShowPrompt(undefined)}
        anchorEl={showPrompt}
        transformOrigin={{
          vertical: 0,
          horizontal: "left",
        }}
      >
        <MenuList dense style={{ padding: 0 }}>
          {options?.map((option) => (
            <MenuItem
              key={`modify-prompt-${option.label}`}
              onClick={() => {
                option.action();
                setShowPrompt(false);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <IconButton
        onMouseDown={handleMouseDown}
        onMouseUp={(e) => handleMouseUp(e)}
        style={{ padding: 0 }}
      >
        {!noHoverIcon && <DragIndicator className="handle" color="action" />}
        {noHoverIcon && <Edit color="action" />}
      </IconButton>
    </>
  );
};

export default TableBody;

const TableBodyMain = styled.tbody``;

const TableBodyTRow = styled.tr`
  height: 26px;
`;

// So that head cells can inherit :)
export const TableBodyTCell = withTheme(styled.td`
  position: relative;
  border-width: 1px;
  border-style: ${(props) => (props.isFocus ? "double" : "solid")};
  border-color: ${(props) =>
    props.isFocus
      ? props.theme.palette.action.active
      : props.theme.palette.action.disabled};
  padding: 2px 4px;
  background-color: white;
`);

export const ModifyPrompter = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 100%;
`;

export const EditButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
`;

/**
 * Here we draw inspiration from what has been done in the *regular* fields,
 * except here we're being EVEN MORE pedantic about the data we're rendering :)
 *
 * Otherwise our table cascades into rendering a lot of useless stuff infinitely many times
 */
function tableFieldMemoizeChecker(previousProps, nextProps) {}
