import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  Radio,
  Checkbox,
  Switch,
  IconButton,
  InputAdornment,
  ListItemText,
  Input,
  LinearProgress,
  Button as MuiButton,
  FormLabel,
  RadioGroup,
  FormGroup,
  Dialog,
  Stack,
  Typography,
  Autocomplete,
  Tooltip,
  Box,
  Chip,
} from "@mui/material";
import {
  ArrowUpward,
  ArrowDownward,
  Clear,
  CameraAlt,
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  OpenInNew,
} from "@mui/icons-material";
import { DatePicker, TimePicker, DateTimePicker } from "@mui/x-date-pickers";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import FileUploader from "react-firebase-file-uploader";
import SignaturePad from "react-signature-canvas";
import { format } from "date-fns";

import { error_text_from_code } from "../../tools/forms";
import { parse_db_timestamp } from "../../tools";

import q, { StorageFile } from "query-it";
import { APIStorage } from "query-it";
import { UserDataContext } from "../../App";

export const useInputStyles = makeStyles((theme) => {
  const underline = {
    "& .MuiInput-underline:before": {
      borderBottomColor: (props) =>
        props.contrast ? theme.palette.text.primary : theme.palette.grey[300],
    },
    "& .MuiSelect-icon": {
      color: (props) =>
        props.contrast ? theme.palette.text.primary : theme.palette.grey[300],
    },
  };

  const inputField = {
    color: (props) =>
      (props.contrast ? theme.palette.text.primary : "#000") + "!important",
  };

  const inputHeight = {
    minHeight: (props) => (props.tall ? "38px" : "unset"),
  };

  const inputLabel = {
    color: (props) =>
      (props.contrast ? theme.palette.text.primary : theme.palette.grey[300]) +
      "!important",
    "& .Mui-disabled": {
      color: (props) =>
        (props.contrast
          ? theme.palette.text.disabled
          : theme.palette.grey[500]) + "!important",
    },
  };

  const sizing = {
    maxWidth: (props) => (props.sz == "sm" ? "80% !important" : "unset"),
  };

  return {
    formControl: {
      margin: (props) => (props.slim ? 0 : theme.spacing(1)),
      minWidth: (props) => (props.fill ? undefined : 160),
      maxWidth: (props) => (props.noWidthCap || props.fill ? undefined : 600),
      width: (props) => (props.fill ? "95%" : "auto"),
      paddingBottom: (props) => (props.error ? 0 : "24px"),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    input: { ...underline, ...inputField, ...inputHeight },
    inputLabel: { ...inputLabel },
    label: { ...inputLabel },
    button: {
      // backgroundColor: theme.palette.primary.main,
      // '&:hover': {
      //   backgroundColor: theme.palette.primary.main
      // },
      color: theme.palette.text.secondary,
      ...sizing,
    },
    box: {
      color: theme.palette.primary.main + " !important",
    },
  };
});

export const GenericField = React.memo(
  ({
    data,
    key,
    onChange,
    label,
    helpText,
    charLimit,
    error,
    style,
    multiLine = false,
    disabled = false,
    contrast,
    fill,
    number,
    password,
    noWidthCap = false,
    noBorder,
    noBottomPad,
    maxWidth,
    size,
    ...params
  }) => {
    useTheme(); // Make sure custom theme is used
    // NOTE: Error display is disabled when noBottomPad is true
    const classes = useInputStyles({
      contrast,
      fill,
      noWidthCap,
      tall: multiLine,
      error: !!error || noBottomPad,
    });

    const onInputChange = useCallback(
      (e) => {
        if (!onChange) {
          return;
        }
        // Let's type cast the value to number if type is number
        if (number) {
          try {
            e = {
              ...e,
              target: { ...e.target, value: Number(e.target.value) },
            };
          } catch {
            e = { ...e, target: { ...e.target, value: 0 } };
          }
        }
        if (charLimit) {
          try {
            const { value } = e.target;
            if (value.length > charLimit) {
              return false;
            }
            return onChange(e);
          } catch {
            return onChange(e);
          }
        } else {
          return onChange(e);
        }
      },
      [onChange, charLimit, number]
    );

    // Now render the actual field
    return (
      <FormControl
        className={classes.formControl}
        style={{ ...style, maxWidth: maxWidth ? maxWidth : style?.maxWidth }}
      >
        <TextField
          disabled={disabled}
          {...params}
          label={label}
          size={size}
          value={data ? data : ""}
          error={error !== undefined}
          helperText={
            error !== undefined && !noBottomPad ? (
              <>{error_text_from_code(error)}</>
            ) : undefined
          }
          onChange={onInputChange}
          multiline={multiLine || disabled}
          variant={multiLine && !noBorder ? "outlined" : "standard"}
          type={number ? "number" : password ? "password" : "text"}
          InputProps={{
            endAdornment:
              charLimit === undefined ? null : (
                <InputAdornment position="end">
                  {data ? data.length : ""}/{charLimit}
                </InputAdornment>
              ),
            className: classes.input,
            inputProps: { min: 0 },
          }}
          InputLabelProps={{
            classes: { root: classes.label },
          }}
        />
      </FormControl>
    );
  }
);

export const SelectField = React.memo(
  ({
    data,
    options,
    onChange,
    label,
    helpText,
    error,
    style,
    optionFormat, // Only applies to the visible label
    disabled = false,
    allowNone = true,
    multi,
    nativeSelect = false,
    autocomplete = false,
    contrast,
    fill,
    slim,
    maxWidth,
  }) => {
    useTheme(); // Make sure custom theme is used
    const classes = useInputStyles({
      contrast,
      fill,
      error: !!error || slim,
      slim,
    });

    // Check that the data is in options or set invalid flag
    let invalid = options
      ? (multi && !Array.isArray(data)) ||
        (!multi && !Object.keys(options).includes(data))
      : false;

    // No options present, so this is basically just a placeholder
    if (!options) {
      return (
        <GenericField label={label} disabled contrast={contrast} fill={fill} />
      );
    }

    // Now render the actual field
    if (autocomplete) {
      // Before handing off to autocomplete, the options need to be visibly unique, so we'll append numbers on duplicates
      const uniqueOptionsReverse = {};
      Object.keys(options).forEach((key) => {
        let label = options[key];
        let i = 1;
        while (uniqueOptionsReverse[label]) {
          label = options[key] + " (" + i + ")";
          i++;
        }
        uniqueOptionsReverse[label] = key;
      });
      const uniqueOptions = {};
      Object.keys(uniqueOptionsReverse).forEach((key) => {
        uniqueOptions[uniqueOptionsReverse[key]] = key;
      });
      // Now render
      return (
        <FormControl
          variant="standard"
          className={classes.formControl}
          style={{ ...style, maxWidth: maxWidth ? maxWidth : style?.maxWidth }}
        >
          <Autocomplete
            options={Object.keys(uniqueOptions).map((key) => ({
              key: key,
              id: key,
              value: key,
              label: uniqueOptions[key],
            }))}
            getOptionLabel={
              optionFormat ??
              ((option) => option?.label ?? uniqueOptions?.[option] ?? "")
            }
            isOptionEqualToValue={(option, value) => option.key === value}
            value={(data?.length ?? 0) < 1 && !multi ? null : data}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  classes: { underline: classes.underline },
                  className: classes.input,
                }}
                label={label}
              />
            )}
            onChange={(e, val) =>
              onChange({
                target: {
                  value: multi
                    ? val?.map((v) => (v.key ? v.key : v)) ?? []
                    : val?.key || val,
                },
              })
            }
            disabled={Object.keys(options).length < 1 || disabled}
            multiple={multi}
            renderTags={(tag, getTagProps) =>
              tag.map((user, index) => (
                <Chip
                  color="secondary"
                  variant="standard"
                  label={`${options[user]}`}
                  {...getTagProps({ index })}
                  key={`${user}`}
                />
              ))
            }
          />
          {(helpText || !!error) && (
            <FormHelperText style={{ color: error ? "#d32f2f" : "black" }}>
              {!error
                ? helpText
                : error !== undefined
                ? error_text_from_code(error)
                : ""}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else {
      return (
        <FormControl
          variant="standard"
          className={classes.formControl}
          style={{ ...style, maxWidth: maxWidth ? maxWidth : style?.maxWidth }}
        >
          <InputLabel htmlFor={label} className={classes.label}>
            {label}
          </InputLabel>
          <Select
            className={classes.input}
            native={nativeSelect}
            value={invalid ? (multi ? [] : "") : data}
            onChange={onChange}
            input={
              <Input className={classes.input} error={error !== undefined} />
            }
            renderValue={(selected) =>
              multi
                ? selected.length > 3
                  ? selected
                      .slice(0, 3)
                      .map((sel) => options[sel])
                      .join(", ") + "..."
                  : selected.map((sel) => options[sel]).join(", ")
                : options[selected]
            }
            inputProps={{
              className: classes.input,
              name: label ? label.toLowerCase() : "",
              id: label,
            }}
            disabled={Object.keys(options).length < 1 || disabled}
            multiple={multi}
          >
            {!nativeSelect && allowNone && !multi && (
              <MenuItem value=""></MenuItem>
            )}
            {!nativeSelect &&
              Object.keys(options).map((opts) => (
                <MenuItem key={opts} value={opts}>
                  {multi ? (
                    <>
                      <Checkbox
                        checked={(data ? data : []).indexOf(opts) > -1}
                      />
                      <ListItemText primary={options[opts]} />
                    </>
                  ) : (
                    options[opts]
                  )}
                </MenuItem>
              ))}
            {nativeSelect && allowNone && !multi && <option value=""></option>}
            {nativeSelect &&
              Object.keys(options).map((opts) => (
                <option key={opts} value={opts}>
                  {options[opts]}
                </option>
              ))}
          </Select>
          {(helpText || !!error) && (
            <FormHelperText style={{ color: error ? "#d32f2f" : "black" }}>
              {!error
                ? helpText
                : error !== undefined
                ? error_text_from_code(error)
                : ""}
            </FormHelperText>
          )}
        </FormControl>
      );
    }
  }
);

export const SpreadSelectField = ({
  data,
  key,
  label,
  onChange,
  options,
  error,
  multi, // Changes from radio to checkbox list
  contrast,
  fill,
  disabled,
  ...props
}) => {
  useTheme(); // Make sure custom theme is used
  const classes = useInputStyles({
    contrast,
    fill,
    noWidthCap: true,
    error: !!error,
  });

  // No options present, so this is basically just a placeholder
  if (!options) {
    return (
      <GenericField label={label} disabled contrast={contrast} fill={fill} />
    );
  }

  let fieldset;

  data = data ? data : [];

  // Note: "disabled" may not be required on FormGroup or RadioGroup

  if (multi) {
    fieldset = (
      <FormGroup row disabled={disabled}>
        {Object.keys(options).map((optKey) => (
          <FormControlLabel
            key={`checkbox-opt${optKey}`}
            control={
              <Checkbox
                checked={data.includes(optKey)}
                onChange={() =>
                  onChange({
                    target: {
                      value: [
                        ...data.filter((dt) => dt !== optKey),
                        ...(data.includes(optKey) ? [] : [optKey]),
                      ],
                    },
                  })
                }
              />
            }
            label={options[optKey]}
          />
        ))}
      </FormGroup>
    );
  } else {
    fieldset = (
      <RadioGroup
        value={data ? data : ""}
        onChange={onChange}
        row
        disabled={disabled}
      >
        {Object.keys(options).map((optKey) => (
          <FormControlLabel
            key={`radio-opt${optKey}`}
            value={optKey}
            control={<Radio />}
            label={options[optKey]}
          />
        ))}
      </RadioGroup>
    );
  }

  return (
    <FormControl className={classes.formControl} disabled={disabled}>
      {label && (
        <FormLabel component="legend" style={{ marginBottom: 15 }}>
          {label}
        </FormLabel>
      )}
      {fieldset}
      {error && (
        <FormHelperText style={{ color: "#d32f2f" }}>
          {error !== undefined ? error_text_from_code(error) : ""}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const BooleanField = ({
  data,
  label,
  onChange,
  value,
  checkbox,
  error,
  radio,
  useIndex,
  boxColor,
  contrast,
  center,
  size,
  disabled,
  ...props
}) => {
  let internal;
  useTheme(); // Make sure custom theme is used
  const classes = useInputStyles({
    contrast: true,
    fill: false,
    error: !!error,
  });

  const boolToggle = useCallback(
    (e) => {
      onChange({
        target: { value: useIndex ? e.target.value : data ? !data : true },
      });
    },
    [onChange, data, useIndex]
  );

  if (radio) {
    internal = (
      <Radio
        checked={data ? data : false}
        onChange={boolToggle}
        disabled={disabled}
        color={boxColor}
        {...(boxColor ? {} : { classes: { root: classes.box } })}
      />
    );
  } else if (checkbox) {
    internal = (
      <Checkbox
        checked={data ? data : false}
        onChange={boolToggle}
        disabled={disabled}
        color={boxColor}
        {...(boxColor ? { classes: { root: classes.box } } : {})}
        style={size === "slim" ? { paddingTop: 0, paddingBottom: 0 } : {}}
      />
    );
  } else {
    // switch
    internal = (
      <Switch
        checked={data ? data : false}
        onChange={boolToggle}
        color={boxColor}
      />
    );
  }

  return (
    <FormControlLabel
      value={value ? value : "undetermined"}
      control={internal}
      label={label}
      disabled={disabled}
      error={error}
      {...props}
    />
  );
};

/*
  This locality field operates on the expectation that either time, date or both will be selected and enabled. 
  If not, nothing will render!
*/
export const LocalityField = ({
  data,
  onChange,
  label,
  error,
  style,
  disabled = false,
  contrast = false,
  useDate = false,
  useTime = false,
}) => {
  useTheme(); // Make sure custom theme is used
  const classes = useInputStyles({ contrast, error: !!error });

  const [inputTimeStamp, setInputTimeStamp] = useState(
    parse_db_timestamp(data)
  );

  useEffect(() => {
    setInputTimeStamp(parse_db_timestamp(data));
  }, [data]);

  // Note: "align-self" compensates for the fact that these are centered by default on mobile
  return (
    <FormControl
      className={classes.formControl}
      style={{ alignSelf: "flex-start", ...style }}
    >
      {useDate && useTime && (
        <DateTimePicker
          label={label}
          value={inputTimeStamp ? inputTimeStamp : null}
          onChange={onChange}
          disabled={disabled}
          error={error}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error}
              variant="standard"
            />
          )}
          inputProps={{
            className: classes.input,
          }}
          InputLabelProps={{
            className: classes.label,
          }}
        />
      )}
      {useDate && !useTime && (
        <DatePicker
          label={label}
          value={inputTimeStamp ? inputTimeStamp : null}
          onChange={onChange}
          disabled={disabled}
          error={error}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error}
              variant="standard"
            />
          )}
          inputProps={{
            className: classes.input,
          }}
          InputLabelProps={{
            className: classes.label,
          }}
        />
      )}
      {!useDate && useTime && (
        <TimePicker
          label={label}
          value={inputTimeStamp ? inputTimeStamp : null}
          onChange={onChange}
          disabled={disabled}
          error={error}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error}
              variant="standard"
            />
          )}
          inputProps={{
            className: classes.input,
          }}
          InputLabelProps={{
            className: classes.label,
          }}
        />
      )}
    </FormControl>
  );
};

export const FileField = ({
  files,
  onChange, // On Change for file field needs to be of a form to simply accept the updated file array
  label,
  path,
  bucket,
  disabled,
  error,
  contrast,
  deleteWithPrejudice,
  native,
  mini, // mini will make the new file button much smaller
}) => {
  useTheme(); // Make sure custom theme is used
  const classes = useInputStyles({
    contrast,
    sz: mini ? "sm" : "md",
    slim: mini,
  });

  const updateCredit = useRef(0);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileBucket, setFileBucket] = useState(undefined);

  const fileDelete = (file) => {
    if (!fileBucket) {
      return;
    }
    // Filenames are guaranteed unique
    if (deleteWithPrejudice) {
      fileBucket
        .compatibleRef(file.location)
        .delete()
        .then((res) => {
          onChange(files.filter((fl) => fl.name !== file.id));
        });
    } else {
      onChange(files.filter((fl) => fl.id !== file.id));
    }
  };

  const fileAdd = useCallback(
    (file) => {
      onChange([...(files ? files : []), file]);
    },
    [files, onChange]
  );

  // Set & get File Bucket
  useEffect(() => {
    if (!bucket) {
      setFileBucket(q.storage);
    } else {
      setFileBucket(q.getProjectStorage(bucket));
    }
  }, [bucket]);

  return (
    <FormControl className={classes.formControl}>
      <FormLabel htmlFor={""} className={classes.label}>
        {label}
      </FormLabel>
      {fileUploading && (
        <ProgressContainer>
          <LinearProgress />
        </ProgressContainer>
      )}
      {(files ? files : []).map((fl) => (
        <UploadedFile
          key={fl.id}
          disabled={disabled}
          file={fl}
          storage={fileBucket}
          onDelete={fileDelete}
          clearMode={!deleteWithPrejudice}
        />
      ))}
      {(!files || files.length < 1) && <FileDiv>No files uploaded yet</FileDiv>}

      {!native && !disabled && (
        <FirebaseFileUploadButton
          addFile={fileAdd}
          storageRef={fileBucket?.compatibleRef(path)}
          setFileUploading={setFileUploading}
          buttonClass={classes.button}
          size={mini ? "sm" : "md"}
        />
      )}
      {native && !disabled && <InternalFileUploadButton />}
      {error && (
        <FormHelperText style={{ color: "#d32f2f" }}>
          {error !== undefined ? error_text_from_code(error) : ""}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const UploadedFile = ({ file, onDelete, storage, disabled, clearMode }) => {
  const [fileURL, setURL] = useState("http://google.ca");

  useEffect(() => {
    if (storage) {
      APIStorage.getDownloadURL(
        storage.ref(file.location ? file.location : file)
      ).then(setURL);
    }
  }, [storage, file]);

  return (
    <FileDiv>
      <FileLink target="_blank" href={fileURL}>
        {file.name ? file.name : file.split("/").pop()}
      </FileLink>
      {!disabled && (
        <FileDelIcon
          className="material-icons"
          onClick={() => onDelete(file)}
          key="file-del"
        >
          {clearMode ? "close" : "delete"}
        </FileDelIcon>
      )}
    </FileDiv>
  );
};

const FirebaseFileUploadButton = ({
  addFile,
  storageRef,
  path,
  setFileUploading,
  buttonClass,
  noText,
  size,
}) => {
  const [file, setFile] = useState(undefined);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const currentUser = useContext(UserDataContext); // We hope this is set and default otherwises

  useEffect(() => {
    if (file !== undefined && uploadSuccess !== false) {
      // See if we can process file type
      const fileType = file.filename.split(".").pop();
      addFile({
        ...file.toObject(),
        location: uploadSuccess,
        fileType: fileType,
        uploadedBy: currentUser?.id ?? "",
        uploadedAt: new Date(),
        virusScanned: false,
      });
      setFile(undefined);
      setUploadSuccess(false);
      setFileUploading(false);
    }
  }, [file, uploadSuccess, addFile, setFileUploading, currentUser]);

  return (
    <MuiButton className={buttonClass} component="label">
      {noText
        ? ""
        : storageRef
        ? size === "sm"
          ? "New File"
          : "Click to upload file"
        : "..."}
      {storageRef && (
        <FileUploader
          hidden
          onUploadStart={(e) => {
            setFileUploading(true);
          }}
          onUploadSuccess={(fileName, tsk) => {
            setUploadSuccess(tsk._metadata.fullPath);
          }}
          metadata={{}}
          filename={(file) => {
            let fobj = new StorageFile(file.name);
            setFile(fobj);
            return fobj.id;
          }}
          storageRef={storageRef}
          multiple
        />
      )}
    </MuiButton>
  );
};

/* This one's a little weird because obviously it's not an upload for saving
  situation, which means we need an onchange for processing the file in-line
*/
const InternalFileUploadButton = ({ onChange }) => {
  return <button>Not available</button>;
};

export const PhotosField = ({
  label,
  files,
  bucket,
  path,
  onChange,
  disabled,
  error,
  deleteWithPrejudice,
  // Style configurations
  contrast,
  mini,
}) => {
  useTheme(); // Make sure custom theme is used
  const classes = useInputStyles({ contrast, sz: mini ? "sm" : "md" });

  const [fileUploading, setFileUploading] = useState(false);
  const [fileBucket, setFileBucket] = useState(undefined);
  const [fullscreen, setFullscreen] = useState(false);
  const [fullscreenFileURL, setFullscreenFileURL] = useState(undefined);
  const [fullscreenFileIndex, setFullscreenFileIndex] = useState(undefined);

  const fileDelete = (file) => {
    if (!fileBucket) {
      return;
    }
    // Filenames are guaranteed unique
    if (deleteWithPrejudice) {
      fileBucket
        .compatibleRef(file.location)
        .delete()
        .then((res) => {
          onChange(files.filter((fl) => fl.name !== file.id));
        });
    } else {
      onChange(files.filter((fl) => fl.id !== file.id));
    }
  };

  const fileAdd = useCallback(
    (file) => {
      onChange([...(files ? files : []), file]);
    },
    [files, onChange]
  );

  // Set & get File Bucket
  useEffect(() => {
    if (!bucket) {
      setFileBucket(q.storage);
    } else {
      setFileBucket(q.getProjectStorage(bucket));
    }
  }, [bucket]);

  // get selected full screen image
  useEffect(() => {
    if (fileBucket && fullscreenFileIndex != undefined) {
      let file = files[fullscreenFileIndex];
      APIStorage.getDownloadURL(
        fileBucket.ref(file.location ? file.location : file)
      ).then(setFullscreenFileURL);
    }
  }, [fileBucket, files, fullscreenFileIndex]);

  const handleCloseFullscreenPhoto = () => {
    setFullscreen(false);
    setFullscreenFileURL(undefined);
    setFullscreenFileIndex(undefined);
  };

  const handleLeftArrowClick = () => {
    if (files.length < 2) {
      return;
    }
    if (fullscreenFileIndex === 0) {
      setFullscreenFileIndex(files.length - 1);
    } else {
      setFullscreenFileIndex(fullscreenFileIndex - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (files.length < 2) {
      return;
    }
    if (fullscreenFileIndex === files.length - 1) {
      setFullscreenFileIndex(0);
    } else {
      setFullscreenFileIndex(fullscreenFileIndex + 1);
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <FormLabel htmlFor={""} className={classes.label}>
        {label}
      </FormLabel>
      <FootnoteText>Accepts jpg, png, gif, mov, mp4, heic</FootnoteText>
      <PhotosContainer>
        {/* First list all existing photos */}
        {(files ? files : []).map((fl, index) => (
          <UploadedPhoto
            key={fl.id}
            disabled={disabled}
            file={fl}
            storage={fileBucket}
            onDelete={fileDelete}
            clearMode={!deleteWithPrejudice}
            setFullscreen={setFullscreen}
            setFullscreenFileIndex={setFullscreenFileIndex}
            index={index}
          />
        ))}
        {/* Now if not disabled show me the new photo button */}
        {!disabled && (
          <NewPhotoButton
            addFile={fileAdd}
            storageRef={fileBucket?.compatibleRef(path)}
            setFileUploading={setFileUploading}
          />
        )}
        {/* If disabled and no photos, show a message */}
        {disabled && (!files || files.length < 1) && (
          <div>No Photos Attached</div>
        )}
      </PhotosContainer>
      <Dialog
        onClose={handleCloseFullscreenPhoto}
        open={fullscreen}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        {fullscreenFileURL ? (
          <FullScreenPhotoContainer>
            <KeyboardArrowLeft onClick={handleLeftArrowClick} />
            <FullScreenPhotoDiv>
              <Close
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "34px",
                  cursor: "pointer",
                }}
                onClick={handleCloseFullscreenPhoto}
              />
              <FullScreenPhotoImg src={fullscreenFileURL} />
            </FullScreenPhotoDiv>
            <KeyboardArrowRight onClick={handleRightArrowClick} />
          </FullScreenPhotoContainer>
        ) : (
          <div>Loading Image</div>
        )}
      </Dialog>
      {error && (
        <FormHelperText style={{ color: "#d32f2f", marginLeft: 0 }}>
          {error !== undefined ? error_text_from_code(error) : ""}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const UploadedPhoto = ({
  file,
  onDelete,
  storage,
  disabled,
  clearMode,
  setFullscreen,
  setFullscreenFileIndex,
  index,
}) => {
  const [fileURL, setURL] = useState("http://google.ca");
  useEffect(() => {
    if (storage) {
      APIStorage.getDownloadURL(
        storage.ref(file.location ? file.location : file)
      ).then(setURL);
    }
  }, [storage, file]);

  return (
    <div id={index}>
      <Close
        style={{
          position: "relative",
          bottom: "45px",
          left: "70px",
          cursor: "pointer",
        }}
        onClick={() => onDelete(file)}
      />
      <PhotoDiv
        src={fileURL}
        onClick={() => {
          setFullscreen(true);
          setFullscreenFileIndex(index);
        }}
      />
    </div>
  );
};

const NewPhotoButton = ({ addFile, storageRef, setFileUploading }) => {
  const [file, setFile] = useState(undefined);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const currentUser = useContext(UserDataContext); // We hope this is set and defualt otherwises

  useEffect(() => {
    if (file !== undefined && uploadSuccess !== false) {
      // See if we can process file type
      const fileType = file.filename.split(".").pop();
      addFile({
        ...file.toObject(),
        location: uploadSuccess,
        fileType: fileType,
        uploadedBy: currentUser.id ?? "",
        uploadedAt: new Date(),
        virusScanned: false,
      });
      setFile(undefined);
      setUploadSuccess(false);
      setFileUploading(false);
    }
  }, [file, uploadSuccess, addFile, setFileUploading, currentUser]);

  return (
    <NewPhotoWrapper>
      {/* Content goes here */}
      <CameraAlt style={{ marginBottom: "6px" }} />
      <div>Add a Photo</div>
      {/* Now the actual interactable */}
      {storageRef && (
        <FileUploader
          hidden
          onUploadStart={(e) => {
            setFileUploading(true);
          }}
          onUploadSuccess={(fileName, tsk) => {
            setUploadSuccess(tsk._metadata.fullPath);
          }}
          metadata={{}}
          filename={(file) => {
            let fobj = new StorageFile(file.name);
            setFile(fobj);
            return fobj.id;
          }}
          storageRef={storageRef}
          multiple
          accept=".jpg, .jpeg, .png, .gif, .heic, .mov, .mp4"
        />
      )}
    </NewPhotoWrapper>
  );
};

export const OrderController = ({ data, onChange, extraOptions = false }) => {
  // This is a simple elevator-style up down order controller
  return (
    <ControllerContainer>
      <VerticalContainer>
        <IconButton
          style={{ padding: "4px" }}
          onClick={() => onChange({ target: { value: Math.max(0, data - 1) } })}
        >
          <ArrowUpward />
        </IconButton>
        <IconButton
          style={{ padding: "4px" }}
          onClick={() => onChange({ target: { value: data + 1 } })}
        >
          <ArrowDownward />
        </IconButton>
      </VerticalContainer>
      <OrderMessage>{data}</OrderMessage>
    </ControllerContainer>
  );
};

export const SignatureField = React.memo(
  ({
    data,
    label,
    onChange,
    drawable,
    signingUser,
    confirmText = "Sign to acknowledge",
    confirmedText = `${data?.user?.name} has signed this Query`,
    contrast,
    disabled,
  }) => {
    useTheme(); // Make sure custom theme is used
    const classes = useInputStyles({ contrast });

    const sigRef = useRef();
    const [internalSignee, setInternalSignee] = useState("");

    const runOnChange = () => {
      // This is where we'll write back out to data with the onChange
      onChange({
        target: {
          value: {
            signature: sigRef.current.toDataURL("image/png"),
            user: signingUser ? signingUser : { name: internalSignee },
          },
        },
      });
    };

    const confirmSignature = () => {
      // Disable further drawing
      sigRef.current.off();
      // Now update the confirm status
      onChange({
        target: {
          value: {
            signature: sigRef.current.toDataURL("image/png"),
            user: signingUser ? signingUser : { name: internalSignee },
            confirmedAt: new Date(),
            confirmed: true,
          },
        },
      });
    };

    const clearSignature = () => {
      sigRef.current.clear();
      onChange({ target: { value: false } });
    };

    useEffect(() => {
      if (
        sigRef.current &&
        (sigRef.current.isEmpty() ||
          sigRef.current.toDataURL("image/png") === "data:,") &&
        data &&
        data?.signature
      ) {
        // This will be set once, and we'll use it to read data in if necessary
        sigRef.current.on(); // Need to turn on to set data
        sigRef.current.fromDataURL(data.signature);
        if (data.confirmed || (data.user && data.user.id !== signingUser?.id)) {
          sigRef.current.off();
        }
      }
      if (disabled) {
        sigRef.current?.off();
      }
    }, [sigRef, data, signingUser, disabled]);

    return (
      <FormControl className={classes.formControl}>
        {label && (
          <FormLabel htmlFor={""} className={classes.label}>
            {label}
          </FormLabel>
        )}
        <SignatureContainer>
          {drawable && (
            <SignatureCanvasContainer disabled={disabled}>
              <SignaturePad
                canvasProps={{
                  style: {
                    width: "100%",
                    height: "100%",
                    background: disabled ? "white" : "#f3f3f3",
                  },
                }}
                ref={(ref) => {
                  sigRef.current = ref;
                }}
                onEnd={() => runOnChange()}
                clearOnResize={false}
              />
            </SignatureCanvasContainer>
          )}
          {!data?.confirmed && (
            <SignatureInteractiveContainer>
              {signingUser && <SignatureText>{confirmText}</SignatureText>}
              {/* If no signing user, we add an input to include one */}
              {!signingUser && (
                <GenericField
                  label="Name"
                  data={internalSignee}
                  onChange={(e) => {
                    const { value } = e.target;
                    setInternalSignee(value);
                  }}
                  noBottomPad
                />
              )}
              <Box
                sx={{
                  marginBottom: !signingUser ? "20px" : 0,
                  minHeight: "40px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {data && (
                  <IconButton disabled={disabled} onClick={clearSignature}>
                    <Clear />
                  </IconButton>
                )}
                {drawable && (
                  <Tooltip
                    title={
                      !data ||
                      (internalSignee.length < 1 && signingUser === undefined)
                        ? "No signature provided"
                        : ""
                    }
                  >
                    <span style={{ paddingLeft: !data ? "8px" : 0 }}>
                      <MuiButton
                        disabled={
                          disabled ||
                          !data ||
                          (internalSignee.length < 1 &&
                            signingUser === undefined)
                        }
                        onClick={confirmSignature}
                        className={classes.button}
                      >
                        Confirm
                      </MuiButton>
                    </span>
                  </Tooltip>
                )}
              </Box>
            </SignatureInteractiveContainer>
          )}
          {data?.confirmed && (
            <SignatureInteractiveContainer>
              <SignatureText>{confirmedText}</SignatureText>
              <SignatureText
                style={{ color: "#5f5f5f", fontSize: 13, marginTop: 0 }}
              >
                {data.confirmedAt
                  ? format(
                      data.confirmedAt.nanoseconds
                        ? parse_db_timestamp(data.confirmedAt)
                        : data.confirmedAt,
                      "MMM do, yyyy 'at' h:mm aaa"
                    )
                  : ""}
              </SignatureText>
            </SignatureInteractiveContainer>
          )}
        </SignatureContainer>
      </FormControl>
    );
  }
);

export const LinkField = ({
  data,
  text,
  click,
  label,
  withLabel,
  ...props
}) => {
  if (data && data.dynamicText != undefined) {
    text = data.dynamicText;
    data = data.value;
  }

  return (
    <FormGroup>
      {withLabel && <FormLabel>{label}</FormLabel>}
      <div style={{ display: "inline-block" }}>
        <Input {...props} defaultValue={text != undefined ? text : data} />
        <IconButton
          style={{ padding: "10px" }}
          disabled={!data}
          onClick={(e) => {
            if (click) {
              e.preventDefault();
              click(data);
            } else {
              // visit link in new tab
              window.open(data, "_blank");
            }
          }}
        >
          <OpenInNew color={data ? "primary" : "disabled"} />
        </IconButton>
      </div>
    </FormGroup>
  );
};

export const SlimTextField = (props) => {
  return (
    <TextField
      inputProps={{
        style: {
          padding: "1px 4px",
        },
      }}
      {...props}
    />
  );
};

// Order Controller
const ControllerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderMessage = styled.div`
  font-size: 1.1rem;
`;

// Progress bar
const ProgressContainer = styled.div`
  width: 200px;
  height: auto;
  min-height: 30px;
`;

// File-related
const FileDiv = styled.div`
  display: flex;
  padding: 8px 2px;
  font-family: ${(props) => props.theme.font};
  align-items: center;
`;

const FileLink = styled.a`
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FileDelIcon = styled.span`
  font-size: 18px;
  cursor: pointer;
  z-index: 120;
  user-select: none;
`;

// Container
const InputDiv = styled.div`
  width: 100%;
  margin-bottom: 12px;
  :last-child {
    margin-bottom: 0;
  }
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: ${isMobile ? "column" : "row"};
  align-items: ${isMobile ? "flex-start" : "center"};

  & > ${InputDiv} {
    margin-left: 8px;
    :first-of-type {
      margin-left: 0;
    }
  }
`;

// Signature Styles
const SignatureContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const SignatureCanvasContainer = styled.div`
  width: 300px;
  height: 80px;
  border-bottom: ${(props) =>
    props.disabled ? "2px solid grey" : "2px solid blue"};
  margin-top: 5px;
`;

const SignatureInteractiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const SignatureText = styled.div`
  margin: 10px;
`;

// Photos Styles
const PhotosContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 6px;
`;

const PhotoDiv = styled.img`
  width: 67px;
  height: 67px;
  margin-right: 8px;
  cursor: pointer;
`;

const FullScreenPhotoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
`;

const FullScreenPhotoDiv = styled.div`
  max-width: 90%;
  height: auto;
`;

const FullScreenPhotoImg = styled.img`
  max-width: 100%;
  height: auto;
  cursor: pointer;
`;

const NewPhotoWrapper = styled.label`
  width: 65px;
  height: 65px;
  font-size: 10px;
  border: 1px dashed #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FootnoteText = styled.div`
  font-size: 12px;
  color: grey;
`;

const LinkContent = styled.a`
  margin: 5px;
  color: black;
  font-family: ${(props) => props.theme.font};
  font-size: 16px;

  &:hover {
    color: blue;
  }
`;
