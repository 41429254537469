import React, { useState, useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import AdvancedTable from "../../ui/tableadv";
import { Typography } from "@mui/material";

export const TableColumnEditor = ({
  columns,
  data,
  setColumnsPreview,
  setDisableConfirm,
}) => {
  const [tableData, setTableData] = useState({ columns: columns });
  const [hasDuplicateHeader, setHasDuplicateHeader] = useState(false);

  const decorateOptions = {
    badge: "badge",
    flag: "flag",
    user: "user",
    dueDate: "due date",
  };
  const sortableOptions = { true: "true", false: "false" };

  useEffect(() => {
    if (!hasDuplicateHeader) {
      setColumnsPreview(tableData.columns);
    }
  }, [tableData.columns, hasDuplicateHeader]);

  const onChange = useCallback((callback) => {
    setTableData((ex) => ({
      ...ex,
      columns: callback({ body: ex?.columns })?.body,
    }));
  }, []);

  let optionsArray;
  let options;

  if (data[0]) {
    const dataOptions = Object.keys(data[0].data).map(
      (string) => "data." + string
    );
    const higherLevelOptions = Object.keys(data[0]).filter(
      (key) =>
        key !== "data" &&
        key !== "distribution" &&
        key !== "__Stringcomposition__" &&
        key !== "schemaRevision" &&
        key !== "approvalsRequired"
    );
    optionsArray = [...dataOptions, ...higherLevelOptions];

    options = {};

    optionsArray.forEach((value) => {
      options[value] = value;
    });
  }

  const hasDuplicateHeaderNames = (columns) => {
    const headerNames = {};

    for (let i = 0; i < columns.length; i++) {
      const headerName = columns[i].headerName;

      if (headerName in headerNames) {
        return true;
      } else {
        headerNames[headerName] = true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (hasDuplicateHeaderNames(tableData.columns) !== hasDuplicateHeader) {
      setHasDuplicateHeader((prev) => !prev);
      setDisableConfirm((prev) => !prev);
    }
  }, [tableData.columns]);

  return (
    <TableBackground>
      <AdvancedTable
        key="table-columns"
        label="Table Columns"
        schema={{
          body: {
            columns: [
              { id: "headerName", title: "Header Name", type: "text" },
              { id: "index", title: "Index", type: "select", options: options },
              {
                id: "sortable",
                title: "Sortable",
                type: "select",
                options: sortableOptions,
              },
              { id: "width", title: "Width", type: "number" },
              {
                id: "decorate",
                title: "Decorate",
                type: "select",
                options: decorateOptions,
              },
              { id: "format", title: "Format", type: "text" },
            ],
          },
          options: { allowImportExport: false },
        }}
        data={tableData ? { body: tableData.columns } : undefined}
        setChange={onChange}
      />
      {hasDuplicateHeader ? (
        <Typography style={{ color: "red", padding: "5px" }}>
          Warning: Do not use duplicate header names. You will not be able to
          save your changes.
        </Typography>
      ) : null}
    </TableBackground>
  );
};

const TableBackground = styled.div`
  display: inline-block;
  background-color: ${(props) => props.theme.palette.grey[400]};
  border-radius: 4px;
`;
