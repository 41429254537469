import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import {
  DataTable,
  MobileGrid,
  MobileGridTile,
  MobileGridNoDataMessage,
} from "../../ui/table";
import { ProjectUsersContext, SelectedSchemaContext } from "..";
import { LoadingBoundary } from "../../../App";
import { parse_db_timestamp } from "../../../tools";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { TEMPLATE_SCHEMA_STATUSSETS } from "../../../common/query";
import { TableColumnEditor } from "../../ui/admin/editor";
import { useUserUpdate } from "../../../hooks/users";

/** The table showing all queries. */
export default ({
  queries,
  schemas,
  projectData,
  userData,
  editMode,
  setColumnsPreview,
  setDisableConfirm,
}) => {
  const [tableColumns, setTableColumns] = useState(undefined);
  const [tableColumnsEditor, setTableColumnsEditor] = useState(undefined);
  const [queryOrder, setQueryOrder] = useState(["createTime"]);
  const [tableData, setTableData] = useState(undefined);
  const users = useContext(ProjectUsersContext);

  const schemaData = useContext(SelectedSchemaContext);

  // Get search param hook
  const [searchParams, setSearchParams] = useSearchParams();

  const updateUser = useUserUpdate();

  // Refresh table schema and data to match new schema when changed
  useEffect(() => {
    setTableData(undefined);
    setTableColumns(undefined);
    setTableColumnsEditor(undefined);
  }, [schemaData]);

  // Adjust table data
  useEffect(() => {
    function sortTableData(a, b) {
      queryOrder.forEach((v) => {
        a = a[v];
        b = b[v];
      });
      let isDateOrdering = !isNaN(Date.parse(a));
      return isDateOrdering ? Date.parse(b) - Date.parse(a) : b - a;
    }
    if (!schemaData || !schemaData.fields) {
      return;
    }
    // Get the fields of the schema
    let optFields = schemaData.fields
      .map((fld) => {
        if (fld.section) {
          return fld.section
            .map((fld) => {
              if (fld.nested) {
                return fld.nested;
              } else {
                return [fld];
              }
            })
            .reduce((acc, cur) => [...acc, ...cur], []);
        } else if (fld.nested) {
          return fld.nested;
        }
        return [fld];
      })
      .reduce((acc, cur) => [...acc, ...cur], [])
      .filter((fld) => fld.type === "select");
    // Put together the data cleanup
    setTableData(
      [...queries]
        .sort(sortTableData)
        .filter((a) => a.status != "deleted")
        .map((dt) => {
          // Evaluate Custom Status for data
          let currentStatus = schemaData.customStatusSet
            ? schemaData.customStatusSet[dt.status]
            : undefined;
          let customStatus = currentStatus
            ? {
                customStatus: {
                  statusType: currentStatus.statusType
                    ? currentStatus.statusType
                    : "open",
                },
              }
            : undefined;
          return {
            ...dt,
            data: Object.keys(dt.data ?? {})
              .map((pl) => {
                let fieldOption;
                if (optFields.some((opt) => opt.id == pl)) {
                  const thisOptField = optFields.find((opt) => opt.id == pl);
                  fieldOption =
                    typeof Object.values(thisOptField.options)[0] === "object"
                      ? Object.values(thisOptField.options).reduce(
                          (acc, ex) => ({ ...acc, ...ex }),
                          {}
                        )[dt.data[pl]]
                      : thisOptField.options[dt.data[pl]];
                }
                return { [pl]: fieldOption ? fieldOption : dt.data[pl] };
              })
              .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
            ...customStatus,
          };
        })
    );
  }, [schemaData, queries, queryOrder]);

  // Adjust table columns & ordering
  useEffect(() => {
    setQueryOrder(
      projectData.tableSort ? projectData.tablesort.split(".") : ["createTime"]
    );
    if (!schemaData) {
      return;
    }
    if (schemaData.tableSchema) {
      setTableColumns(
        schemaData.tableSchema.map((col) => {
          if (col.format) {
            if (col.format === "creator") {
              return {
                ...col,
                format: (row) => {
                  let user = users.find((u) => u.id === row.creator);
                  return user && user.name
                    ? user.name.first + " " + user.name.last
                    : "Unrecognized User";
                },
              };
            } else {
              return { ...col, format: new Function("row", col.format) };
            }
          } else {
            return col;
          }
        })
      );
      setTableColumnsEditor(
        schemaData.tableSchema.map((col) => {
          if (col.format) {
            if (col.format === "creator") {
              return {
                ...col,
                format: (row) => {
                  let user = users.find((u) => u.id === row.creator);
                  return user && user.name
                    ? user.name.first + " " + user.name.last
                    : "Unrecognized User";
                },
              };
            } else {
              return col;
            }
          } else {
            return col;
          }
        })
      );
    } else {
      setTableColumns(defaultColumns);
      setTableColumnsEditor(defaultColumns);
    }
  }, [schemaData, projectData, users]);

  if (!projectData || !tableColumns || !tableData) {
    return <LoadingBoundary />;
  }

  return (
    <QueryTableDiv>
      {!isMobile && !editMode && (
        <DataTable
          data={tableData}
          columns={tableColumns}
          onRowClick={(e) =>
            setSearchParams({ qq: `${projectData.id}-${e.row._id}` })
          }
        />
      )}
      {!isMobile && editMode && (
        <TableColumnEditor
          columns={tableColumnsEditor}
          data={tableData}
          setColumnsPreview={setColumnsPreview}
          setDisableConfirm={setDisableConfirm}
        />
      )}
      {isMobile && tableData && tableColumns && (
        <MobileGrid>
          {tableData.map((tableRow) => {
            const statusSet =
              schemaData?.type === "custom"
                ? schemaData?.customStatusSet
                : TEMPLATE_SCHEMA_STATUSSETS[schemaData?.type];
            let dataRowsData = tableColumns
              .slice(1, 4)
              .map((col) =>
                col.format
                  ? col.format(tableRow)
                  : col.index
                      ?.split(".")
                      .reduce((acc, path) => acc[path], tableRow)
              );
            return (
              <MobileGridTile
                key={tableRow.id}
                onClick={() =>
                  setSearchParams({ qq: `${projectData.id}-${tableRow.id}` })
                }
                title={
                  tableColumns[0].format
                    ? tableColumns[0].format(tableRow)
                    : tableRow[tableColumns[0].field]
                }
                dataRows={tableColumns.slice(1, 4).map(
                  (col, ind) => `${col.headerName}: 
                ${
                  dataRowsData[ind]
                    ? col.headerName.toLowerCase().includes("date")
                      ? resolveDateFormat(dataRowsData[ind])
                      : dataRowsData[ind]
                    : "No Data"
                }`
                )}
                badge={{
                  color: statusSet?.[tableRow.status]?.color,
                  text: tableRow.status,
                }}
              />
            );
          })}
          {tableData.length < 1 && (
            <MobileGridNoDataMessage>
              No Queries Yet, Start Creating!
            </MobileGridNoDataMessage>
          )}
        </MobileGrid>
      )}
    </QueryTableDiv>
  );
};

const resolveDateFormat = (data) => {
  if (
    typeof data === "object" &&
    data.nanoseconds !== undefined &&
    data.nanoseconds >= 0
  ) {
    return format(parse_db_timestamp(data), "dd-MMM-yyyy");
  } else if (typeof data === "number") {
    return format(new Date(data), "dd-MMM-yyyy");
  }
  return data;
};

export const QueryTableNoDataComp = () => {
  return (
    <QueryTableNoData>
      <QueryTableNoDataExplain>
        There are no records to display
      </QueryTableNoDataExplain>
    </QueryTableNoData>
  );
};

/** Basic Column-set for when the user does not define one */
const defaultColumns = [
  {
    name: "ID",
    headerName: "ID",
    selector: "id",
    format: (row) => (row.dynamicId ? row.dynamicId.toUpperCase() : row.id),
    sortable: true,
  },
  {
    name: "Due Date",
    headerName: "Due Date",
    selector: "dueDate",
    sortable: true,
  },
  {
    name: "Status",
    headerName: "Status",
    selector: "status",
    sortable: true,
    format: (row) => row.status.toUpperCase(),
  },
  {
    name: "Pending Approvals",
    headerName: "Pending Approvals",
    selector: "pendingApprovals",
    sortable: true,
    format: (row) =>
      row.pendingApprovals !== undefined ? row.pendingApprovals : "N/A",
  },
];

/** Styles for the query table. This table uses BEM css styles, not styled components, so we use selectors.
 * Refer to that library's documentation for full class info.
 */
const QueryTableDiv = styled.div`
  margin-top: 12px;
  margin-bottom: ${isMobile ? "70px" : "0"};
  height: 0;
  flex-grow: 1;
  overflow: auto;
  border-radius: 4px;

  & .rdt_Table {
    font-family: ${(props) => props.theme.font};
  }

  & .rdt_TableHeadRow {
    background: ${(props) => props.theme.tbl.head};
    & * {
      color: ${(props) => props.theme.text};
    }
  }

  & .rdt_TableRow {
    background: ${(props) => props.theme.tbl.row1};
    color: ${(props) => props.theme.text};
    border-bottom: 1px solid ${(props) => props.theme.gridlines};

    :nth-child(2n) {
      background: ${(props) => props.theme.tbl.row2};
    }

    :hover {
      background: ${(props) => props.theme.tbl.hover};
    }

    :last-child {
      border-bottom: none;
    }
  }
`;

const QueryTableNoData = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: ${(props) => props.theme.text};
  background: ${(props) => props.theme.tbl.head};
`;

const QueryTableNoDataExplain = styled.div`
  padding: 18px;
`;
