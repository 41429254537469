import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from "react";

import { parse_on_fields } from "../tools/forms";
import { deep_comparison, generate_tempid } from "../tools";
import * as utils from "../components/project/analytics/utils";
import { useSchema, useSchemaCustomizations } from "./projects";
import { log_error, log_warning } from "../tools/logger";
import { COPIABLE_FIELDS } from "../common/fields";

import { QueryStatus } from "./status";

import q from "query-it";
import { UserContext } from "../App";

export const useQueries = (project, anonymized) => {
  const [queries, setQueries] = useState(undefined);

  const userData = useContext(UserContext);

  useEffect(() => {
    let mounted = true;
    if (project) {
      let queries = project.queries;
      if (anonymized) {
        queries = queries.where("creator", "==", userData.ref.id);
      }
      const unsubQs = queries.listen((queries) => {
        if (mounted) setQueries(queries);
      });
      return () => {
        unsubQs();
        mounted = false;
      };
    }
  }, [project, anonymized, userData]);

  return queries;
};

export const useNewQueryBatch = (project) => {
  const batch = useRef(null);
  const queryRef = useRef(null);

  useEffect(() => {
    if (project !== undefined) {
      batch.current = q.startTransaction();
      queryRef.current = batch.current.add(project.queries);
      batch.current.set(queryRef.current);
    }
  }, [project]);

  return [batch, queryRef];
};

export const useQueryUnified = (
  { queryId, schemaId },
  { userData, copyQueryIndex } = {},
  project = undefined
) => {
  const [query, setQuery] = useState(undefined);
  const [queryData, setQueryData] = useState(undefined);
  const queryDataStatic = useRef(undefined);

  const [copiedQuery, setCopiedQuery] = useState(undefined);

  // Set state for new query
  const [isNew, setNew] = useState(undefined);

  // Implement schemaData hook
  const [schema, schemaData] = useSchema(
    queryData?.schemaId ?? schemaId,
    project,
    queryData?.schemaRevisionId
  );
  const userCustomSchema = useSchemaCustomizations(
    project,
    schema,
    userData?.id
  );

  // We're adding support for default field conditioning here
  // First we'll need refs to track and update once per state change
  const defaultNeedsUpdate = useRef(false);
  const autoDefault = useRef(false);
  const defaultFields = useRef([]);
  const defaultFieldValues = useRef({});

  // We need to hold some conditions for change checks here
  const approverCondition = useRef(undefined);
  const approversChangedAutomated = useRef(false);
  const approversChangedManually = useRef(false);
  const distributionCondition = useRef(undefined);

  // We'll also need support for calculated field auto-calculation
  // First we need a ref to make sure its applied once per state update
  const calcNeedsUpdate = useRef(false);
  const autoCalc = useRef(false);
  const calculatedFields = useRef([]);

  // Track copy execution with a ref as well
  const copyExecuted = useRef(false);

  // Now we'll also add a dirty handler on any open query
  const [dirty, setDirty] = useState(false);
  const preserveClean = useRef(true);

  // Fetch the copiedQuery data for copy support
  useEffect(() => {
    if (copyQueryIndex) {
      q.projects
        .project(copyQueryIndex?.split("-")?.[0])
        .queries.query(copyQueryIndex?.split("-")?.[1])
        .get()
        .then((data) => {
          setCopiedQuery(data);
        });
    }
  }, [copyQueryIndex]);

  // Fetch existing query ref effect
  useEffect(() => {
    // Fetch or create the query
    if (project && queryId) {
      setQuery(project.queries.query(queryId));
    }
  }, [project, queryId, schemaId]);

  // Fetch existing query data effect
  useEffect(() => {
    let mounted = true;
    if (query) {
      const unsubQ = query.listen((data) => {
        if (mounted) {
          queryDataStatic.current = data;
          preserveClean.current = true;
          setQueryData(data);
        }
      });
      return () => {
        unsubQ();
        mounted = false;
      };
    }
  }, [query]);

  // New Query Generation effect
  useEffect(() => {
    if (
      query ||
      queryData !== undefined ||
      schemaData === undefined ||
      userCustomSchema === undefined
    ) {
      return;
    }

    setNew(true);

    // We need to generate a new query
    // Start by defining new data object
    const generatedQuery = {
      creator: userData.id ?? "",
      createTime: new Date(),
      status: "created",
      schemaId: schemaData.id,
      data: {},
      distribution: [],
    };

    // If there's a revision to the schema, add that to tracking as well
    if (schemaData.revisionId) {
      generatedQuery.schemaRevision = schemaData.revisionId;
    }

    // Begin with the defaultables
    const { defaultDateLeads, customStatusSet } = schemaData;

    if (defaultDateLeads?.due) {
      let currdt = new Date();
      generatedQuery["dueDate"] = currdt.setDate(
        currdt.getDate() + defaultDateLeads?.due
      );
      generatedQuery["overdueNotified"] = 0;
    }

    if (customStatusSet && schemaData.type === "custom") {
      generatedQuery.status = Object.values(customStatusSet).find(
        (s) => s.statusType === "created"
      )?.status;
      if (generatedQuery.status === undefined) {
        log_error("No created status found for custom query");
      }
    }

    // Add empty response object if needed.
    if (schemaData.type === "response") {
      generatedQuery["response"] = {
        userId: null,
        data: {},
      };
    }

    // Update status to closed at this point if decision schema
    // DEFECT: this makes no sense
    if (schemaData.type === "decision") {
      generatedQuery["schema"] = "closed";
    }

    let nn = new Date();

    if (
      schemaData.settings?.enablePersonalDefaults &&
      userCustomSchema.customDefaults
    ) {
      let joined = [];
      if (schemaData.fields) {
        joined = [...joined, ...schemaData.fields];
      }
      if (
        schemaData.settings?.enablePersonalFields &&
        userCustomSchema.customFields
      ) {
        joined = [...joined, ...userCustomSchema.customFields];
      }

      parse_on_fields(joined, (fld) => {
        // First check if there is a default we need to monitor
        if (userCustomSchema.customDefaults[fld.id]) {
          if (userCustomSchema.customDefaults[fld.id]._conditionalOn) {
            autoDefault.current = true;
            // Then let's file away this conditional in the set
            defaultFields.current.push({
              default: userCustomSchema.customDefaults[fld.id],
              id: fld.id,
              condition: userCustomSchema.customDefaults[fld.id]._conditionalOn,
            });
            // If there is a default on the conditional, we need to apply that now too
            if (userCustomSchema.customDefaults[fld.id]._default) {
              generatedQuery.data[fld.id] =
                userCustomSchema.customDefaults[fld.id]._default;
            }
          } else if (userCustomSchema.customDefaults[fld.id]._format) {
            // If there is a format, we need to apply that now too
            // first parse the function
            // then call it
            try {
              const func = new Function(
                "userData",
                userCustomSchema.customDefaults[fld.id]._format
              );
              generatedQuery.data[fld.id] = func(userData);
            } catch (e) {
              log_error(`Error parsing default format for ${fld.id}: ${e}`);
            }
          } else {
            // No conditional, that means this is a one-time default for Query Creation
            // We handle that here and now
            generatedQuery.data[fld.id] =
              userCustomSchema.customDefaults[fld.id];
          }
        }
        // This conditional on the calculation logic is heavily flawed
        if (generatedQuery.data[fld.id] == undefined) {
          if (
            fld.type === "date" &&
            userCustomSchema.customDefaults[fld.id] !== null
          ) {
            generatedQuery.data[fld.id] = nn;
          } else if (fld.type === "arbcalc") {
            // Enable autocalc if there is a calculation
            autoCalc.current = true;
            // Also add it to our calculated field set
            calculatedFields.current.push({
              ...fld,
              parseFunc: new Function("data", "row", "utils", fld.format),
            });
            // Then do the standard defaulting
            generatedQuery.data[fld.id] = 0;
          } else if (fld.type === "table" || fld.type === "statictable") {
            // We also need to check tables for calculated fields
            if (
              fld.columns &&
              fld.columns.some((col) => col.type === "arbcalc")
            ) {
              // Enable autocalc
              autoCalc.current = true;
              // Also add the table to our calculated field set
              calculatedFields.current.push({
                ...fld,
                type: "table",
                columns: fld.columns.map((col) => ({
                  ...col,
                  ...(col.type === "arbcalc"
                    ? {
                        parseFunc: new Function(
                          "data",
                          "row",
                          "utils",
                          col.format
                        ),
                      }
                    : {}),
                })),
              }); // standardize because we don't care if its static
            }
            // Now auto-define the table. This is critical as it doesn't happen elsewhere
            generatedQuery.data[fld.id] =
              fld.type === "statictable"
                ? fld.rows
                  ? fld.rows.map((row) => ({ ...row, id: generate_tempid() }))
                  : [{ id: generate_tempid() }]
                : [{ id: generate_tempid() }];
          } else {
            generatedQuery.data[fld.id] = "";
          }
        }
      });
    } else {
      parse_on_fields(schemaData.fields, (fld) => {
        // First check if there is a default we need to monitor
        if (fld.default) {
          if (fld.default._conditionalOn) {
            autoDefault.current = true;
            // Then let's file away this conditional in the set
            defaultFields.current.push({
              default: fld.default,
              id: fld.id,
              condition: fld.default._conditionalOn,
            });
            // If there is a default on the conditional, we need to apply that now too
            if (fld.default._default) {
              generatedQuery.data[fld.id] = fld._default;
            }
          } else if (fld.default._format) {
            // If there is a format, we need to apply that now too
            // first parse the function
            // then call it
            try {
              const func = new Function("userData", fld.default._format);
              generatedQuery.data[fld.id] = func(userData);
            } catch (e) {
              log_error(`Error parsing default format for ${fld.id}: ${e}`);
            }
          } else {
            // No conditional, that means this is a one-time default for Query Creation
            // We handle that here and now
            generatedQuery.data[fld.id] = fld.default;
          }
        }
        // This conditional on the calculation logic is heavily flawed
        if (generatedQuery.data[fld.id] == undefined) {
          if (fld.type === "date" && fld.default !== null) {
            generatedQuery.data[fld.id] = nn;
          } else if (fld.type === "arbcalc") {
            // Enable autocalc if there is a calculation
            autoCalc.current = true;
            // Also add it to our calculated field set
            calculatedFields.current.push({
              ...fld,
              parseFunc: new Function("data", "row", "utils", fld.format),
            });
            // Then do the standard defaulting
            generatedQuery.data[fld.id] = 0;
          } else if (fld.type === "table" || fld.type === "statictable") {
            // We also need to check tables for calculated fields
            if (
              fld.columns &&
              fld.columns.some((col) => col.type === "arbcalc")
            ) {
              // Enable autocalc
              autoCalc.current = true;
              // Also add the table to our calculated field set
              calculatedFields.current.push({
                ...fld,
                type: "table",
                columns: fld.columns.map((col) => ({
                  ...col,
                  ...(col.type === "arbcalc"
                    ? {
                        parseFunc: new Function(
                          "data",
                          "row",
                          "utils",
                          col.format
                        ),
                      }
                    : {}),
                })),
              }); // standardize because we don't care if its static
            }
            // Now auto-define the table. This is critical as it doesn't happen elsewhere
            generatedQuery.data[fld.id] =
              fld.type === "statictable"
                ? fld.rows
                  ? fld.rows.map((row) => ({ ...row, id: generate_tempid() }))
                  : [{ id: generate_tempid() }]
                : [{ id: generate_tempid() }];
          } else {
            generatedQuery.data[fld.id] = "";
          }
        }
      });
    }
    // Now finish by actually updating state
    preserveClean.current = true;
    setQueryData((prev) => ({ ...prev, ...generatedQuery }));
  }, [schemaData, query, queryData, userData, userCustomSchema]);

  // In addition to the query setup, let's see about the copy we want to do here
  useEffect(() => {
    // NOTE: This is queryDataStatic for the other query, so we don't have to worry about other events firing this un-intentionally
    if (!copiedQuery || !schemaData || !queryData || copyExecuted.current) {
      return;
    }
    // If we have one to copy, let's go ahead and do it!
    const dataToCopy = {};
    parse_on_fields(schemaData.fields, (fld) => {
      if (COPIABLE_FIELDS.includes(fld.type)) {
        dataToCopy[fld.id] = copiedQuery.data[fld.id];
      }
    });
    setQueryData((ex) => ({
      ...ex,
      data: { ...ex?.data, ...dataToCopy },
      duplicatedQueryId: copiedQuery.id,
    }));
    copyExecuted.current = true;
  }, [copiedQuery, schemaData, queryData]);

  // Parse the schema here to setup defualts and calculations
  useEffect(() => {
    if (!schemaData || !schemaData?.fields) {
      return;
    }
    parse_on_fields(schemaData.fields, (fld) => {
      // First check if there is a default we need to monitor
      if (fld.default) {
        // We don't care about default value, that's handled, elsewhere.
        // We only care about the conditional ones
        if (fld._conditionalOn) {
          autoDefault.current = true;
          // Then let's file away this conditional in the set
          defaultFields.current.push({
            default: fld.default,
            id: fld.id,
            condition: fld._conditionalOn,
          });
        }
      }
      // Now manage potential calculations
      if (fld.type === "arbcalc") {
        // Enable autocalc if there is a calculation
        autoCalc.current = true;
        // Also add it to our calculated field set
        calculatedFields.current.push({
          ...fld,
          parseFunc: new Function("data", "row", "utils", fld.format),
        });
      } else if (fld.type === "table" || fld.type === "statictable") {
        // We also need to check tables for calculated fields
        if (fld.columns && fld.columns.some((col) => col.type === "arbcalc")) {
          // Enable autocalc
          autoCalc.current = true;
          // Also add the table to our calculated field set
          calculatedFields.current.push({
            ...fld,
            type: "table",
            columns: fld.columns.map((col) => ({
              ...col,
              ...(col.type === "arbcalc"
                ? {
                    parseFunc: new Function("data", "row", "utils", col.format),
                  }
                : {}),
            })),
          }); // standardize because we don't care if its static
        }
      }
    });
  }, [schemaData]);

  useEffect(() => {
    if (!autoCalc.current) {
      return;
    }
    if (!calcNeedsUpdate.current) {
      calcNeedsUpdate.current = true; // Next time we do an update we'll need to calc
      return;
    }
    // Run calculations
    let calcs = {};
    calculatedFields.current.forEach((fld) => {
      if (fld.type === "table") {
        // Now the calculation is slightly different
        // We do have to force the calc table update even if no calcs occur
        calcs[fld.id] =
          queryData.data[fld.id] &&
          queryData.data[fld.id].map((row) => {
            let rowCalcs = {};
            fld.columns.forEach((col) => {
              if (col.type === "arbcalc") {
                // Run the calculation!
                try {
                  let res = col.parseFunc(
                    { ...queryData.data, ...calcs },
                    row,
                    utils
                  );
                  if (!isNaN(res) && res != undefined) {
                    rowCalcs[col.id] = res;
                  }
                } catch {
                  log_error(
                    "Calculated field execution error occured (table row)"
                  );
                }
              }
            });
            return { ...row, ...rowCalcs };
          }); // Because we know this has rows
      } else {
        // Parse the calculation
        try {
          let res = fld.parseFunc(
            { ...queryData.data, ...calcs },
            undefined,
            utils
          );
          if (!isNaN(res) && res != undefined) {
            calcs[fld.id] = res;
          }
        } catch {
          log_error("Calculated field execution error occured");
        }
      }
    });
    // Now update state
    calcNeedsUpdate.current = false;
    setQueryData((ex) => ({ ...ex, data: { ...ex.data, ...calcs } }));
  }, [queryData]);

  // Now for defaulting
  useEffect(() => {
    if (!autoDefault.current) {
      return;
    }
    if (!defaultNeedsUpdate.current) {
      defaultNeedsUpdate.current = true; // Next time we do an update we'll need to calc
      return;
    }
    // Run calculations
    let defaults = {};
    defaultFields.current.forEach((fld) => {
      // First we'll retrieve the data at the conditon
      let condData = queryData.data[fld.condition];
      if (condData === undefined) {
        return;
      }
      // Also check if the conditional data has changed
      if (defaultFieldValues.current[fld.condition] === condData) {
        // Skip if the data hasn't changed
        return;
      }
      // Now we'll map the condition
      let defaultData = fld.default[condData];
      if (defaultData === undefined) {
        defaults[fld.id] =
          fld.default._default !== undefined ? fld.default._default : "";
      } else {
        defaults[fld.id] = defaultData;
      }
      // Check if we've decided to set this defualt
      if (defaults[fld.id] !== undefined) {
        // Track the value of the default (condData)
        defaultFieldValues.current[fld.condition] = condData;
      }
    });
    // Now update state
    defaultNeedsUpdate.current = false;
    setQueryData((ex) => ({ ...ex, data: { ...ex.data, ...defaults } }));
  }, [queryData]);

  const clean = useCallback(() => {
    setDirty(false);
  }, []);

  // We also have the potential for some functionality that defaults data after changes
  useEffect(() => {
    if (approversChangedManually.current) {
      // DEFECT: This won't behave properly on existing queries
      // After manual change, we won't override with defaulting
      return;
    }
    if (!dirty && !isNew) {
      // We don't want to default if we're not dirty and not new (new can do first time defaulting!)
      return;
    }
    // Approvers
    if (
      queryData &&
      schemaData?.defaultApprovers?.conditionalOn !== undefined
    ) {
      // DEFECT: This is kind of a hack, should be re-done
      // NOTE: approvals conditional is now supporting multiple conditions, which looks as follows:
      /**
       * {
       *  "conditionalOn": ["field1", "field2", ...],
       *  "field1&&field2&&...": defaultValue
       * }
       * Remark: fieldx === * is a wildcard for any value of the field. First match of dict will be used (order matters)
       */
      // Fetch the conditional values of the Query
      let conditionalValues = [];
      (Array.isArray(schemaData.defaultApprovers.conditionalOn)
        ? schemaData.defaultApprovers.conditionalOn
        : [schemaData.defaultApprovers.conditionalOn]
      ).forEach((cond) => {
        let dt = queryData.data;
        // Now pull inward until we get to the data
        cond.split(".").forEach((val) => {
          if (val !== "data") {
            dt = dt[val];
          }
        });
        // Then we'll push this as a conditionalValue
        conditionalValues.push(dt);
      });
      // If the approversCondition is undefined while dt is '', then they're both undefiend in our eyes
      // Check if the conditions have changed
      if (
        (
          approverCondition.current ?? conditionalValues.map((v) => undefined)
        ).some((v, i) => v !== conditionalValues[i]) &&
        !(
          conditionalValues.every((v) => v === "" || v === undefined) &&
          approverCondition.current === undefined
        )
      ) {
        // Conditional values changed, update them and look at setting approvers
        approverCondition.current = conditionalValues;
        calcNeedsUpdate.current = false;
        defaultNeedsUpdate.current = false;
        // Now determine if we have an approval default for this condition
        let approverDefaultSelection = undefined;
        let specificSelection = false;
        Object.keys(schemaData.defaultApprovers).forEach((key) => {
          if (key === "conditionalOn") {
            return;
          } // Skip this (not a key with defaults)
          if (approverDefaultSelection !== undefined && specificSelection) {
            return;
          } // We already have a selection
          // Now we'll check if the condition matches
          let conditions = key.split("&&");
          if (conditions.length !== conditionalValues.length) {
            return;
          }
          if (
            !conditions.every((c, i) => c === conditionalValues[i] || c === "*")
          ) {
            return;
          }
          // If we've made it this far, then we have a match
          approverDefaultSelection = schemaData.defaultApprovers[key];
          // Check if the match is specific
          specificSelection = conditions.every(
            (c, i) => c === conditionalValues[i]
          );
        });
        // Before we do a setter here, let's check to make sure that we don't need to migrate
        // REMOVE: Migration
        // Check if schema doesn't have fields of new approver default structure
        if (
          approverDefaultSelection &&
          (!Object.hasOwn(approverDefaultSelection?.[0], "stageLabel") ||
            !Object.hasOwn(approverDefaultSelection?.[0], "targetStatus"))
        ) {
          // Update the schema document and return
          log_warning(
            "The approvers default schema is out of date and has been migrated to a newer format"
          );
          // Update the schema by mappings each approver in the array to an object {main: approver}
          let newDefaultApprovers = schemaData.defaultApprovers;
          // Now we'll iterate over the keys (knowing each one except "conditionalOn" is an approvers mapping object)
          Object.keys(newDefaultApprovers).forEach((key) => {
            if (key === "conditionalOn") {
              return;
            }
            if (key === "users") {
              delete newDefaultApprovers[key]; // old uneeded data, delete this key value pair
              return;
            }
            // Now we'll iterate over the approvers array
            newDefaultApprovers[key] = newDefaultApprovers[key].map((row) => ({
              stageLabel: row?.stage ?? "Approval",
              targetStatus: "open",
              approvers: row.approvers.map((approver) => ({
                main: approver,
              })),
            }));
          });
          // Then apply that to the schema in db
          project.schemas.schema(schemaData.id).update({
            defaultApprovers: newDefaultApprovers,
          });
          // And we'll set the tracked approverCondition values to undefined to trigger rerender with new schema!
          approverCondition.current = undefined;
          return;
        }
        // Finally do the setting!
        approversChangedAutomated.current = true;
        setQueryData((ex) => ({
          ...ex,
          approvalsRequired: approverDefaultSelection,
        }));
      }
    }
    // Distribution
    if (
      schemaData &&
      queryData &&
      schemaData.defaultDistribution &&
      schemaData.defaultDistribution.conditionalOn
    ) {
      let dt = queryData;
      schemaData.defaultDistribution.conditionalOn
        .split(".")
        .forEach((val) => (dt = dt[val]));
      // If the approversCondition is undefined while dt is '', then they're both undefiend in our eyes
      if (
        dt !== distributionCondition.current &&
        !(dt === "" && distributionCondition.current === undefined)
      ) {
        distributionCondition.current = dt;
        calcNeedsUpdate.current = false;
        defaultNeedsUpdate.current = false;
        if (schemaData.defaultDistribution[dt]) {
          // Here it is up to the default setup to make sure this is inline with the
          // table format expected for this field
          setQueryData((ex) => ({
            ...ex,
            distribution: schemaData.defaultDistribution[dt],
          }));
        } else {
          // Here we'll need to apply a default in keeping with the schema defaulting of this approvers table
          setQueryData((ex) => ({ ...ex, distribution: [] }));
        }
      }
    }
  }, [queryData, schemaData, dirty, isNew]);

  // And the effect that makes it dirty in the first place
  const previousQueryData = useRef(undefined);
  // const currentQueryData = useRef(undefined);
  useEffect(() => {
    // Protector for when data is fresh during updates
    if (preserveClean.current) {
      // Once query data has been fetched, cleanliness preservation is no longer in effect
      if (queryData && queryData.data) preserveClean.current = false;
      previousQueryData.current = queryData;
      return;
    }
    // If previousQueryData is undefined, define it!
    if (previousQueryData.current === undefined) {
      previousQueryData.current = queryData;
      approversChangedAutomated.current = false; // Update this so we don't catch a manual change as an automated one
      return;
    }
    // If we're already dirty, it's time to stop
    if (dirty) {
      // Then just update the previousQueryData
      previousQueryData.current = queryData;
      return;
    }
    // Then check if any relevant fields have changed
    const relevantChangeOccured = () => {
      // Set protection flags (make sure this happens on any subsequent render as some will be merged)
      const thisChangeIsAutomated = approversChangedAutomated.current;
      approversChangedAutomated.current = false;
      // * Data changed
      if (!deep_comparison(previousQueryData.current?.data, queryData?.data)) {
        return true;
      }
      // * Approvals changed
      // Here we compare the approvalsRequired array objects (not the array itself which is free to change)
      if (previousQueryData.current?.approvalsRequired?.length !== 0) {
        if (
          previousQueryData.current?.approvalsRequired?.some((row, i) => {
            // Not sure about including these in dirty...
            // if (row?.stageLabel !== queryData?.approvalsRequired?.[i]?.stageLabel) return true;
            // if (row?.targetStatus !== queryData?.approvalsRequired?.[i]?.targetStatus) return true;
            const previousApprovals =
              row?.approvers?.filter((ap) => ap?.main && ap?.main.length > 0) ??
              [];
            const currentApprovals =
              queryData?.approvalsRequired?.[i]?.approvers?.filter(
                (ap) => ap?.main && ap?.main.length > 0
              ) ?? [];
            // Now if this length is not equal then we know something has changed
            if (previousApprovals.length !== currentApprovals.length) {
              return true;
            }
            // If the lengths are the same then we'll check the approvers are the same
            return !previousApprovals.every(
              (ap, i) => ap.main === currentApprovals[i].main
            );
          })
        ) {
          if (!thisChangeIsAutomated) {
            approversChangedManually.current = true;
          }
          return true;
        }
      }
      // * Distribution changed
      if (previousQueryData.current?.distribution !== queryData?.distribution) {
        return true;
      }
      // * Form invitees changed
      // TODO: Allow dirty on form invitees changed
      // * Evaluations changed
      // TODO: Allow dirty on evaluations changed
      // * Assignee changed
      if (previousQueryData.current?.assignee !== queryData?.assignee) {
        return true;
      }
      // Return false, nothing has changed
      return false;
    };
    if (relevantChangeOccured()) {
      setDirty(true);
    }
    // Now we'll always overwrite the previousQueryData after a dirty check
    previousQueryData.current = queryData;
  }, [queryData, dirty]);

  return [
    query,
    queryData,
    setQueryData,
    dirty,
    clean,
    queryDataStatic.current,
  ];
};

const getByDotNotation = (t, path) =>
  path.split(".").reduce((r, k) => r?.[k], t);

export const useQueryStatus = (queryData, schemaData, isNew = false) => {
  //lets memoize the duedate value separately, so that QueryStatus does not have to recompute on every queryData change
  const dueDate = useMemo(() => {
    if (queryData && schemaData) {
      const enableDueDate = schemaData?.settings?.enableDueDate ?? true;
      if (enableDueDate === true) {
        return queryData.dueDate;
      } else if (typeof enableDueDate === "string") {
        return getByDotNotation(queryData, enableDueDate);
      } else {
        return undefined;
      }
    }
  }, [queryData, schemaData?.settings?.enableDueDate]);

  const status = useMemo(() => {
    if (queryData && schemaData) {
      return new QueryStatus(
        { ...queryData, status: isNew ? "new" : queryData?.status },
        schemaData,
        dueDate
      );
    }
  }, [queryData?.status, queryData?.closeTime, schemaData, isNew, dueDate]);

  return status;
};

// export const useApprovals = (query) => {
//   const [approvals, setApprovals] = useState(undefined);

//   useEffect(() => {
//     let mounted = true;
//     if (query) {
//       const unsub = query.approvals.listen((data) => {
//         if (mounted) setApprovals(data);
//       });
//       return () => {
//         unsub();
//         mounted = false;
//       };
//     }
//   }, [query]);

//   return approvals;
// };

export const useResponse = (query) => {
  const [response, setResponse] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    if (query) {
      const unsub = query.responses.listen((data) => {
        if (mounted) {
          // Now we also apply a special abrastraction because there is only 1 response
          if (data.length > 0) {
            setResponse(data[0]);
          } else {
            setResponse({ id: undefined });
          }
        }
      });
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [query]);

  return [response, setResponse];
};

export const useComments = (query) => {
  const [comments, setComments] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    if (query) {
      const unsub = query.comments.listen((data) => {
        if (mounted) setComments(data);
      });
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [query]);

  return comments;
};

export const useTasks = (query) => {
  const [tasks, setTasks] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    if (query) {
      const unsub = query.tasks.listen((data) => {
        if (mounted)
          setTasks(
            data.sort(function (a, b) {
              return b.createdDate - a.createdDate;
            })
          );
      });
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [query]);

  return tasks;
};

export const useTaskFeed = (query, taskId) => {
  const [feed, setFeed] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    if (query) {
      const unsub = query.tasks.task(taskId).feed.listen((data) => {
        if (mounted) setFeed(data);
      });
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [query, taskId]);

  return feed;
};

// FETCHING
// This is where we want the data on demand, so we include an argument for a call-back
// so that we can call our function once we have the right data!

let fetchQueryCallback = (id) => {};
export const useFetchQuery = (project) => {
  const [queryId, setQueryId] = useState(undefined);
  const [lastQueryData, setLastQueryData] = useState(undefined);

  const makeQueryFetch = useCallback(
    (queryId, callback) => {
      fetchQueryCallback = callback;
      setQueryId(queryId);
    },
    [setQueryId]
  );

  useEffect(() => {
    if (project && queryId) {
      project.queries.query(queryId).listen((data) => {
        setLastQueryData(data);
        fetchQueryCallback(data);
      });
    }
  }, [project, queryId]);

  return [lastQueryData, makeQueryFetch];
};
