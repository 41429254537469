import React from "react";

import { QUERYVIEWS } from "../../../common/query";

// Views
import ActionManagerView from "./actions";
import PrintPreview from "./print/preview";
import FileRevisionsView from "./revisions";

// Admin Views
import QueryDefaultsEditor from "./admin/defaults/tab";
import QueryFieldsEditor from "./admin/fields";
import QueryDynamicIDEditor from "./admin/dynamic_id_editor";
import QueryFieldsValidationEditor from "./admin/validations/fields_validation_editor";
import QueryForm from "./forms";
import { useSearchParams } from "react-router-dom";
import Defaults from "./admin/defaults/defaults";

/**
 * This Query component determines all of the views that are commonly available.
 * It's main role is to standardize views that are available based on factors
 * divorce from the new/existing status of the Query. This imposed a consistent
 * presence of view components :)
 */
export default React.memo(
  ({
    queryData,
    setQueryData,
    schemaData,
    customStatus,
    project,
    query,
    readonly,
    isNew,
    setStatusMessage,
    newQueryRef,
    // From the query manager
  }) => {
    // Pull query route param
    const [search] = useSearchParams();
    const queryRoute = search.get("qr");

    switch (queryRoute) {
      // No need to render common if queryRoute is QUERYVIEWS.QR_CODE
      case QUERYVIEWS.QR_CODE:
        return null;
      case QUERYVIEWS.ACTIONS:
        return (
          <ActionManagerView
            queryData={queryData}
            setQueryData={setQueryData}
            query={query}
            readonly={readonly}
            schemaData={schemaData}
            customStatus={customStatus}
            isNew={isNew}
            setStatusMessage={setStatusMessage}
            newQueryRef={newQueryRef}
          />
        );
      case QUERYVIEWS.FIELDS:
        return (
          <QueryFieldsEditor
            project={project}
            schemaId={queryData.schemaId}
            setStatusMessage={setStatusMessage}
            custom
          />
        );
      // Then we have some viewstates to manage that are admin related!
      case QUERYVIEWS.ADMIN_DEFAULTS:
        return (
          <QueryDefaultsEditor
            project={project}
            schemaId={queryData.schemaId}
          />
        );
      case QUERYVIEWS.DEFAULTS:
        return (
          <Defaults project={project} schemaId={queryData.schemaId} custom />
        );
      case QUERYVIEWS.ADMIN_FIELDS:
        return (
          <QueryFieldsEditor
            project={project}
            schemaId={queryData.schemaId}
            setStatusMessage={setStatusMessage}
          />
        );
      case QUERYVIEWS.ADMIN_VALIDATION_CONTROLS:
        return (
          <QueryFieldsValidationEditor
            project={project}
            schemaId={queryData.schemaId}
            setStatusMessage={setStatusMessage}
          />
        );
      case QUERYVIEWS.ADMIN_DYNAMIC_ID:
        return (
          <QueryDynamicIDEditor
            project={project}
            schemaId={queryData.schemaId}
            queryData={queryData}
            setStatusMessage={setStatusMessage}
          />
        );
      case QUERYVIEWS.EXTERNAL_FORM:
        return (
          <QueryForm
            queryData={queryData}
            setQueryData={setQueryData}
            query={query}
            schemaData={schemaData}
            customStatus={customStatus}
            isNew={isNew} // Won't be necessary later but we need it for now
          />
        );
      case QUERYVIEWS.PRINT:
        return (
          <PrintPreview
            query={query}
            queryData={queryData}
            schemaData={schemaData}
            projectId={project.id}
          />
        );
      case QUERYVIEWS.REVISIONS:
        return <FileRevisionsView queryData={queryData} />;
      default:
        return null;
    }
  }
);
