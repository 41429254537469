import React, { useState, useMemo, useContext } from "react";
import styled from "@emotion/styled";
import { isMobile } from "react-device-detect";

import TableBody from "./body";
import TableHeader from "./head";
import { IconButton, Menu, MenuItem, MenuList, Tooltip } from "@mui/material";
import { Add, InsertDriveFile } from "@mui/icons-material";
import { generate_tempid } from "../../../tools";
import { log_error } from "../../../tools/logger";
import { downloadTableTemplateWorkbook, uploadTableTemplateRows } from "./xlsx";
import { TableFooter } from "./foot";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeContext } from "styled-components";
import { materialTheme } from "../../../themes/mui_theme";
import { panelTheme } from "../../../themes/mui_theme_panels";
import { MuiFormHelperText } from "../../project/query/fields/queryfields";

export const ChangeContext = React.createContext(undefined);
export const GlobalDisablerContext = React.createContext(false);
export const QueryDataContext = React.createContext(undefined);
export const SchemaEditOnlyContext = React.createContext(false);
export const TableEditorContext = React.createContext(undefined);

export default ({
  data,
  onChange,
  setChange, // Support for that cool react-style callback setter of change
  setSchemaChange, // For changing the advanced table schema only
  schema,
  label,
  disabled,
  error,
  tableHeaderEditor,
  setTableHeaderEditor,
  tableColumnEditor,
  setTableColumnEditor,

  editChange, // OnChange but for editing instead of reading data. Disables data reading and switches table to edit mode
  position = "left", // center, left, right
  // Optional data that can be used to make render decisions
  queryData,
  schemaEditOnly,
}) => {
  // State
  const [showExcelMenu, setShowExcelMenu] = useState(undefined);

  const theme = useContext(ThemeContext);

  // Before we render the table, check that the schema is valid and adequate
  if (schema === undefined || schema.body?.columns === undefined) {
    log_error(
      "Advanced Table could not render because schema was not provided"
    );
    return null;
  }

  // Before render, check if mobile and render mobile alternative
  if (isMobile) {
    // we pass it every bit of info available to the table component, and it just presents it differently
    return (
      <AdvancedMobileTable
        data={data}
        onChange={onChange}
        setChange={setChange}
        schema={schema}
        label={label}
        disabled={disabled}
        editChange
        position={position}
        queryData={queryData}
        showExcelMenu={showExcelMenu}
        setShowExcelMenu={setShowExcelMenu}
      />
    );
  }

  // Define some helper functions
  const addTableRow = () => {
    setChange((ex) => ({ ...ex, body: [...(ex?.body || [{}]), {}] }));
  };

  // Evaluate need for a table footer
  const tableNeedsFooter = schema?.body?.columns?.some(
    (col) => col?.footerCalculation !== undefined
  );

  return (
    <TableContainer
      position={position}
      fill={schema.options?.fill}
      scroll={schema.options?.scroll}
    >
      <ThemeProvider theme={panelTheme(materialTheme(theme))}>
        <ChangeContext.Provider value={{ setChange, setSchemaChange }}>
          <GlobalDisablerContext.Provider value={!!disabled}>
            <QueryDataContext.Provider value={queryData}>
              <SchemaEditOnlyContext.Provider value={schemaEditOnly}>
                <TableEditorContext.Provider
                  value={{
                    tableHeaderEditor,
                    setTableHeaderEditor,
                    tableColumnEditor,
                    setTableColumnEditor,
                  }}
                >
                  <TableWrapper>
                    {/* Menu setup */}
                    <Menu
                      open={!!showExcelMenu}
                      onClose={() => setShowExcelMenu(undefined)}
                      anchorEl={showExcelMenu}
                      transformOrigin={{
                        vertical: -44,
                        horizontal: "right",
                      }}
                    >
                      <MenuList dense style={{ padding: 0 }}>
                        <MenuItem
                          onClick={() =>
                            downloadTableTemplateWorkbook(
                              schema?.body?.columns,
                              label
                            )
                          }
                        >
                          Download Table Template
                        </MenuItem>
                        <MenuItem style={{ cursor: "pointer" }}>
                          <label
                            htmlFor="uploadTemplate"
                            style={{
                              width: "100%",
                              height: "100%",
                              cursor: "pointer",
                            }}
                          >
                            Upload Table Rows
                          </label>
                          {/* hidden file upload input */}
                          <input
                            type="file"
                            id="uploadTemplate"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              uploadTableTemplateRows(
                                e,
                                schema?.body?.columns,
                                setChange
                              )
                            }
                          />
                        </MenuItem>
                      </MenuList>
                    </Menu>
                    {/* Now the actual view */}
                    <TableInteractables>
                      {/* The left side is where the table title goes */}
                      <TableTitle>
                        {label}
                        {error && error.length > 0 && (
                          <MuiFormHelperText error={error}>
                            {error}
                          </MuiFormHelperText>
                        )}
                      </TableTitle>
                      {/* And then we put actual interactables on the right! */}
                      <TableInteractablesButtons>
                        {!schemaEditOnly && (
                          <Tooltip title="Add New Row" enterDelay={1000}>
                            <TableInteractablesMicroButton
                              onClick={addTableRow}
                            >
                              <Add style={MicroButtonStyle} />
                            </TableInteractablesMicroButton>
                          </Tooltip>
                        )}
                        {schemaEditOnly && (
                          <AddMenuButton
                            schema={schema}
                            setChange={setSchemaChange}
                          />
                        )}
                        {(schema.options?.allowImportExport ?? true) && (
                          <Tooltip
                            title="Download/Upload with Excel"
                            enterDelay={1000}
                          >
                            <TableInteractablesMicroButton
                              onClick={(e) => setShowExcelMenu(e.target)}
                            >
                              <InsertDriveFile style={MicroButtonStyle} />
                            </TableInteractablesMicroButton>
                          </Tooltip>
                        )}
                      </TableInteractablesButtons>
                    </TableInteractables>
                    <TableFull>
                      <TableHeader
                        columns={schema.body.columns}
                        showSimpleHeader={
                          schema.options?.showBasicHeader ?? true
                        }
                        headerScheme={schema.header}
                        headerData={data?.header ?? {}}
                        disabled={disabled}
                      />
                      <TableBody
                        data={data?.body ?? [{}]}
                        columns={schema.body.columns}
                        settings={schema.settings}
                        disabled={disabled}
                      />
                      {tableNeedsFooter && (
                        <TableFooter
                          columns={schema.body.columns}
                          data={data?.body ?? [{}]}
                        />
                      )}
                    </TableFull>
                  </TableWrapper>
                </TableEditorContext.Provider>
              </SchemaEditOnlyContext.Provider>
            </QueryDataContext.Provider>
          </GlobalDisablerContext.Provider>
        </ChangeContext.Provider>
      </ThemeProvider>
    </TableContainer>
  );
};

/**
 * MOBILE SUPPORT
 */

const AdvancedMobileTable = ({
  data,
  onChange,
  setChange, // Support for that cool react-style callback setter of change
  schema,
  label,
  disabled,

  editChange, // OnChange but for editing instead of reading data. Disables data reading and switches table to edit mode
  position = "left", // center, left, right
  // Optional data that can be used to make render decisions
  queryData,
  // From the main component
  showExcelMenu,
  setShowExcelMenu,
}) => {
  // Define some helper functions
  const addTableRow = () => {
    setChange((ex) => ({ ...ex, body: [...(ex.body || [{}]), {}] }));
  };

  const tableNeedsFooter = false;

  // Calculate the width of the table and force it to overflow for scrolling
  const tableWidth = useMemo(() => {
    // First figure out how many body cols exist
    const bodyCols = schema.body.columns.length;
    // Then figure out how many header cols exist
    const headerCols = schema.header?.columns?.length ?? 0;
    // Footer won't exceed header and body, so take max of those two
    return Math.max(bodyCols, headerCols) * 180;
  }, [schema]);

  return (
    <TableContainer
      position={position}
      fill={schema.options?.fill}
      scroll={schema?.options?.scroll}
    >
      <ChangeContext.Provider value={{ setChange }}>
        <GlobalDisablerContext.Provider value={!!disabled}>
          <QueryDataContext.Provider value={queryData}>
            <TableWrapper
              style={{
                minWidth: `${tableWidth}px`,
                overflowX: "scroll",
                marginRight: "15px",
              }}
            >
              {/* Menu setup */}
              <Menu
                open={!!showExcelMenu}
                onClose={() => setShowExcelMenu(undefined)}
                anchorEl={showExcelMenu}
                transformOrigin={{
                  vertical: -44,
                  horizontal: "right",
                }}
              >
                <MenuList dense style={{ padding: 0 }}>
                  <MenuItem
                    onClick={() =>
                      downloadTableTemplateWorkbook(
                        schema?.body?.columns,
                        label
                      )
                    }
                  >
                    Download Table Template
                  </MenuItem>
                  <MenuItem style={{ cursor: "pointer" }}>
                    <label
                      htmlFor="uploadTemplate"
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                    >
                      Upload Table Rows
                    </label>
                    {/* hidden file upload input */}
                    <input
                      type="file"
                      id="uploadTemplate"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        uploadTableTemplateRows(
                          e,
                          schema?.body?.columns,
                          setChange
                        )
                      }
                    />
                  </MenuItem>
                </MenuList>
              </Menu>
              {/* Now the actual view */}
              <TableInteractables style={{ justifyContent: "flex-start" }}>
                {/* The left side is where the table title goes */}
                <div>{label}</div>
                {/* And then we put actual interactables on the right! */}
                <TableInteractablesButtons>
                  <Tooltip title="Add New Row" enterDelay={1000}>
                    <TableInteractablesMicroButton onClick={addTableRow}>
                      <Add style={MicroButtonStyle} />
                    </TableInteractablesMicroButton>
                  </Tooltip>
                  {(schema.options?.allowImportExport ?? true) && (
                    <Tooltip
                      title="Download/Upload with Excel"
                      enterDelay={1000}
                    >
                      <TableInteractablesMicroButton
                        onClick={(e) => setShowExcelMenu(e.target)}
                      >
                        <InsertDriveFile style={MicroButtonStyle} />
                      </TableInteractablesMicroButton>
                    </Tooltip>
                  )}
                </TableInteractablesButtons>
              </TableInteractables>
              <TableFull>
                <TableHeader
                  columns={schema.body.columns}
                  showSimpleHeader={schema.options?.showBasicHeader ?? true}
                  headerScheme={schema.header}
                  headerData={data?.header ?? {}}
                  disabled={disabled}
                />
                <TableBody
                  data={data?.body ?? [{}]}
                  columns={schema.body.columns}
                  settings={schema.settings}
                  disabled={disabled}
                  noHover
                />
                {tableNeedsFooter && <TableFooter />}
              </TableFull>
            </TableWrapper>
          </QueryDataContext.Provider>
        </GlobalDisablerContext.Provider>
      </ChangeContext.Provider>
    </TableContainer>
  );
};

const AddMenuButton = ({ schema, setChange }) => {
  const [showPrompt, setShowPrompt] = useState(undefined);

  const addHeader = () => {
    const initialHeader = {};
    schema.body.columns.forEach((col, i) => {
      initialHeader[i] = { type: "text" };
    });
    setChange((ex) => ({
      ...(ex?.schema || {}),
      header: [...(ex?.schema?.header || []), initialHeader],
    }));
    setShowPrompt(false);
  };

  const addColumn = () => {
    setChange((ex) => ({
      ...(ex?.schema || {}),
      body: {
        ...(ex?.schema?.body || []),
        columns: [
          ...(ex?.schema?.body?.columns || []),
          {
            id: generate_tempid().substring(0, 10),
            title: "NEW COLUMN",
            type: "text",
          },
        ],
      },
    }));
    setShowPrompt(false);
  };

  return (
    <>
      <Menu
        open={!!showPrompt}
        onClose={() => setShowPrompt(undefined)}
        anchorEl={showPrompt}
        transformOrigin={{
          vertical: 0,
          horizontal: "center",
        }}
      >
        <MenuList dense style={{ padding: 0 }}>
          <MenuItem onClick={addHeader}>Add Header</MenuItem>
          <MenuItem onClick={addColumn}>Add Column</MenuItem>
        </MenuList>
      </Menu>
      <Tooltip title="Add Table Elements">
        <TableInteractablesMicroButton
          onClick={(e) => setShowPrompt(e.currentTarget)}
          style={{ padding: 0 }}
        >
          <Add style={MicroButtonStyle} />
        </TableInteractablesMicroButton>
      </Tooltip>
    </>
  );
};

/**
 * STYLES
 */

const TableContainer = styled.div`
  min-width: ${(props) =>
    props.scroll ? "unset" : props.fill ? "calc(100% - 16px)" : "fit-content"};
  max-width: 100%;
  margin: 8px;
  margin-right: ${isMobile ? "25px" : "8px"};
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.position === "center"
      ? "center"
      : props.position === "right"
      ? "flex-end"
      : "flex-start"};

  overflow-x: ${isMobile ? "scroll" : "auto"};
`;

const TableWrapper = styled.div`
  display: flex;
  min-width: 260px;
  margin: 8px;
  flex-direction: column;
  overflow-y: hidden;
  color: ${(props) => props.theme.palette.grey[900]};
`;

const TableFull = styled.table`
  border-collapse: collapse;
  min-width: 280px;

  // TODO: Apply some border-color darkening on hover?
`;

export const TableCellInteractable = styled.td`
  width: 20px;
`;

const TableInteractables = styled.div`
  width: calc(100% - 20px); // For the modify prompter we remove the margin
  height: 26px;
  margin-left: 20px; // For the modify prompter

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TableInteractablesButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableInteractablesMicroButton = styled(IconButton)`
  padding: 0 !important;
  align-items: flex-start !important;
`;

const MicroButtonStyle = { height: "20px", width: "20px" };

const TableTitle = styled.div`
  color: ${(props) => props.theme.palette.grey[900]};
  display: flex;
  justify-content: center;
`;
