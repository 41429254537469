import React, { useContext } from "react";
import styled from "styled-components";

import { QueryFields } from "../../fields";
import { TabPanelContainer } from "..";

import { useResponse } from "../../../../../hooks/queries";
import { Button2 } from "../../../../ui/buttons";
import { ProjectUsersContext } from "../../..";

const ViewResponses = ({
  query,
  queryData,
  userData,
  responseSchema,
  // response,
  // setResponse
}) => {
  const users = useContext(ProjectUsersContext);
  const [response, setResponse] = useResponse(query);

  const onFieldChange = (fieldId) => (value) =>
    setResponse((ex) => ({
      ...ex,
      userId: ex?.userId ?? userData.id,
      createTime: ex?.createTime ?? new Date(),
      data: { ...ex?.data, [fieldId]: value },
    }));

  if (!response) {
    return null;
  }

  const isAssignee = response.assignedTo === userData.id;

  const responderName = users?.find((u) => u.id === response.userId)?.name;

  return (
    <TabPanelContainer style={{ flexDirection: "column" }}>
      {isAssignee && queryData.status === "open" && (
        <ResponseHeader>
          <div>
            {`${userData.name?.first} ${userData.name?.last}`}, this Query has
            been assigned to you and has been submitted in preparation for your
            response. <br />
            {queryData.dueDate
              ? `Please submit your response below by ${"the due date"} or the Query will be marked as overdue.`
              : ""}
          </div>
          <Button2
            style={{ marginRight: 10 }}
            label="Save Response"
            onClick={() =>
              query.responses.response(response.id).update(response)
            }
          />
        </ResponseHeader>
      )}
      {!isAssignee && queryData.status === "open" && (
        <div style={{ marginBottom: 8 }}>
          The Assignee has yet to submit their response. Check back later or
          remind them to submit their response in order to view it.
        </div>
      )}
      {!isAssignee && queryData.status !== "open" && (
        <div style={{ marginBottom: 8 }}>
          This Response has been provided by {responderName?.first}{" "}
          {responderName?.last}
        </div>
      )}
      {(isAssignee || queryData.status !== "open") && (
        <QueryFields
          fieldSet={responseSchema ?? RESPONSE_SCHEMA_DEFUALT}
          data={response?.data ?? {}}
          onFieldChange={onFieldChange}
          status={
            queryData.assignedUser === userData.id &&
            queryData.status === "open"
              ? "responding"
              : "readonly"
          }
          noHeaders
        />
      )}
    </TabPanelContainer>
  );
};

export default ViewResponses;

export const RESPONSE_SCHEMA_DEFUALT = [
  {
    name: "Response",
    id: "response",
    type: "textarea",
    editableWhile: ["responding"],
  },
  {
    name: "Attachments",
    id: "attach",
    type: "files",
    editableWhile: ["responding"],
  },
];

const ResponseHeader = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
