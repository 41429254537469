import React, { useState, useEffect, useContext } from "react";

import Query, { QueryContainer } from "./query";
import { QueryProgressBar } from "./progress";
import { QueryBanner } from "../../ui/heading";
import CommentSidebar from "./comments";

import QueryWorkflow from "./workflow";
import ExistingQueryContent from "./queryc_existing";
import CommonQueryContent from "./queryc_common";

import { parse_db_timestamp, is_overdue } from "../../../tools";
import { get_query_due_date } from "../../../tools/forms";

import {
  // useQuery,
  useQueries,
  useQueryStatus,
  useQueryUnified,
  useResponse,
} from "../../../hooks/queries";
import {
  useConcurrents,
  useCurrentUsers,
  useProject,
  useProjectLogo,
  useSchema,
} from "../../../hooks/projects";
import { QueryContextProvider } from "./provider";

import { UserContext, UserDataContext } from "../../../App";
import { useUserBuiltPermissions } from "../../../hooks/users";
import { format } from "date-fns";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

export default ({ lookupIndex, defaultFocus }) => {
  // Get IDs for Query
  // const { formViewId, messenger } = useSearchParams();
  const [projectId, queryId] = lookupIndex.split("-"); // Of the form PROJECT-QUERY

  // Get common data
  const userData = useContext(UserDataContext);
  const user = useContext(UserContext);

  // Now get the Project data
  const [project, projectData] = useProject(projectId);
  const queries = useQueries(project, projectData?.settings?.isAnonymized); // This may cause problems or slowdowns on large projects? May need a limiter
  const concurrent = useConcurrents(project, queryId, userData.id);
  const projectUsers = useCurrentUsers(project);

  // Now get the Query data
  const [query, queryData, setQueryData, dirty, clean, queryDataStatic] =
    useQueryUnified({ queryId: queryId }, {}, project);
  const [schema, schemaData] = useSchema(
    queryData && queryData.schemaId,
    project,
    queryData && queryData.schemaRevision?.id
  );
  const [response, setResponse] = useResponse(query);
  const queryStatus = useQueryStatus(queryData, schemaData);

  // Track Query errors
  const [queryValidationErrors, setQueryValidationErrors] = useState({});
  const [showQueryErrors, setShowQueryErrors] = useState(false);

  // Fetch Query creator for header
  const [queryCreator, setQueryCreator] = useState(undefined);
  useEffect(() => {
    if (queryDataStatic && queryDataStatic.creator) {
      const creator = projectUsers.find(
        (user) => user.id === queryDataStatic.creator
      );
      setQueryCreator(creator);
    }
  }, [queryDataStatic, projectUsers]);

  // Also get user permissions for the schema
  const userPermissions = useUserBuiltPermissions(
    user,
    schemaData,
    projectUsers
  );

  // Now get view management data
  const [viewFormPackage, setViewFormPackage] = useState(undefined);
  const [readonly, setReadonly] = useState(false); // State to determine if readonly should be on for query
  const [adminEdit, setAdminEdit] = useState(false); // State to determine if the query is in admin edit mode

  // States for user feedback and prettiness
  const [statusMessage, setStatusMessage] = useState("");
  const projectLogo = useProjectLogo(projectData);

  // State for comment drawer open
  const [commentsOpen, setCommentsOpen] = useState(false);

  // Check readonly
  useEffect(() => {
    // if the query is closed, there is no need for the read only banner
    if (!(queryData && queryData.closeTime)) {
      setReadonly(
        concurrent !== undefined &&
          concurrent !== null &&
          concurrent !== userData.id
      );
    }
  }, [concurrent, userData, queryData]);

  // TODO: I think this function is inefficient
  function saveQuery() {
    if (
      userData.id != queryDataStatic.creator &&
      queryDataStatic.status == "created"
    ) {
      setStatusMessage(
        "This Query is in its draft state and you are not the creator"
      );
      return;
    }

    setShowQueryErrors(true);
    if (Object.values(queryValidationErrors).some((q) => q !== false)) {
      setStatusMessage("Some Query data is currently invalid");
      return;
    }

    // check if the current status's requirements are still met
    // Note: we check requirementSet with the previous status because
    //       requirementSet checks whether we can advance to the next stage
    const prevStatus = queryStatus?.previous_status();
    if (prevStatus !== undefined) {
      const requirementSet = queryStatus?.get_requirements_map();
      const checkedRequirement = requirementSet?.[prevStatus].find(
        (requirement) => {
          if (requirement.type === "submit") {
            // Then this is the current requirement
            return true;
          } else if (requirement.type === "check") {
            // Then we need to check if the requirement is met
            // If the requirement check returns false, the find should return true!
            // Check is also allowed to query existing actions
            return !requirement.check(queryData, {
              response: response,
              tasks: [],
              approvals: [],
            });
          } else {
            // We don't support this requirement on the front-end which means it is not passed!
            return true;
          }
        }
      );
      if (checkedRequirement?.type === "check") {
        setStatusMessage(
          `Save unsuccessful. ${
            checkedRequirement?.statusMessage ?? checkedRequirement?.guideText
          }`
        );
        return;
      }
    }

    // Now init the save
    query.update({ ...queryData, lastEditedBy: userData.id });
    setStatusMessage("Query Saved");
    clean();
  }

  function deleteQuery() {
    // These are moved to a graveyard in the backend
    query.delete();
    setStatusMessage("Query Deleted");
  }

  function cancelQuery() {
    query.update({ status: "cancelled" });
    setStatusMessage("Query Cancelled");
  }

  // Check for render ready
  if (!(queryData && schemaData && userData)) {
    return null;
  }

  // Check for de-rendering
  if (Object.keys(queryDataStatic ?? {}).length === 0) {
    return null;
  }

  const creatorOnly =
    userData.id == queryDataStatic.creator || queryData.status !== "created";

  const approversOnly = queryDataStatic.approvalsRequired
    ? queryDataStatic.approvalsRequired[0]?.approvers?.includes(userData.id)
    : false;

  return (
    <QueryContextProvider
      projectUsers={projectUsers}
      projectData={projectData}
      projectLogo={projectLogo}
      projectQueries={queries}
      projectReference={project}
      userPermissions={userPermissions}
      showFieldErrors={showQueryErrors}
      autoCompleteUserlist={schemaData?.settings?.selectUserAutoComplete}
      queryStatus={queryStatus}
    >
      <QueryContainer>
        {schemaData.showProgression && (
          <QueryProgressBar schemaData={schemaData} status={queryData.status} />
        )}
        <Query
          title={
            queryData.dynamicId
              ? queryData.dynamicId?.toUpperCase()
              : queryData.id
          }
          status={queryData.status?.toUpperCase()}
          metaTitle={`by ${queryCreator?.name?.first} ${
            queryCreator?.name?.last
          } on 
              ${format(
                parse_db_timestamp(queryDataStatic.createTime),
                "dd/MM/yyyy"
              )}`}
          isOverdue={
            is_overdue(
              parse_db_timestamp(get_query_due_date(queryData, schemaData))
            ) && !queryData.closeTime
          }
          queryFormProps={{
            hasEvaluations: queryData?.evaluations !== undefined,
            hasIssuedForms: queryData?.lastFormCompositionIssued !== undefined,
          }}
          statusMessage={statusMessage}
          setStatusMessage={setStatusMessage}
          schemaData={schemaData}
          defaultView={defaultFocus}
          dirty={dirty}
          clean={clean}
          saveQuery={saveQuery}
          deleteQuery={deleteQuery}
          cancelQuery={cancelQuery}
          commentsOpen={commentsOpen}
          setCommentsOpen={setCommentsOpen}
          query={query}
        >
          {/* {readonly && <ReadonlyBanner />} */}
          {readonly && (
            <QueryBanner color={"#ffffc1"}>
              This Query is currently being edited by another user. You will not
              be able to edit it until they have finished
            </QueryBanner>
          )}
          {adminEdit && (
            <QueryBanner color={"#FF7276"}>
              You are currently editing this Query as an administrator. This
              subverts the editing rules of the Query
              <IconButton
                style={{ padding: 0 }}
                onClick={() => setAdminEdit(false)}
              >
                <Close />
              </IconButton>
            </QueryBanner>
          )}
          {commentsOpen && (
            <CommentSidebar
              onClose={() => setCommentsOpen(false)}
              query={query}
            />
          )}
          <ExistingQueryContent
            queryData={queryData}
            schema={schema}
            schemaData={schemaData}
            projectData={projectData}
            setQueryData={setQueryData}
            setQueryValidationErrors={setQueryValidationErrors}
            project={project}
            query={query}
            queries={queries}
            readonly={readonly}
            creatorOnly={creatorOnly}
            approversonly={approversOnly}
            adminEdit={adminEdit}
            setAdminEdit={setAdminEdit}
            setStatusMessage={setStatusMessage}
          />
          <CommonQueryContent
            queryData={queryData}
            setQueryData={setQueryData}
            schemaData={schemaData}
            project={project}
            query={query}
            readonly={readonly}
            setStatusMessage={setStatusMessage}
          />
          {!readonly && (
            <QueryWorkflow
              query={query}
              queryData={queryData}
              schemaData={schemaData}
              setStatusMessage={setStatusMessage}
              setShowErrors={setShowQueryErrors}
              queryValidationErrors={queryValidationErrors}
            />
          )}
        </Query>
      </QueryContainer>
    </QueryContextProvider>
  );
};
