import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { isMobile } from "react-device-detect";
import { Add } from "@mui/icons-material";

import {
  InputShim,
  InputContainer,
  DropInputContainer,
  FloatingFieldEditButtons,
} from "../../query/admin/fields/tools.js";
import { ChartResolver } from "../../../ui/charts";
import Draggable from "react-draggable";

export const EditableFieldResolver = ({
  chartSchema,
  data,
  actions,
  theme,
  sendBlob,
  onFieldChange,
  onFieldDelete,
  onFieldDuplicate,
  onFieldDrag,
  index,
  isBeingDragged,
  types,
  setName,
  setFormat,
}) => {
  const [showEditButtons, setshowEditButtons] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  // Field state management
  const fieldState = useRef(undefined);

  // Field state auto-modifier
  useEffect(() => {
    if (fieldState.current) {
      // fieldState has a previous value, let's see if we need to update it
    }
    // The last thing we do is modify the field state
    fieldState.current = chartSchema;
  }, [chartSchema]);

  //draggable functions
  const handleOnStart = (e, data) => {
    onFieldDrag(e, data, chartSchema, index);
    setIsDragging(true);
  };

  const handleOnStop = (e, data) => {
    onFieldDrag(e, data, chartSchema, index);
    setIsDragging(false);
  };

  // Now let's build the actual field based on its resolution
  return (
    <Draggable
      handle="strong"
      onStart={(e, data) => handleOnStart(e, data)}
      onStop={(e, data) => handleOnStop(e, data)}
      position={{ x: 0, y: 0 }} //snap back to original position
    >
      <Charts>
        <InputContainer
          onMouseEnter={() => setshowEditButtons(true)}
          onMouseLeave={() => setshowEditButtons(false)}
          style={{ maxWidth: "min-content" }}
        >
          <ChartResolver
            chartSchema={chartSchema}
            data={data}
            actions={actions}
            theme={theme}
            sendBlob={sendBlob}
            isEditMode
            setName={setName}
            setFormat={setFormat}
            index={index}
          />

          {showEditButtons && !isBeingDragged && (
            <FloatingFieldEditButtons
              onFieldDelete={onFieldDelete}
              onFieldChange={onFieldChange}
              onFieldDuplicate={onFieldDuplicate}
              types={types}
            />
          )}
        </InputContainer>
      </Charts>
    </Draggable>
  );
};

export const NewFieldPlaceholder = ({ hidden, addFieldCallback }) => {
  const [hiddenActive, setHiddenActive] = useState(false);
  const hoverTimeout = useRef(false);

  const triggerShowNFP = () => {
    hoverTimeout.current = true;
    setTimeout(() => {
      if (hoverTimeout.current) {
        setHiddenActive(true);
      }
    }, 400);
  };

  // If we're using hidden and it's not active, show a sliver div with hover available
  if (hidden && !hiddenActive) {
    return (
      <InputShim
        onMouseEnter={triggerShowNFP}
        onMouseLeave={() => (hoverTimeout.current = false)}
      />
    );
  }

  // Now return the full add button if we're not hidden
  return (
    <InputContainer
      style={{
        alignItems: "center",
        justifyContent: "center",
        maxWidth: hidden ? "75px" : "100%",
        cursor: "pointer",
        alignSelf: "stretch",
      }}
      onMouseLeave={() => setTimeout(() => setHiddenActive(false), 200)}
      onClick={addFieldCallback}
    >
      <div 
        style={{
          fontSize: hidden ? "16px" : "18px", 
          userSelect: "none", 
          color: "#FFFFFF", 
          padding: "5px"
        }}
      >
        New Chart
      </div>
      <Add />
    </InputContainer>
  );
};

export const DragHoverPlaceHolder = ({
  hidden,
  setIsDroppable,
  setDropData,
  index,
}) => {
  const [hiddenActive, setHiddenActive] = useState(false);
  const hoverTimeout = useRef(false);

  const triggerShowNFP = () => {
    hoverTimeout.current = true;
    setTimeout(() => {
      if (hoverTimeout.current) {
        setHiddenActive(true);
        setIsDroppable(true);
        setDropData(index);
      }
    }, 400);
  };

  if (hidden && !hiddenActive) {
    return (
      <DropInputContainer
        onMouseEnter={triggerShowNFP}
        onMouseLeave={() => (hoverTimeout.current = false)}
        style={{
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          alignSelf: "stretch",
          zIndex: 10,
        }}
      >
        <div
          style={{
            fontSize: hidden ? "16px" : "18px",
            userSelect: "none",
            opacity: "0.5",
            color: "#FFFFFF"
          }}
        >
          Drop Field Here
        </div>
      </DropInputContainer>
    );
  }

  return (
    <InputContainer
      style={{
        width: "200px",
        height: "400px",
        zIndex: 10,
      }}
      onMouseLeave={() => {
        setIsDroppable(false), setTimeout(() => setHiddenActive(false), 200);
      }}
    ></InputContainer> //set drag hover preview in this container if wanted
  );
};

const Charts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${isMobile &&
  css`
    width: 100%;
  `}
`;
