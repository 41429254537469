import React, { useState, useEffect } from "react";
import q from "query-it";
import styled from "@emotion/styled";
// Hooks
import {
  useDeleteUser,
  useUserNotifications,
  useUserUpdate,
  useUserLookup,
} from "../../../../hooks/users";
import {
  useCompanies,
  useAddCompanyUser,
  useRemoveCompanyUser,
} from "../../../../hooks/companies";
import {
  useAllProjects,
  useRemoveProjectUser,
} from "../../../../hooks/projects";
import { useDevlog } from "../../../../hooks/devlog";
import { useSearchParams } from "react-router-dom";
// UI
import { QueryBlur, QueryStatusBanner } from "../../../project/query/query";
import { BooleanField, GenericField, SelectField } from "../../../ui/inputs2";
import { InteractiveTable } from "../../../ui/table";
import { IconButton } from "@mui/material";
import { Button2 as Button } from "../../../ui/buttons";
import { Close } from "@mui/icons-material";
import { ConfirmationModal } from "../../../ui/modals";
import { VacationCard } from "../../../account/manage/index";

import CSSTransition from "react-transition-group/CSSTransition";
import "./styles.css";
import { isMobile } from "react-device-detect";

export default ({ userID }) => {
  // Get the userData and userRef
  const [userData, userRef] = useUserLookup(userID);

  // Initial animation
  const [editIsOpen, setEditIsOpen] = useState(false);
  // General fields
  const [editUser, setEditUser] = useState(undefined);
  const updateUser = useUserUpdate();
  // Companies
  const [companies] = useCompanies();
  const addCompanyUser = useAddCompanyUser();
  const removeCompanyUser = useRemoveCompanyUser();
  const [originalCompany, setOriginalCompany] = useState(undefined);
  // ^ Not sure if this is the best implementation. It's meant to track the company we're deleting the user from
  const [selectedCompany, setSelectedCompany] = useState();
  const [companyOptions, setCompanyOptions] = useState([]);
  // Projects
  const [projects] = useAllProjects();
  const [deleteProjects, setDeleteProjects] = useState([]);
  const removeProjectUser = useRemoveProjectUser();
  // Notifications
  const userNotifications = useUserNotifications(userID);
  // Devlog
  const devlog = useDevlog();
  // Confirmation Modal
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false);
  // Delete user
  const deleteUser = useDeleteUser();
  // Search Params
  const [searchParams, setSearchParams] = useSearchParams();
  // Banner Animation
  const [bannerAnim, setBannerAnim] = useState(undefined);

  useEffect(() => {
    setEditIsOpen(true);
  }, []);

  useEffect(() => {
    if (userData) {
      setEditUser({
        first: userData.name?.first,
        last: userData.name?.last,
        eula: userData.eula,
        notification: userData.notification,
        notifyFrequency: userData.notifyFrequency,
        // Vacation has not been implemented yet
        vacation: false,
        // Ban user has not been implemented yet
        ban: false,
        devlog: [],
        phone: userData.phone,
        id: userData.id,
      });
    }
  }, [userData]);

  useEffect(() => {
    // NOTE: company selection process assumes that the user can only be part of ONE company.
    companies?.forEach((company) => {
      if (company.users.includes(userID)) {
        setSelectedCompany(company.id);
        if (!originalCompany) {
          setOriginalCompany(company.id);
        }
      }
      setCompanyOptions((ex) => ({ ...ex, [company.id]: company.name }));
    });
  }, [companies]);

  const onEditChange = (ind) => (e) => {
    const { value } = e.target;
    setEditUser((ex) => ({ ...ex, [ind]: value }));
  };

  const onProjectsChange = (projectId) => (e) => {
    setDeleteProjects([...deleteProjects, projectId]);
  };

  const onCompanyChange = (e) => {
    const { value } = e.target;
    setSelectedCompany(value);
  };

  const onEditSubmit = () => {
    // 1. for each project in deleteProjects, remove the user from that project
    deleteProjects.forEach((project) => removeProjectUser(project, userID));
    // 2. remove the user from the old company's users array and add user to the new company's users array
    if (selectedCompany !== originalCompany) {
      if (originalCompany) {
        removeCompanyUser(originalCompany, userID);
      }
      if (selectedCompany) {
        addCompanyUser(selectedCompany, userID);
      }
      setOriginalCompany(selectedCompany);
    }
    // 3. Create the update data, and strip any undefined fields to not override potentially existing values
    let updateData = {
      eula: editUser.eula,
      notification: editUser.notification,
      notifyFrequency: editUser.notifyFrequency,
      name: {
        first: editUser.first,
        last: editUser.last,
      },
    };
    Object.keys(updateData).forEach((key) =>
      updateData[key] === undefined ? delete updateData[key] : {}
    );
    // 3. update user on firebase (call a hook)
    updateUser(editUser.id, updateData);
    // 4. Close the edit window
    closePopup();
  };

  const onDeleteUser = () => {
    // 1. Remove user from all projects (if they have any)
    if (projects) {
      projects
        .filter((project) => project.users?.includes(userID))
        .map((project) => project.id)
        .forEach((projectID) => removeProjectUser(projectID, userID));
    }
    // 2. Remove user from their company (if they are in one)
    if (originalCompany) {
      removeCompanyUser(originalCompany, userID);
    }
    // 3. Delete user from the users collection
    deleteUser(userID);
  };

  const sendPasswordReset = () => {
    try {
      q.auth
        .sendPasswordResetEmail(userData.email)
        .then((res) => {
          setBannerAnim("Password Reset Email Sent");
          setTimeout(() => setBannerAnim(undefined), 3000);
        })
        .catch((err) => {
          setBannerAnim("Email Not Found");
          setTimeout(() => setBannerAnim(undefined), 3000);
        });
    } catch (err) {
      setBannerAnim("No Email Provided");
      setTimeout(() => setBannerAnim(undefined), 3000);
    }
  };

  const closePopup = () => {
    searchParams.delete("user");
    setSearchParams(searchParams);
  };

  return (
    <EditContainer>
      <QueryBlur onClick={closePopup} />

      <CSSTransition
        in={editIsOpen}
        timeout={500}
        classNames="edit"
        unmountOnExit
      >
        <Popup>
          <TopBar>
            <p style={{ marginLeft: "12px", fontSize: "20px" }}>
              <b>Edit/View User Information</b>
            </p>
            <IconButton onClick={closePopup} style={{ marginRight: "15px" }}>
              <Close />
            </IconButton>
          </TopBar>
          <CSSTransition
            in={bannerAnim ? true : false}
            timeout={3600}
            classNames="querybanner"
          >
            <QueryStatusBanner style={{ top: "53px", left: "3px", zIndex: 26 }}>
              {bannerAnim}
            </QueryStatusBanner>
          </CSSTransition>

          <ComponentContent>
            <EditUserPane>
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                Basic User Info
              </p>
              <hr />
              <GenericField
                label={"First Name"}
                data={editUser?.first}
                onChange={onEditChange("first")}
              />
              <GenericField
                label={"Last Name"}
                data={editUser?.last}
                onChange={onEditChange("last")}
              />
              <br />
              <SelectField
                label={"Notification Preference"}
                data={editUser?.notification}
                options={
                  editUser?.phone === undefined || editUser?.phone?.length < 1
                    ? noPhoneNotificationPreferences
                    : notificationPreferences
                }
                onChange={onEditChange("notification")}
              />
              <SelectField
                label={"Notification Frequency"}
                data={editUser?.notifyFrequency}
                options={notifyFrequencyOptions}
                onChange={onEditChange("notifyFrequency")}
              />
              <SelectField
                label={"Company"}
                data={selectedCompany}
                options={companyOptions}
                onChange={onCompanyChange}
              />
              <br />
              <BooleanField
                label={"EULA Accepted"}
                data={editUser?.eula}
                onChange={onEditChange("eula")}
              />
              {/* <BooleanField
                label={"On Vacation"}
                data={editUser?.vacation}
                onChange={onEditChange("vacation")}
              /> */}
              <BooleanField
                label={"Ban User"}
                data={editUser?.ban}
                onChange={onEditChange("ban")}
              />
            </EditUserPane>
            <div>
              <VacationCard user={userRef} userData={userData} admin />
            </div>
            <SectionHeader>User Projects</SectionHeader>
            <div style={{ marginLeft: "12px", marginRight: "12px" }}>
              {projects && (
                <InteractiveTable
                  data={projects?.filter(
                    (project) =>
                      project.users?.includes(userID) &&
                      !deleteProjects.includes(project.id)
                  )}
                  columns={[
                    { name: "Name", index: "name" },
                    {
                      name: "Action",
                      component: (row) => (
                        <Button
                          onClick={onProjectsChange(row.id)}
                          label="Remove User"
                        />
                      ),
                    },
                  ]}
                  allowOrdering
                />
              )}
            </div>

            <SectionHeader>User Notifications</SectionHeader>
            <div style={{ marginLeft: "12px", marginRight: "12px" }}>
              {userNotifications && (
                <InteractiveTable
                  data={userNotifications}
                  columns={userNotificationsInteractiveColumns}
                  allowOrdering
                />
              )}
            </div>

            <SectionHeader>User Devlog</SectionHeader>
            <div
              style={{
                marginLeft: "12px",
                marginRight: "12px",
                marginBottom: "15px",
              }}
            >
              {devlog && (
                <InteractiveTable
                  data={devlog.filter((item) => item.user == userID)}
                  columns={devlogInteractiveColumns}
                  allowOrdering
                />
              )}
            </div>
          </ComponentContent>

          <BottomBar>
            <div>
              <Button
                label="Delete"
                style={{ marginLeft: "12px" }}
                onClick={() => setConfirmDeleteUser(true)}
              />
              <ConfirmationModal
                open={confirmDeleteUser}
                body="Are you sure you want to delete this user? They will be removed from their company as well as all projects."
                onConfirm={() => {
                  onDeleteUser();
                  setConfirmDeleteUser(false);
                  closePopup();
                  setConfirmDeleteUser(false);
                }}
                onCancel={() => setConfirmDeleteUser(false)}
              />
            </div>
            <div>
              <Button
                label="Reset Password"
                style={{ marginRight: "12px" }}
                onClick={sendPasswordReset}
              />
              <Button
                label="Rewelcome"
                style={{ marginRight: "12px" }}
                onClick={async () => {
                  await updateUser(userID, { rewelcomeRequested: true });
                  // Now we also need to fire change in a user's project that is launched, let's see if we can find one
                  // Lines below are from oldindex but changed slightly
                  //  - user projects retrieved differently since we already have a list of all projects
                  const userProjects = projects?.filter((project) =>
                    project.users?.includes(userID)
                  );
                  let usefulProjects = userProjects.filter(
                    (proj) => proj.launched === true
                  );
                  if (usefulProjects.length > 0) {
                    let targetProject = usefulProjects[0];
                    await q.projects.project(targetProject.id).update({
                      triggerWelcomeCheck:
                        (targetProject?.triggerWelcomeCheck ?? 0) + 1,
                    });
                  }
                  setBannerAnim("User Rewelcome Set");
                  setTimeout(() => setBannerAnim(undefined), 3000);
                }}
              />
              <Button
                label="Save"
                style={{ marginRight: "12px" }}
                onClick={onEditSubmit}
              />
            </div>
          </BottomBar>
        </Popup>
      </CSSTransition>
    </EditContainer>
  );
};

const noPhoneNotificationPreferences = {
  email: "Email",
  app: "In-App Only",
};

const notificationPreferences = {
  email: "Email",
  text: "Text",
  app: "In-App Only",
};

const notifyFrequencyOptions = {
  immediate: "Real-Time (Immediate)",
  halfday: "Twice Daily (9am, 5pm)",
  daily: "Daily",
  twiceweek: "Twice Weekly (Today, 4 days later)",
  weekly: "Weekly (Today)",
};

const userNotificationsInteractiveColumns = [
  { name: "Title", index: "title" },
  { name: "Time", index: "timestamp" },
  { name: "Project ID", index: "projectId" },
  { name: "Read?", index: "read" },
];

const devlogInteractiveColumns = [
  { name: "Time", index: "timestamp" },
  { name: "User", index: "user" },
  { name: "Message", index: "error" },
];

const EditContainer = styled.div`
  position: absolute;
  font-family: ${(props) => props.theme.palette.font};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Popup = styled.div`
  height: ${isMobile ? "100%" : "80%"};
  min-height: 300px;
  width: ${isMobile ? "100%" : "50%"};
  background-color: blue;
  z-index: 20;
  position: relative;

  @media (max-width: 1070px) {
    width: 100%;
  }
`;

const ComponentContent = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 25;
`;

const EditUserPane = styled.div`
  background-color: white;
  position: relative;
  margin-left: 12px;
  margin-right: 12px;
`;

const TopBar = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;

  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  border-bottom: 1px solid #c2c2c2;
  user-select: none;
  z-index: 30;
`;

const BottomBar = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;

  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  border-top: 1px solid #c2c2c2;
  z-index: 30;
`;

const SectionHeader = styled.p`
  font-size: 18px;
  margin-left: 12px;
  font-weight: bold;
`;
