import React, { useContext, useEffect, useState, useCallback } from "react";
import styled, { ThemeContext } from "styled-components";
import { isMobile } from "react-device-detect";
import { ThemeProvider } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import { materialThemeContrast } from "../../themes/mui_theme";
import { Link, Navigate } from "react-router-dom";

export const ModalNotify = React.forwardRef((props, ref) => {
  const theme = useContext(ThemeContext);
  return (
    <ThemeProvider ref={ref} theme={materialThemeContrast(theme)}>
      <$Modal ref={ref}>{props.children}</$Modal>
    </ThemeProvider>
  );
});

export const ModalPrompt = React.forwardRef((props, ref) => {
  const theme = useContext(ThemeContext);
  return (
    <ThemeProvider ref={ref} theme={materialThemeContrast(theme)}>
      <$ModalPrompt ref={ref}>{props.children}</$ModalPrompt>
    </ThemeProvider>
  );
});

export const ModalViewFullScreen = React.forwardRef((props, ref) => {
  const theme = useContext(ThemeContext);
  return (
    <ThemeProvider ref={ref} theme={materialThemeContrast(theme)}>
      <$ModalViewFullScreen ref={ref}>{props.children}</$ModalViewFullScreen>
    </ThemeProvider>
  );
});

export const PopupModal = React.forwardRef((props, ref) => {
  const theme = useContext(ThemeContext);
  return (
    <ThemeProvider ref={ref} theme={materialThemeContrast(theme)}>
      <$PopupModal ref={ref}>{props.children}</$PopupModal>
    </ThemeProvider>
  );
});

export const EscapeHeader = () => {
  const [escapeTriggered, setEscapeTriggered] = useState(false);

  // Make a key handler function
  const checkForEscape = useCallback((e) => {
    if (e.code === "Escape") {
      setEscapeTriggered(true);
    }
  }, []);

  // Only add on first mount of component!
  useEffect(() => {
    document.addEventListener("keydown", checkForEscape);
    return () => document.removeEventListener("keydown", checkForEscape);
  }, [checkForEscape]);

  if (escapeTriggered) {
    return <Navigate to="/app/projects" />;
  }

  return (
    <EscapeDiv>
      <Link to={`/app/projects`}>
        <IconButton>
          <Close />
        </IconButton>
      </Link>
    </EscapeDiv>
  );
};

export const UnderConstructionNotice = () => {
  return (
    <Fullscreen>
      This view is currently a work in progress. Please check back later, or
      contact us for more information.
    </Fullscreen>
  );
};

export const FloatingPage = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.background};
  font-family: ${(props) => props.theme.font};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;

  // background: ${(props) => props.theme.step50};
  background-color: white;
  border-radius: 4px;

  max-height: 85%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 0;
  flex-grow: 1;
  flex-direction: row;

  box-sizing: border-box;
  font-family: ${(props) => props.theme.font};

  position: relative;
  overflow: auto;
`;

export const Tile = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  min-width: 50%;

  background: ${(props) => props.theme.step50};
  color: ${(props) => props.theme.text};
  border-radius: 4px;
`;

export const FloatingSideContainer = styled.div`
  background: white;
  width: ${(props) => (props?.icons ? "32px" : "unset")};
  min-width: ${(props) => (props?.icons ? "unset" : "80px")};
  border-radius: 4px;
  padding: 3px;
  display: flex;
  flex-direction: column;

  ${(props) => props?.orientation ?? "left"}: -50px;
  z-index: 1200;  

  position: sticky;

  margin-${(props) => props?.orientation ?? "left"}: 10px;
  height: fit-content;

  top: 0px; // To reset the top position
`;

const $Modal = styled.div`
  font-family: ${(props) => props.theme.font};
  position: absolute;
  background: white;

  width: ${isMobile ? "80%" : "30%"};
  height: ${isMobile ? "25%" : "fit-content"};
  top: 30%;
  // ensure its centred horizontally for all views
  left: 50%;
  transform: translateX(-50%);

  max-width: ${isMobile ? "350px" : "unset"};
  min-height: 190px;

  z-index: 1300;
  border: none;
  border-radius: 8px;
  padding: 10px;
  outline: none;
`;

const $ModalPrompt = styled($Modal)`
  display: flex;
  flex-direction: column;
  min-width: 225px;
  padding-left: 30px;
  padding-right: 30px;
`;

const $ModalViewFullScreen = styled.div`
  font-family: ${(props) => props.theme.font};
  position: absolute;
  background: white;

  width: ${isMobile ? "100%" : "75%"};
  height: ${isMobile ? "100%" : "fit-content"};
  top: ${isMobile ? "0" : "20%"};
  left: ${isMobile ? "0" : "12.5%"};

  min-height: 190px;
  max-height: ${isMobile ? "100%" : "70%"};
  border: none;
  border-radius: 8px;
  padding: 10px;
  outline: none;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
`;

const $PopupModal = styled.div`
  font-family: ${(props) => props.theme.font};
  position: absolute;
  background: white;

  width: ${isMobile ? "100%" : "40%"};
  height: ${isMobile ? "100%" : "fit-content"};
  top: ${isMobile ? "0" : "30%"};
  left: ${isMobile ? "0" : "30%"};

  min-height: 190px;
  max-height: ${isMobile ? "100%" : "70%"};
  border: none;
  border-radius: 8px;
  padding: 10px;
  outline: none;

  display: flex;
  flex-direction: column;
`;

const EscapeDiv = styled.div`
  display: flex;
  position: relative;
  left: -30px;
  top: -10px;
  align-items: center;
  justify-content: flex-start;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  justify-content: flex-start;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Fullscreen = styled.div`
  min-width: 100%;
  min-height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
