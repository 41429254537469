import React, {
  useState,
  useRef,
  useContext,
  useCallback,
  useEffect,
} from "react";
import styled from "styled-components";

import {
  Button,
  DialogContent,
  IconButton,
  Modal,
  Tooltip,
  Typography,
  Paper,
} from "@mui/material";
import { AccountCircle, Rule } from "@mui/icons-material";
import { Button2 } from "../../../../ui/buttons";
import { ChartResolver } from "../../../../ui/charts";
import { QueryStatusTag } from "../../query";
import { QueryFields, ShowFieldErrorsContext } from "../../fields";
import { FloatingSelectList } from "../../admin/fields/tools";
import { TabPanelContainer } from "..";

import { useTasks } from "../../../../../hooks/queries";
import { UserDataContext } from "../../../../../App";
import { ProjectReferenceContext, ProjectUsersContext } from "../../..";
import { QueryBatchContext, NewQueryTasksContext } from "../../new_query";

import TaskDetail from "./details";
import CommentSidebar from "../../comments";
import NewTask from "./new";
import panelTheme from "../../../../../themes/index_panel";
import { UserCircle } from "../../../../ui/decorations";
import { addDays, format } from "date-fns";
import { parse_db_timestamp, is_overdue } from "../../../../../tools";
import {
  ModalPrompt,
  ModalContent,
  ModalFooter,
} from "../../../../ui/containers";
import { TEMPLATE_SCHEMA_STATUSSETS } from "../../../../../common/query";

import "./tasks.css";

const confirmationModalDefaults = {
  open: false,
  title: undefined,
  message: undefined,
  confirm: () => {},
};

const taskFilterDefaults = {
  status: undefined,
  assignedTo: undefined,
};

const charts = [
  {
    name: "Tasks by Status",
    type: "pie",
    field: "status",
    id: "task-count",
    labelLimit: 6,
    icon: <Rule />,
  },
  {
    name: "Tasks by Assignee",
    type: "pie",
    field: "assignedTo",
    id: "task-assignments",
    label: "users",
    labelFormat:
      "return (val?.name ? `${val.name.first} ${val.name.last}` : 'Unassigned')",
    labelLimit: 6,
    icon: <AccountCircle />,
  },
];

export default ({
  query,
  schemaData,
  queryData,
  setStatusMessage,
  newQueryRef,
}) => {
  const userData = useContext(UserDataContext);
  const users = useContext(ProjectUsersContext);
  const project = useContext(ProjectReferenceContext);
  const queryBatch = useContext(QueryBatchContext);
  const { newQueryTasks, setNewQueryTasks } = useContext(NewQueryTasksContext);

  const tasks = useTasks(query);
  const [newTask, setNewTask] = useState(undefined);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [newTaskCreated, setNewTaskCreated] = useState(false);
  const [newTaskSelected, setNewTaskSelected] = useState(false);
  const [closureTried, setClosureTried] = useState(false);
  const [taskFilter, setTaskFilter] = useState(taskFilterDefaults);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [chartIndex, setChartIndex] = useState(0);
  const [showChartOptions, setShowChartOptions] = useState(false);

  const taskSchema = schemaData.taskSchema ?? TASK_SCHEMA_DEFAULT;
  const newTaskDefaultData = {
    creator: userData.id,
    createdDate: new Date(),
    data: {},
    closure: {},
    status: "new",
    projectId: project.ref.id,
    schemaId: schemaData.id,
    ...(schemaData?.defaultDateLeads?.task
      ? { dueDate: addDays(new Date(), schemaData.defaultDateLeads.task) }
      : {}),
  };

  // Then fetch the status set
  let statuses =
    schemaData.type === "custom"
      ? schemaData.customStatusSet
      : TEMPLATE_SCHEMA_STATUSSETS[schemaData.type];
  const currentStatusMeta = statuses[queryData.status ?? "new"];

  const addTaskFeedToBatch = (taskRef, feed) => {
    const feedCollection = taskRef.collection("feed");
    const newFeedRef = queryBatch.current.add(feedCollection);
    queryBatch.current.set(newFeedRef, feed);
  };

  const deleteTask = (taskId) => {
    if (queryBatch) {
      const taskRef = newQueryRef.current.collection("tasks").doc(taskId);
      queryBatch.current.delete(taskRef);
      setNewQueryTasks(newQueryTasks.filter((task) => task.id !== taskId));
    } else {
      query.tasks.task(taskId).delete();
    }
    setSelectedTask(undefined);
  };

  const [confirmationModal, setConfirmationModal] = useState({
    ...confirmationModalDefaults,
  }); // add message to activate

  const completeTask = (taskId) => {
    setClosureTried(true);
    // Check that the task is eligible for completion
    if (!selectedTask?.closure?.description) {
      setStatusMessage("Please provide a closure description");
      return;
    }
    if (selectedTask.dueDate != null && isNaN(selectedTask.dueDate)) {
      setStatusMessage("Date is invalid. Please try again.");
      return;
    }
    const updatedTask = {
      ...selectedTask,
      status: "closed",
      closedBy: userData.id,
      closedAt: new Date(),
    };
    const feed = {
      message: `The task was closed by ${userData?.name?.first} ${userData?.name?.last}`,
      user: userData.id,
      timestamp: new Date(),
    };
    if (queryBatch) {
      const taskRef = newQueryRef.current.collection("tasks").doc(taskId);
      queryBatch.current.update(taskRef, updatedTask);
      addTaskFeedToBatch(taskRef, feed);
      setNewQueryTasks(
        newQueryTasks.map((task) => {
          if (task.id === selectedTask.id) {
            return updatedTask;
          }
          return task;
        })
      );
    } else {
      const tk = query.tasks.task(taskId);
      tk.update(updatedTask);
      tk.feed.add(feed);
    }
    setSelectedTask(undefined);
  };

  const saveTask = () => {
    const error = validateTask(
      selectedTask,
      schemaData?.settings?.requireTaskAssignee
    );
    if (error) {
      setStatusMessage(error);
      return;
    }

    const feed = {
      message: `${userData?.name?.first} ${userData?.name?.last} updated the task`,
      user: userData.id,
      timestamp: new Date(),
    };
    if (queryBatch) {
      const taskRef = newQueryRef.current
        .collection("tasks")
        .doc(selectedTask.id);
      queryBatch.current.update(taskRef, selectedTask);
      addTaskFeedToBatch(taskRef, feed);
      setNewQueryTasks(
        newQueryTasks.map((task) => {
          if (task.id === selectedTask.id) {
            return selectedTask;
          }
          return task;
        })
      );
      setStatusMessage("Task Saved");
    } else {
      const tk = query.tasks.task(selectedTask.id);
      tk.update(selectedTask);
      tk.feed.add(feed).then((d) => {
        setStatusMessage("Task Saved");
      });
    }
  };

  const createTask = async () => {
    const createdTask = { ...newTask, status: "open" };
    const feed = {
      message: `The task was created by ${userData?.name?.first} ${userData?.name?.last}`,
      user: userData.id,
      timestamp: new Date(),
    };

    if (queryBatch) {
      const taskCollection = newQueryRef.current.collection("tasks");
      const newTaskRef = queryBatch.current.add(taskCollection);
      queryBatch.current.set(newTaskRef, createdTask);
      addTaskFeedToBatch(newTaskRef, feed);
      setNewQueryTasks([
        { ...createdTask, id: newTaskRef.ref.id },
        ...newQueryTasks,
      ]);
    } else {
      const newTaskRef = await query.tasks.add(createdTask);
      const tk = query.tasks.task(newTaskRef.id);
      tk.feed.add(feed);
    }
    setNewTask(undefined);
  };

  const duplicateTask = (taskData) => {
    if (newTask) {
      setStatusMessage(
        "A new task already exists. Please create or cancel it before duplicating an existing task"
      );
    } else {
      const REMOVED_PROPERTIES = ["id", "closedAt", "closedBy"];
      const processedTaskData = {
        ...taskData,
        creator: userData.id,
        createdDate: new Date(),
        status: "new",
      };
      REMOVED_PROPERTIES.forEach((key) => delete processedTaskData[key]);

      setSelectedTask(undefined);
      setNewTask(processedTaskData);
      setNewTaskSelected(true);
    }
  };

  // Auto-select first task
  useEffect(() => {
    const taskList = queryBatch ? newQueryTasks : tasks;
    if (taskList && taskList.length > 0 && !newTask && !newTaskCreated) {
      setSelectedTask((ex) => (ex === undefined ? taskList[0] : ex));
    }
  }, [tasks, newTask, queryBatch, newQueryTasks, newTaskCreated]);

  // Auto-select newly created task
  useEffect(() => {
    const taskList = queryBatch ? newQueryTasks : tasks;
    if (newTaskCreated && taskList) {
      setTimeout(() => {
        setSelectedTask(taskList[0]);
        setNewTaskCreated(false);
      }, 500);
    }
  }, [tasks, newTaskCreated, queryBatch, newQueryTasks]);

  let renderedTasks = queryBatch ? newQueryTasks : tasks;
  // chartResolver errors when assignedTo values are null
  renderedTasks = renderedTasks?.map((task) => {
    if (task.assignedTo === null) {
      delete task.assignedTo;
    }
    return task;
  });
  const filteredTasks = renderedTasks?.filter((task) =>
    Object.keys(taskFilter).every((filter) => {
      return !taskFilter[filter] || taskFilter[filter] === task[filter];
    })
  );

  return (
    <TabPanelContainer
      style={{ marginLeft: 0, marginBottom: "5px", overflow: "hidden" }}
    >
      <div style={{ width: 10 }}></div>
      <TabPanelLeftBar>
        <div>
          <Paper
            elevation={3}
            onMouseEnter={() => setShowChartOptions(true)}
            onMouseLeave={() => setShowChartOptions(false)}
          >
            {filteredTasks && filteredTasks.length > 0 && (
              <div style={{ position: "relative", marginTop: "10px" }}>
                <div
                  style={{
                    position: "absolute",
                    left: "87%",
                  }}
                >
                  <Tooltip title={charts[chartIndex].name}>
                    <IconButton size="small">
                      {charts[chartIndex].icon}
                    </IconButton>
                  </Tooltip>
                </div>
                {showChartOptions && (
                  <ChartOptions>
                    <Tooltip title={charts[chartIndex === 0 ? 1 : 0].name}>
                      <IconButton
                        size="small"
                        onClick={() => setChartIndex(chartIndex === 0 ? 1 : 0)}
                      >
                        {charts[chartIndex === 0 ? 1 : 0].icon}
                      </IconButton>
                    </Tooltip>
                  </ChartOptions>
                )}
                <div
                  style={{
                    marginLeft: "-12px",
                  }}
                >
                  <ChartResolver
                    key="task-data-chart"
                    data={filteredTasks}
                    chartSchema={charts[chartIndex]}
                    theme={{
                      ...panelTheme,
                      tileBackground: "white",
                      text: "black",
                      noOverride: true,
                    }}
                  />
                </div>
              </div>
            )}
          </Paper>
          {renderedTasks?.length > 0 && filteredTasks?.length === 0 && (
            <div
              style={{
                marginTop: "10px",
                marginBottom: "12px",
              }}
            >
              <Paper elevation={3}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    height: "200px",
                  }}
                >
                  <Typography variant="h6">No tasks match filter</Typography>
                </div>
              </Paper>
            </div>
          )}
        </div>
        <Paper elevation={3}>
          <SummarySection
            style={{
              justifyContent: "space-around",
              margin: "2px 0",
            }}
          >
            <FilterDropdownButtons
              taskFilter={taskFilter}
              setTaskFilter={setTaskFilter}
              setSelectedTask={setSelectedTask}
            />
            <Button2
              size="small"
              label="New Task"
              disabled={currentStatusMeta?.statusType === "closed"}
              onClick={() => {
                if (newTask) {
                  setStatusMessage(
                    "A new task already exists. Please create or cancel it before making a new one"
                  );
                } else {
                  setSelectedTask(undefined);
                  setNewTask(newTaskDefaultData);
                  setNewTaskSelected(true);
                }
              }}
            />
            <div style={{ marginLeft: "-20px" }}>
              <Tooltip
                title={`Showing ${filteredTasks?.length ?? 0}/${
                  renderedTasks?.length ?? 0
                } tasks`}
              >
                <TaskCount>{filteredTasks?.length ?? 0}</TaskCount>
              </Tooltip>
            </div>
          </SummarySection>
        </Paper>
        <Paper elevation={3} sx={{ overflow: "auto", margin: "10px 0" }}>
          <TaskList>
            {/* If we're adding a task, give it a temporary listing as well! */}
            {newTask && (
              <TaskListEntry
                key="newtask"
                onClick={() => {
                  setSelectedTask(undefined);
                  setNewTaskSelected(true);
                }}
                selected={newTaskSelected}
                taskId="NEW TASK"
                task={newTask}
                assignee={
                  users.find((user) => user.id === newTask.assignedTo) ?? []
                }
              />
            )}
            {filteredTasks?.map((task, ind) => (
              <TaskListEntry
                key={`${task.id}`}
                onClick={() => {
                  setNewTaskSelected(false);
                  setSelectedTask(task);
                }}
                selected={selectedTask?.id === task.id}
                taskId={`TASK-${filteredTasks.length - ind}`}
                task={task}
                assignee={
                  users.find((user) => user.id === task.assignedTo) ?? []
                }
              />
            ))}
          </TaskList>
        </Paper>
      </TabPanelLeftBar>
      <Modal
        open={confirmationModal.open}
        onClose={() => {
          setConfirmationModal({ ...confirmationModalDefaults });
        }}
      >
        <DialogContent>
          <ModalPrompt>
            <ModalContent>
              <h2>{confirmationModal.title}</h2>
              <p>{confirmationModal.message}</p>
            </ModalContent>
            <ModalFooter>
              <Button
                onClick={() => {
                  setConfirmationModal({ ...confirmationModalDefaults });
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  confirmationModal.confirm();
                  setConfirmationModal({ ...confirmationModalDefaults });
                }}
              >
                OK
              </Button>
            </ModalFooter>
          </ModalPrompt>
        </DialogContent>
      </Modal>
      <TaskDetailContainer empty={!newTask && !selectedTask}>
        {!newTask && !selectedTask && (
          <Typography variant="h5" style={{ color: "grey" }}>
            Create or select a task to get started
          </Typography>
        )}
        {newTask && newTaskSelected && (
          <NewTask
            newTask={newTask}
            setNewTask={setNewTask}
            createTask={createTask}
            taskSchema={taskSchema}
            setConfirmationModal={setConfirmationModal}
            setNewTaskCreated={setNewTaskCreated}
            setStatusMessage={setStatusMessage}
            assigneeRequired={schemaData?.settings?.requireTaskAssignee}
          />
        )}
        {selectedTask && (
          <TaskDetail
            query={query}
            taskData={selectedTask}
            setTaskData={setSelectedTask}
            deleteTask={deleteTask}
            completeTask={completeTask}
            saveTask={saveTask}
            duplicateTask={duplicateTask}
            taskSchema={taskSchema}
            setConfirmationModal={setConfirmationModal}
            closureTried={closureTried}
            setCommentsOpen={setCommentsOpen}
            setStatusMessage={setStatusMessage}
          />
        )}
        {commentsOpen && (
          <CommentSidebar
            task={true}
            query={query}
            taskData={selectedTask}
            onClose={() => setCommentsOpen(false)}
          />
        )}
      </TaskDetailContainer>
    </TabPanelContainer>
  );
};

// This won't be implemented here, but is unified for "detail" and "new"
export const TaskDataForm = ({
  taskData,
  setTaskData,
  schema,
  enableClosure,
  closureTried,
}) => {
  const closureRef = useRef(null);

  const setFieldsCallback = useCallback(
    (ind) => (value) => {
      if (schema.find((sch) => ind === sch.id) !== undefined) {
        setTaskData((ex) => ({ ...ex, data: { ...ex?.data, [ind]: value } }));
      } else {
        setTaskData((ex) => ({ ...ex, [ind]: value }));
      }
    },
    [setTaskData, schema]
  );

  const setClosureFieldCallback = useCallback(
    (ind) => (value) => {
      setTaskData((ex) => ({
        ...ex,
        closure: { ...ex?.closure, [ind]: value },
      }));
    },
    [setTaskData]
  );

  // DEFECT: scrolls both the TaskDataFormContainer and TabPanelContainer
  //          for some reason which hides the new task button and task header
  // useEffect(() => {
  //   if (closureTried && closureRef.current) {
  //     closureRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [closureTried]);

  const editable = !TASK_CLOSED_STATUSES.includes(taskData.status)
    ? "open"
    : "closed";

  return (
    <TaskDataFormContainer>
      <ShowFieldErrorsContext.Provider value={closureTried}>
        {/* First a section for standard query metadata */}
        <TaskSectionHeader style={{ marginTop: "16px" }}>
          Task Details
        </TaskSectionHeader>
        <QueryFields
          data={
            taskData.creator === "0"
              ? {
                  ...taskData,
                  ...(taskData?.data ?? {}),
                  creator: "SYSTEM-GENERATED",
                }
              : { ...taskData, ...(taskData?.data ?? {}) }
          }
          onFieldChange={setFieldsCallback}
          fieldSet={[
            ...(taskData.creator === "0"
              ? TASK_SCHEMA_METADATA_GENERATED
              : TASK_SCHEMA_METADATA),
            ...schema.filter((sch) => sch.id !== "title"),
            ...(taskData.status === "closed"
              ? TASK_SCHEMA_METADATA_CLOSED
              : []),
          ]}
          status={editable}
          noHeaders
        />
        {/* Finish with a closure section (provided closure is enabled) */}
        {enableClosure && (
          <>
            <TaskSectionHeader>Closure</TaskSectionHeader>
            <QueryFields
              data={taskData?.closure ?? {}}
              onFieldChange={setClosureFieldCallback}
              fieldSet={TASK_SCHEMA_CLOSURE}
              status={editable}
              noHeaders
            />
            <div ref={closureRef}></div>
          </>
        )}
        {/* Finish with some view space */}
        <div style={{ minHeight: 120, width: 1 }}></div>
      </ShowFieldErrorsContext.Provider>
    </TaskDataFormContainer>
  );
};

const TaskListEntry = ({ assignee, task, selected, onClick }) => {
  let dateMessage = "No due date";
  const dueDate = parse_db_timestamp(task.dueDate);
  try {
    if (task.dueDate) {
      dateMessage = `Due ${format(dueDate, "MMM. d, y")}`;
    }
  } catch {
    dateMessage = "Invalid due date";
  }

  return (
    <TaskListEntryContainer selected={selected} onClick={onClick}>
      {/* Left side of the listing */}
      <div
        style={{
          display: "flex",
          flexGrow: 3,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* Adam here. I decided that task Ids add very little value right now. Have a title instead */}
        {/* <div style={{fontWeight: '400'}}>{taskId}</div> */}
        <div>{task.data.title || "Untitled Task"}</div>
        <div style={{ display: "flex", marginTop: "3px" }}>
          <QueryStatusTag style={{ marginLeft: 0 }}>
            {task.status}
          </QueryStatusTag>
          <div
            style={{
              opacity: 0.9,
              marginTop: 2,
              fontSize: 14,
              color: is_overdue(dueDate) ? "red" : undefined,
            }}
          >
            {dateMessage}
          </div>
          {/* <div>{(assignee) ? `${assignee.name?.first} ${assignee.name?.last}` : 'Undefined User'}</div> */}
        </div>
      </div>

      {/* Right side of the listing */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 60,
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <UserCircle userData={assignee} noUserGuarantee />
      </div>
    </TaskListEntryContainer>
  );
};

const FilterDropdownButtons = ({
  taskFilter,
  setTaskFilter,
  setSelectedTask,
}) => {
  const users = useContext(ProjectUsersContext);
  const [showFieldList, setShowFieldList] = useState(undefined);

  const statusOptions = {
    open: { label: "open" },
    closed: { label: "closed" },
  };

  const assignedToOptions = {};
  users?.forEach((user) => {
    if (user.name && user.name.first && user.name.last) {
      assignedToOptions[user.id] = {
        label: `${user.name.first} ${user.name.last}`,
      };
    } else {
      assignedToOptions[user.id] = { label: "Unnamed User" };
    }
  });

  return (
    <div style={{ position: "relative", marginRight: "-30px" }}>
      <FloatingSelectList
        setField={(value) => {
          setTaskFilter((ex) => ({ ...ex, [showFieldList?.id]: value }));
          setSelectedTask(undefined);
        }}
        show={!!showFieldList}
        onHide={() => setShowFieldList(undefined)}
        types={showFieldList?.options}
        data={
          taskFilter[showFieldList?.id]
            ? showFieldList?.options[taskFilter[showFieldList?.id]]
            : null
        }
        placeholder={`${showFieldList?.id}...`}
        position="right"
      />
      <Tooltip title="Filter by status">
        <IconButton
          size="large"
          onClick={() =>
            setShowFieldList({ id: "status", options: statusOptions })
          }
        >
          <Rule color={taskFilter.status ? "primary" : "disabled"} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Filter by assignee">
        <IconButton
          size="large"
          onClick={() =>
            setShowFieldList({ id: "assignedTo", options: assignedToOptions })
          }
        >
          <AccountCircle
            color={taskFilter.assignedTo ? "primary" : "disabled"}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export const validateTask = (task, assigneeRequired) => {
  if (!task.data.title) {
    return "A task name is required. Please try again.";
  }
  if (assigneeRequired && !task.assignedTo) {
    return "An assignee is required. Please try again.";
  }
  if (task.dueDate != null && isNaN(task.dueDate)) {
    return "Date is invalid. Please try again.";
  }
  return "";
};

export const TASK_CLOSED_STATUSES = ["closed", "cancelled"];

export const TASK_SCHEMA_DEFAULT = [
  {
    id: "title",
    name: "Title",
    type: "string",
    editableWhile: ["new", "created", "open", "approved"],
    required: {
      validations: {
        character_range: { constraint: { max: 50 } },
      },
    },
  },
  {
    id: "description",
    name: "Description",
    type: "textarea",
    editableWhile: ["new", "created", "open", "approved"],
  },
  {
    id: "attachments",
    name: "Attachments",
    type: "files",
    editableWhile: ["new", "created", "open", "approved"],
  },
];

export const TASK_SCHEMA_METADATA = [
  {
    nested: [
      {
        id: "assignedTo",
        name: "Assigned User",
        type: "userlist",
        multi: false,
        editableWhile: ["new", "created", "open", "approved"],
      },
      {
        id: "dueDate",
        name: "Due Date",
        type: "date",
        editableWhile: ["new", "created", "open", "approved"],
      },
      {
        id: "creator",
        name: "Assigned By",
        type: "userlist",
        multi: false,
        editableWhile: [],
      },
    ],
  },
];

export const TASK_SCHEMA_METADATA_GENERATED = [
  {
    nested: [
      {
        id: "assignedTo",
        name: "Assigned User",
        type: "userlist",
        multi: false,
        editableWhile: ["new", "created", "open"],
      },
      {
        id: "dueDate",
        name: "Due Date",
        type: "date",
        editableWhile: ["new", "created", "open"],
      },
      {
        id: "creator",
        name: "Assigned By",
        type: "string",
        multi: false,
        editableWhile: [],
      },
    ],
  },
];

export const TASK_SCHEMA_METADATA_CLOSED = [
  {
    id: "closedBy",
    name: "Closed By",
    type: "userlist",
    multi: false,
    editableWhile: [],
  },
];

export const TASK_SCHEMA_CLOSURE = [
  {
    id: "description",
    name: "Task Closure Description",
    type: "textarea",
    editableWhile: ["new", "created", "open", "approved"],
    required: {
      validator: {
        preset: "populated",
      },
    },
  },
  {
    id: "attachments",
    name: "Attachments",
    type: "files",
    editableWhile: ["new", "created", "open", "approved"],
  },
];

const TASK_FILTER_FIELDS = [
  {
    id: "status",
    name: "Filter by status",
    type: "multiselect",
    options: { open: "open", closed: "closed" },
  },
  {
    id: "assignedTo",
    name: "Filter by assignee",
    type: "userlist",
    multi: false,
  },
];

const TabPanelLeftBar = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  margin-top: -7px;
  margin-right: 10px;
`;

const TaskList = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const TaskListEntryContainer = styled.div`
  width: 100%;
  border-bottom: 2px solid #d0d0d0;
  padding: 4px 0;
  padding-left: 5px;
  user-select: none;
  cursor: pointer;

  display: flex;
  flex-direction: row;

  background: ${(props) => (props.selected ? "#e0e0e0" : "white")};

  &:hover {
    background-color: #d2d2d2;
    cursor: pointer;
  }
`;

const TaskDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 8px;
  margin-bottom: 10px;
  ${(props) => (props.empty ? "justify-content: center" : "")};
  ${(props) => (props.empty ? "align-items: center" : "")};
`;

const TaskDataFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  margin-left: 8px;
`;

const TaskSectionHeader = styled.div`
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-weight: 600;
`;

const SummarySection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TaskCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.button.active};
  border-radius: 30px;
  width: fit-content;
  padding: 4px 7px;
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.text};
  font-size: 0.9rem;
`;

const ChartOptions = styled.div`
  position: absolute;
  animation-name: slideIn, fadeIn;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
`;
