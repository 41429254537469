import { useState, useEffect, useCallback, useRef } from "react";
import q from "query-it";
import { APIStorage } from "query-it";
import { TEMPLATE_SCHEMA_STATUSSETS } from "../common/query";
import { log_error } from "../tools/logger";
import { usePageVisibility } from "./utils"

let cachedProjects = undefined;
export const useProjects = (user) => {
  const [projects, setProjects] = useState(cachedProjects);

  useEffect(() => {
    const date = new Date();
    if (user) {
      let mount = true;
      let queryStore = {};
      const unsub = user.projects.listen(async (dtprojects) => {
        const activeProjects = dtprojects.filter(
          (prj) => prj.launched || user.is_admin
        );

        activeProjects.forEach((prj) => {
          q.projects.project(prj.id).queries.listen((rows) => {
            queryStore[prj.id] = {
              open: rows.filter((q) => q.status === "open").length,
              overdue: rows.filter(
                (q) =>
                  q.dueDate &&
                  new Date(q.dueDate) < date &&
                  q.status !== "closed"
              ).length,
            };

            if (
              mount &&
              Object.keys(queryStore).length == activeProjects.length
            ) {
              for (
                let qiter = 0;
                qiter < Object.keys(queryStore).length;
                qiter++
              ) {
                activeProjects[qiter].open =
                  queryStore[activeProjects[qiter].id].open;
                activeProjects[qiter].overdue =
                  queryStore[activeProjects[qiter].id].overdue;
              }
              setProjects(activeProjects);
              cachedProjects = activeProjects;
            }
          });
        });

        if (activeProjects && activeProjects.length < 1) {
          setProjects([]);
        } // Default blank but set
      });
      return () => {
        unsub();
        mount = false;
      };
    }
  }, [user]);

  return projects;
};

export const useAllProjects = () => {
  const [projects, setProjects] = useState(undefined);

  useEffect(() => {
    q.projects.listen((docs) => {
      setProjects(docs);
    });
  }, []);

  const newProject = useCallback(async (name) => {
    let proj = await q.projects.add({ name: name });
    // DEFECT: This doesn't work, proj is a ref or something??
    return q.projects
      .project(proj.id)
      .collection("schemas")
      .add({ name: " - New Schema", type: "response", fields: [] });
  }, []);

  return [projects, newProject];
};

// Takes in a list of projectIDs and returns an object that maps each projectID to a list of users
export const useProjectsUsers = (projectIDs) => {
  const [projectsUsers, setProjectsUsers] = useState({});

  useEffect(() => {
    projectIDs?.forEach((id) =>
      q.projects
        .doc(id)
        .collection("users")
        .listen((docs) => {
          setProjectsUsers((ex) => ({ ...ex, [id]: docs }));
        })
    );
  }, []);

  return projectsUsers;
};

// takes in a project and returns the projects badges
export const useCurrentBadges = (project) => {
  const [badges, setBadges] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    if (project) {
      const unsub = project.defaultBadges.listen((data) => {
        if (mounted) setBadges(data ?? []);
      });
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [project]);

  return badges;
};

let cachedProjectData = undefined;
export const useProject = (id) => {
  const [project, setProject] = useState(undefined);
  const [projectData, setProjectData] = useState(cachedProjectData);

  useEffect(() => {
    if (id) {
      setProject(q.projects.project(id));
    }
    return;
  }, [id]);

  useEffect(() => {
    let mounted = true;
    if (project) {
      const unsubPr = project.listen((projectData) => {
        if (mounted) {
          setProjectData(projectData);
          cachedProjectData = projectData;
        }
      });
      return () => {
        mounted = false;
        unsubPr();
      };
    }
    return;
  }, [project]);

  return [project, projectData];
};

let cachedReports = undefined;
export const useCurrentReports = (project) => {
  const [reports, setReports] = useState(cachedReports);

  useEffect(() => {
    let mounted = true;
    if (project) {
      const unsub = project.reports.listen((rpts) => {
        if (mounted) setReports(rpts);
      });
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [project]);

  return reports;
};

let cachedSchemas = undefined;
export const useCurrentSchemas = (
  project,
  user,
  setRedirect,
  adminOverride = false
) => {
  const [schemas, setSchemas] = useState(cachedSchemas);

  useEffect(() => {
    if (project) {
      let mounted = true;
      const unsub = project.schemas.listen(
        (schms) => {
          let orgSchem = schms.sort(
            (a, b) => (a.order ? a.order : 0) - (b.order ? b.order : 0)
          );
          orgSchem = orgSchem
            .map((sch) => {
              delete sch["dynamicIdMap"];
              return sch;
            })
            .filter(
              (sch) =>
                !sch.whitelist ||
                !user ||
                adminOverride ||
                sch.whitelist.includes(user.ref.id)
            );
          if (mounted) setSchemas(orgSchem);
          cachedSchemas = orgSchem;
        },
        (e) => (setRedirect ? setRedirect(true) : null)
      );
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [project, user, setRedirect, adminOverride]);

  return schemas;
};

// Takes a projectID and returns all the schemas that the user has access to
export const useProjectSchemas = () => {
  const getProjectSchemas = async (projectID, userID) => {
    const schemas = await q.projects.doc(projectID).collection("schemas").get();
    // NOTE: Users only have access if a schema has no whitelist or the user is on the whitelist
    const filteredSchemas = schemas.filter(
      (schema) => !schema.whitelist || schema.whitelist.includes(userID)
    );
    return filteredSchemas;
  };

  return getProjectSchemas;
};

export const useSchema = (schemaId, project, schemaRevisionId = undefined) => {
  const [schema, setSchema] = useState(undefined);
  const [schemaData, setSchemaData] = useState(undefined);

  useEffect(() => {
    setSchemaData(undefined);
    if (project && schemaId && schemaRevisionId) {
      setSchema(
        project.schemas.schema(schemaId).revisions.revision(schemaRevisionId)
      );
    } else if (project && schemaId) {
      setSchema(project.schemas.schema(schemaId));
    }
  }, [project, schemaId, schemaRevisionId]);

  useEffect(() => {
    let schemaMount = true;
    if (schema && schemaRevisionId) {
      schema.listen((data) => {
        if (schemaMount) setSchemaData(data.schema);
      });
    } else if (schema) {
      schema.listen((data) => {
        if (schemaMount) setSchemaData(data);
      });
    }
    return () => {
      schemaMount = false;
    };
  }, [schema, schemaRevisionId]);

  return [schema, schemaData];
};

export const useSchemaCustomizations = (project, schemaRef, userId) => {
  const [customizations, setCustomizations] = useState(undefined);

  useEffect(() => {
    setCustomizations(undefined);
    if (project && schemaRef && userId) {
      let mounted = true;
      const mainSchemaRef =
        schemaRef.ref.parent.id === "revisions"
          ? project.schemas.schema(schemaRef.ref.parent.parent.id)
          : schemaRef;

      const unsub = mainSchemaRef.users.user(userId).listen((data) => {
        if (mounted) setCustomizations(data);
      });
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [project, schemaRef, userId]);

  return customizations;
};

export const useAggregateData = (project, schemaRef) => {
  const [aggregateData, setAggregateData] = useState(undefined);

  useEffect(() => {
    if (project && schemaRef) {
      let mounted = true;
      const unsub = schemaRef
        .collection("aggregateData")
        .doc("truth")
        .listen((data) => {
          if (mounted) setAggregateData(data);
        });
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [project, schemaRef]);

  return aggregateData;
};

export const useCurrentUsers = (project) => {
  const [users, setUsers] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    if (project) {
      const unsub = project.users.listen((data) => {
        if (mounted) setUsers((data ?? []).filter((usr) => !usr?.hidden));
      });
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [project]);

  return users;
};

export const usePopups = (project) => {
  const [popups, setPopups] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    if (project) {
      const unsub = project.popups.listen((data) => {
        if (mounted)
          setPopups(
            (data ?? []).map((popup) => {
              return {
                ...popup,
                openRate: `${Math.floor(
                  (100 * popup.opened) / popup.received
                )}%`,
              };
            })
          );
      });
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [project]);

  return popups;
};

export const usePatchNotes = () => {
  const [patchNotes, setPatchNotes] = useState(undefined);

  useEffect(() => {
    q.patchnotes.get().then((data) => {
      setPatchNotes(data);
    });
  }, []);

  return patchNotes;
};

export const useRemoveProjectUser = () => {
  const removeProjectUser = async (projectID, userID) => {
    try {
      const projectRef = q.projects.doc(projectID);
      const projectData = await projectRef.get();
      const newProjectUsers = projectData.users.filter(
        (user) => user !== userID
      );
      await projectRef.update({ users: newProjectUsers });
      await projectRef.collection("users").doc(userID).delete();
    } catch (error) {
      log_error("Remove User from project failed");
    }
  };

  return removeProjectUser;
};

let cachedActions = undefined;
export const useCurrentActions = (project) => {
  const [actions, setActions] = useState(cachedActions);

  useEffect(() => {
    if (project) {
      const unsubAct = project.actions.listen((actions) => {
        cachedActions = actions;
        setActions(cachedActions);
      });
      return () => unsubAct();
    }
    return;
  }, [project]);

  return actions;
};

export const useConcurrents = (project, queryId, userId) => {
  // Would be nice not to do a state update every time this changes
  const isVisible = usePageVisibility();
  const [concurrent, setConcurrent] = useState(undefined);
  const [start, setStart] = useState(undefined);
  const [windowClose, setWindowClose] = useState(false);

  const handleBeforeUnload = (e) => {
    setWindowClose(true);
    if (concurrent === userId && project && queryId) {
      project.concurrents.concurrent(queryId).delete();
    }
  };

  useEffect(() => {
    let mounted = true;
    if (project && queryId) {
      const unsub = project.concurrents.concurrent(queryId).listen((conc) => {
        if (mounted && !windowClose)
          setConcurrent(Object.values(conc).length > 0 ? conc.by : null);
          setStart(Object.values(conc).length > 0 ? conc.start : null);
      });
      return () => {
        mounted = false;
        unsub();
      };
    }

    return () => {
      mounted = false;
    };
  }, [project, queryId, windowClose]);

  // On open, let's hold the readonly if it's not being held
  useEffect(() => {
    // listen for any window unload events (close window)
    window.addEventListener("beforeunload", handleBeforeUnload);
    
    const pastTimeLimit = start && Date.now() - start > THIRTY_MINUTES;

    // unclaim query when app is not visible to user
    if (!isVisible && concurrent === userId && project && queryId) {
      project.concurrents.concurrent(queryId).delete();
    }

    // if query is unclaimed or claim is past the time limit, give new user control
    if ((concurrent === null || pastTimeLimit) && isVisible) {
      project.concurrents.concurrent(queryId).set({ by: userId, start: Date.now() });
    }

    // taken hold of the query, and need to return a handler to let it and unload listener go on exit
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);

      if (concurrent === userId && project && queryId) {
        project.concurrents.concurrent(queryId).delete();
      }
    };
  }, [queryId, project, userId, concurrent, start, isVisible]);

  // // Check readonly
  // useEffect(() => {
  //   setreadonly(concurrents.some(qr => qr.id === (query && query.ref.id) && qr.by !== userData.id));
  // }, [concurrents, userData, query]);

  return concurrent;
};

export const useProjectLogo = (projectData) => {
  const [logo, setLogo] = useState(undefined);

  const mountedProject = useRef(undefined);

  useEffect(() => {
    mountedProject.current = projectData?.id;
    if (projectData) {
      let projLogo = projectData.logo;
      if (projLogo && projLogo.includes("/")) {
        // To be removed in the future
        setLogo(projLogo);
      } else if (projLogo) {
        APIStorage.getDownloadURL(
          q
            .getProjectStorage(projectData.id)
            .ref(`${projectData.id}/${projLogo}`)
        ).then((url) => {
          if (mountedProject.current === projectData.id) setLogo(url);
        });
      } else {
        setLogo(null);
      }
    }
  }, [projectData]);

  return logo;
};

export const useSchemaStatusSet = (schemaData) => {
  const [statusSet, setStatusSet] = useState([]);

  useEffect(() => {
    if (!schemaData) {
      setStatusSet([]);
      return;
    }

    // First check custom
    if (!schemaData.customStatusSet && schemaData.type === "custom") {
      setStatusSet([]);
      return;
    }

    // Now let's get the statusset object
    let statuses =
      schemaData.type === "custom"
        ? schemaData.customStatusSet
        : TEMPLATE_SCHEMA_STATUSSETS[schemaData.type];

    // And we can use that in all other cases, just taking the values :)
    setStatusSet(Object.values(statuses));
  }, [schemaData]);

  return statusSet;
};

const THIRTY_MINUTES = 30 * 60 * 1000; // 30 minutes in milliseconds