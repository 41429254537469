import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import { materialThemeContrast } from "../../../themes/mui_theme";
import { SectionTitle } from "../index";
import { Button2 as CustomButton } from "../../ui/buttons";
import VacationModal from "./vacation";
import { ConfirmationModal } from "../../ui/modals";
import { parse_db_timestamp } from "../../../tools";

// Hooks
import { useProjects } from "../../../hooks/projects";
import { useHotkey } from "../../../hooks/system";

export default ({ user, userData }) => {
  return <VacationCard user={user} userData={userData} />;
};

export const VacationCard = ({ user, userData, admin }) => {
  const [ret, setRet] = useState(false);
  const [vacationPeriod, setVacationPeriod] = useState(undefined);
  const [vacationModalOpen, setVacationModalOpen] = useState(false);
  const [confirmEndOpen, setConfirmEndOpen] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const projects = useProjects(user);

  // Functions for opening/closing the set vacation modal
  const openVacation = () => {
    setShowSaveConfirmation(false);
    setVacationModalOpen(true);
  };
  const closeVacation = (showSave) => {
    setVacationModalOpen(false);
    if (showSave) {
      setShowSaveConfirmation(true);
    }
  };

  // Functions for opening/closing the modal that confirms if you want to end vacation
  const openEnd = () => setConfirmEndOpen(true);
  const closeEnd = () => setConfirmEndOpen(false);

  const theme = useContext(ThemeContext);

  const endVacation = () => {
    user.update({ vacation: null });
    // Should be a Firebase Update instead
    closeEnd();
    setShowSaveConfirmation(false);
  };

  useHotkey("Escape", () =>
    vacationModalOpen
      ? closeVacation()
      : confirmEndOpen
      ? closeEnd()
      : setRet(true)
  );

  // Check userData.vacation, lets us know if the user is already set to be on vacation
  // userData.vacation should have date information, setVacationPeriod accordingly
  useEffect(() => {
    setVacationPeriod(
      userData?.vacation
        ? {
            startDate: parse_db_timestamp(userData.vacation.start),
            endDate: parse_db_timestamp(userData.vacation.end),
          }
        : undefined
    );
  }, [userData]);

  if (ret) {
    return <Navigate to="/app/projects" />;
  }

  return (
    <MuiThemeProvider theme={materialThemeContrast(theme)}>
      {vacationModalOpen && (
        <VacationModal
          user={user}
          onClose={closeVacation}
          vacationPeriod={vacationPeriod}
          userProjects={projects}
          userData={userData}
          admin={admin}
        />
      )}
      <ConfirmationModal
        open={confirmEndOpen}
        onCancel={closeEnd}
        onConfirm={endVacation}
        body={`Are you sure you would like to end ${
          admin ? (userData?.name?.first ?? "User") + "'s" : "your"
        } vacation? Doing so will also undo all of ${
          admin ? "their" : "your"
        } responsibility re-assignments`}
      />
      {!admin && (
        <SectionTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "8px",
            marginTop: "8px",
          }}
        >
          Vacation
          {showSaveConfirmation && (
            <div style={{ color: "#11c211" }}>Saved!</div>
          )}
        </SectionTitle>
      )}
      <VacationCardContainer
        admin={admin}
        show={
          vacationPeriod && vacationPeriod.startDate && vacationPeriod.endDate
        }
      >
        {vacationPeriod &&
          vacationPeriod.startDate &&
          vacationPeriod.endDate && (
            <>
              <VacationCardText admin={admin}>{`${
                admin ? (userData?.name?.first ?? "User") + "'s" : "Your"
              } Vacation Starts On: `}</VacationCardText>
              <VacationCardText admin={admin}>
                <b>
                  {vacationPeriod?.startDate && vacationPeriod.startDate.seconds
                    ? new Date(
                        vacationPeriod?.startDate.seconds * 1000
                      ).toDateString()
                    : vacationPeriod.startDate.toDateString()}
                </b>
              </VacationCardText>
              <VacationCardText admin={admin}>{`${
                admin ? (userData?.name?.first ?? "User") + "'s" : "Your"
              } Vacation Ends On: `}</VacationCardText>
              <VacationCardText admin={admin}>
                <b>
                  {vacationPeriod?.endDate && vacationPeriod.endDate.seconds
                    ? new Date(
                        vacationPeriod?.endDate.seconds * 1000
                      ).toDateString()
                    : vacationPeriod.endDate.toDateString()}
                </b>
              </VacationCardText>
            </>
          )}
        <ButtonContainer>
          <CustomButton
            label={
              vacationPeriod &&
              vacationPeriod.startDate &&
              vacationPeriod.endDate
                ? "Edit Vacation"
                : "Add Vacation"
            }
            onClick={openVacation}
          />
          {vacationPeriod &&
            vacationPeriod.startDate &&
            vacationPeriod.endDate && (
              <CustomButton
                style={{ marginLeft: "5px" }}
                label={
                  vacationPeriod.startDate > Date.now()
                    ? "Cancel Vacation"
                    : "End Vacation Now"
                }
                onClick={openEnd}
              />
            )}
        </ButtonContainer>
      </VacationCardContainer>
    </MuiThemeProvider>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const VacationCardContainer = styled.div`
  background: ${(props) =>
    props.show
      ? props.admin
        ? "#e4e4e4"
        : props.theme.step50
      : props.theme.default};
  padding: 20px;
  border-radius: 5px;

  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => (props.show ? "center" : "flex-start")};
  gap: 10px;

  flex-direction: column;
  overflow: hidden;
`;

const VacationCardText = styled.p`
  color: ${(props) => (props.admin ? "black" : "white")};
  margin-top: 0px;
`;
