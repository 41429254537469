import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Tabs, Tab } from "@mui/material";
import { isMobile } from "react-device-detect";

import { QueryContent, QueryStatusContext } from "../query";

import QuerySettings from "./settings";
import Approvals from "./approvals2";
import Responses from "./responses";
import Tasks from "./tasks";

import { UserDataContext } from "../../../../App";
import { QueryStatus } from "../../../../hooks/status";

export const ACTIONVIEWS = {
  APPROVAL: "approval",
  TASK: "task",
  // ASSIGNMENT: "assignment",
  RESPONSE: "response",
  QUERY: "query",
};

export const TabContext = React.createContext(undefined);

// DEFECT: This page re-renders far too often...
export default ({
  query,
  queryData,
  readonly,
  schemaData,
  setQueryData,
  isNew,
  setStatusMessage,
  newQueryRef,
}) => {
  const userData = useContext(UserDataContext);
  const queryStatus = useContext(QueryStatusContext);

  const [tabsToRender, setTabsToRender] = useState(undefined);

  const [searchParams, setSearchParams] = useSearchParams();
  const actionRoute = searchParams.get("ar");
  const updateActionRoute = (e, v) => {
    setSearchParams((ex) => {
      ex.set("ar", v);
      return ex;
    });
  };

  // Pre-determine which tabs will render
  useEffect(() => {
    let tbs = [];
    if (queryStatus?.has_approvals()) {
      tbs.push(ACTIONVIEWS.APPROVAL);
    }

    if (
      !isNew &&
      ["response"].includes(schemaData.type) &&
      queryData.status !== "created"
    ) {
      tbs.push(ACTIONVIEWS.RESPONSE);
    }
    if (
      schemaData.settings?.enableTasks &&
      !isMobile &&
      (!schemaData.settings?.tasksAvailableStatus ||
        queryStatus.status_has_passed(
          schemaData.settings?.tasksAvailableStatus
        ))
    ) {
      tbs.push(ACTIONVIEWS.TASK);
    }

    setTabsToRender(tbs);
  }, [schemaData, isNew, queryStatus]);

  return (
    <QueryContent
      style={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        margin: 0,
      }}
    >
      <TabContext.Provider value={actionRoute}>
        {/* Top browser for enabled action types */}
        <Tabs value={actionRoute} onChange={updateActionRoute}>
          {/* {tabsToRender.includes(ACTIONVIEWS.ASSIGNMENT) && <Tab label="Assignments" value={ACTIONVIEWS.ASSIGNMENT} />} */}
          {tabsToRender?.includes(ACTIONVIEWS.APPROVAL) && (
            <Tab label="Approvals" value={ACTIONVIEWS.APPROVAL} />
          )}
          {tabsToRender?.includes(ACTIONVIEWS.RESPONSE) && (
            <Tab label="Response" value={ACTIONVIEWS.RESPONSE} />
          )}
          {tabsToRender?.includes(ACTIONVIEWS.TASK) && (
            <Tab label="Tasks" value={ACTIONVIEWS.TASK} />
          )}
          {/* The tabs below are there as temporary tabs to prevent console errors */}
          {!tabsToRender && (
            <Tab label="Approvals" value={ACTIONVIEWS.APPROVAL} />
          )}
          {!tabsToRender && (
            <Tab label="Response" value={ACTIONVIEWS.RESPONSE} />
          )}
          {!tabsToRender && <Tab label="Tasks" value={ACTIONVIEWS.TASK} />}

          {/* Always display this one, and always last :) */}
          <Tab label="Query Settings" value={ACTIONVIEWS.QUERY} />
        </Tabs>
        {/* The panels display the relevant information for different types of actions */}
        {tabsToRender?.includes(ACTIONVIEWS.APPROVAL) && (
          <TabPanel value={ACTIONVIEWS.APPROVAL}>
            <Approvals
              queryData={queryData}
              userData={userData}
              setQueryData={setQueryData}
              schemaData={schemaData}
              query={query}
              readonly={readonly}
            />
          </TabPanel>
        )}
        {/* {tabsToRender.includes(ACTIONVIEWS.ASSIGNMENT) && <TabPanel value={ACTIONVIEWS.ASSIGNMENT}>
        wachow!
      </TabPanel>} */}
        {tabsToRender?.includes(ACTIONVIEWS.RESPONSE) && (
          <TabPanel value={ACTIONVIEWS.RESPONSE}>
            <Responses
              query={query}
              queryData={queryData}
              responseSchema={schemaData.responseFields}
              response={queryData.response.data}
              userData={userData}
              setResponse={(ind, val) =>
                setQueryData((ex) => ({
                  ...ex,
                  response: {
                    ...(ex.response ? ex.response : {}),
                    userId:
                      ex.response && ex.response.userId
                        ? ex.response.userId
                        : userData.id,
                    createTime:
                      ex.response && ex.response.createTime
                        ? ex.response.createTime
                        : new Date(),
                    data: {
                      ...(ex.response && ex.response.data
                        ? ex.response.data
                        : {}),
                      [ind]: val,
                    },
                  },
                }))
              }
            />
          </TabPanel>
        )}
        {tabsToRender?.includes(ACTIONVIEWS.TASK) && (
          <TabPanel value={ACTIONVIEWS.TASK}>
            <Tasks
              query={query}
              schemaData={schemaData}
              queryData={queryData}
              setStatusMessage={setStatusMessage}
              newQueryRef={newQueryRef}
            />
          </TabPanel>
        )}
        {/* And then always display this one */}
        <TabPanel value={ACTIONVIEWS.QUERY}>
          <QuerySettings
            schemaData={schemaData}
            queryData={queryData}
            setQueryData={setQueryData}
            readonly={readonly}
          />
        </TabPanel>
      </TabContext.Provider>
    </QueryContent>
  );
};

export const TabPanel = ({ value, ...props }) => {
  const current = useContext(TabContext);

  return <TabPanelDiv hidden={value !== current}>{props.children}</TabPanelDiv>;
};

const TabPanelDiv = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex: 4 0 calc(100% - 48px);
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
`;

export const TabPanelContainer = styled.div`
  display: flex;
  min-width: calc(100% - 10px);
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  flex-grow: 1;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;
