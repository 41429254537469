import React, { useState } from "react";
import { Button2, IconButtonText } from "../../../../ui/buttons";
import { SlimTextField } from "../../../../ui/inputs2";

import {
  Box,
  Tooltip,
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import {
  Info,
  Edit,
  Check,
  Close,
  Add,
  Delete,
  AltRoute,
  Loop,
} from "@mui/icons-material";
import { UsersSelectInput } from "../../fields/queryfields";

export default ({
  approvalsRequired,
  setApprovalsRequired,
  approvalStagesAddable,
}) => {
  //handle Add Approval Stage
  const addApprovalStage = () => {
    setApprovalsRequired((prev) => [
      ...prev,
      {
        approvers: [],
        stageLabel: "Approval",
        targetStatus: "open",
      },
    ]);
  };

  //handle Delete Approval Stage
  const deleteApprovalStage = (deleteIndex) => {
    setApprovalsRequired((prev) => {
      const updatedApprovals = prev.filter(
        (val, index) => index !== deleteIndex
      );
      return updatedApprovals;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {approvalStagesAddable && (
          <Button2
            label="Add Approval Stage"
            onClick={addApprovalStage}
            size="small"
            sx={{ marginRight: "10px" }}
          />
        )}
        {/* <Tooltip title={APPROVALS_HELP_TEXT}>
          <Info sx={{ marginRight: "10px" }} />
        </Tooltip> */}
      </Box>
      {approvalsRequired?.map?.((approval, ind) => (
        <ApprovalStageCard
          approval={approval}
          setApproval={(callback) => {
            setApprovalsRequired((prev) => [
              ...prev.slice(0, ind),
              callback(prev[ind]),
              ...prev.slice(ind + 1),
            ]);
          }}
          key={ind}
          index={ind}
          deleteApprovalStage={() => deleteApprovalStage(ind)}
          approvalStagesAddable={approvalStagesAddable}
        />
      ))}
      <Box sx={{ height: "50px", width: "100%" }} />
    </Box>
  );
};

const ApprovalStageCard = ({
  approval,
  setApproval,
  deleteApprovalStage,
  index,
  approvalStagesAddable,
}) => {
  const [showEditButton, setShowEditButton] = useState(false);
  const [editingLabel, setEditingLabel] = useState(false);
  const [newLabel, setNewLabel] = useState(approval?.stageLabel ?? "");

  const addApprover = () => {
    setApproval((prev) => ({
      ...prev,
      approvers: [
        ...prev.approvers,
        {
          main: "",
          alternates: [],
        },
      ],
    }));
  };

  const saveLabel = () => {
    // make sure newLabel is not empty
    if (newLabel) {
      setApproval((prev) => ({
        ...prev,
        stageLabel: newLabel,
      }));
      setEditingLabel(false);
      setShowEditButton(false);
    }
  };

  return (
    <Card variant="outlined" sx={{ display: "flex" }}>
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{ display: "flex" }}
            onMouseEnter={() => setShowEditButton(true)}
            onMouseLeave={() => setShowEditButton(false)}
          >
            <Typography
              sx={{ fontSize: "16px", display: "flex", alignItems: "center" }}
              component="div"
            >
              Approval Stage:&nbsp;
              {editingLabel ? (
                <SlimTextField
                  variant="outlined"
                  value={newLabel}
                  onChange={(e) => setNewLabel(e.target.value)}
                  size="small"
                />
              ) : (
                approval?.stageLabel ?? ""
              )}
            </Typography>
            {editingLabel ? (
              <>
                <IconButton
                  sx={{ padding: 0, marginLeft: "5px" }}
                  onClick={saveLabel}
                >
                  <Check fontSize="small" />
                </IconButton>
                <IconButton
                  sx={{ padding: 0, marginLeft: "5px" }}
                  onClick={() => {
                    setEditingLabel(false);
                    setShowEditButton(false);
                    setNewLabel(approval?.stageLabel ?? "");
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              </>
            ) : (
              showEditButton && (
                <>
                  <Tooltip title="Edit Title">
                    <IconButton
                      sx={{ padding: 0, marginLeft: "5px" }}
                      onClick={() => setEditingLabel(true)}
                    >
                      <Edit sx={{ fontSize: "18px" }} />
                    </IconButton>
                  </Tooltip>
                  {approvalStagesAddable && (
                    <Tooltip title="Delete Approval Stage">
                      <IconButton
                        sx={{ padding: 0, marginLeft: "5px" }}
                        onClick={() => deleteApprovalStage(index)}
                      >
                        <Delete sx={{ fontSize: "18px" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )
            )}
          </Box>
          {/* <Box>
            <Button2 label="Add Approver" size="small" onClick={addApprover} />
          </Box> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            marginTop: "10px",
          }}
        >
          {approval?.approvers?.map((approver, ind) => (
            <ApproverCard
              key={ind}
              approver={approver}
              setApprover={(callback) => {
                setApproval((prev) => ({
                  ...prev,
                  approvers: [
                    ...prev.approvers.slice(0, ind),
                    callback(prev.approvers[ind]),
                    ...prev.approvers.slice(ind + 1),
                  ],
                }));
              }}
              deleteApprover={() => {
                setApproval((prev) => ({
                  ...prev,
                  approvers: [
                    ...prev.approvers.slice(0, ind),
                    ...prev.approvers.slice(ind + 1),
                  ],
                }));
              }}
            />
          ))}
          <Card
            variant="outlined"
            sx={{ width: "100px", height: "100px", borderStyle: "dashed" }}
          >
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "calc(100% - 40px)",
                cursor: "pointer",
              }}
              onClick={addApprover}
            >
              <Add sx={{ color: "grey", fontSize: "40px" }} />
              <Typography
                sx={{
                  color: "grey",
                  fontSize: "12px",
                  textAlign: "center",
                  userSelect: "none",
                }}
              >
                Add Approver
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </CardContent>
    </Card>
  );
};

const ApproverCard = ({ approver, setApprover, deleteApprover }) => {
  const [showingAlternates, setShowingAlternates] = useState(
    approver?.alternates?.length > 0
  );

  const setMainApprover = (e) => {
    const { value } = e.target;
    setApprover((prev) => ({
      ...prev,
      alternates: prev?.alternates?.filter((v) => v !== value) ?? [],
      main: value,
    }));
  };

  const setAlternateApprovers = (e) => {
    const { value } = e.target;
    setApprover((prev) => ({
      ...prev,
      alternates: value?.filter((v) => v !== prev?.main) ?? [],
    }));
  };

  return (
    <Card
      variant="outlined"
      sx={{ width: "200px", minHeight: "100px", height: "fit-content" }}
    >
      <UsersSelectInput
        data={approver?.main ? approver.main : ""}
        onChange={setMainApprover}
        label="Approver"
        style={{ width: "90%", padding: 0, marginTop: 0 }}
        editable={true}
      />
      {showingAlternates && (
        <UsersSelectInput
          data={approver?.alternates ? approver.alternates : []}
          onChange={setAlternateApprovers}
          label="Alternates"
          style={{ width: "90%", padding: 0, marginTop: 0 }}
          editable={true}
          multi
        />
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <Tooltip title="Add Alternates">
          <IconButton onClick={() => setShowingAlternates(true)}>
            <AltRoute />
          </IconButton>
        </Tooltip>
        <Tooltip title="Swap Approvers">
          <span>
            <IconButton
              disabled={(approver?.alternates?.length ?? 0) < 1}
              onClick={() => {
                setApprover((prev) => ({
                  ...prev,
                  main: prev?.alternates[0],
                  alternates: [prev?.main, ...prev?.alternates?.slice(1)],
                }));
              }}
            >
              <Loop
                color={
                  (approver?.alternates?.length ?? 0) < 1
                    ? "disabled"
                    : "primary"
                }
              />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Remove">
          <IconButton onClick={deleteApprover}>
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    </Card>
  );
};

// const APPROVALS_HELP_TEXT = `Approvals create an accountable workflow for your chain of command where designated approvers are required to sign off on
// queries before they may be closed. These approvals often carry a due date and can include other information or stages of review where necessary.

// Approval "stages" are carried out one at a time and must be completed in order. All approvals at the same stage will be assigned at the same time for completion.
// One rejection in any stage will halt a query from being closed with approval.

// Approvals always have a minimum of one approver. Additional "alternate" approvers can be added to ensure that a query is not left hanging if an approver is unavailable.
// According to the system, the primary approver is always responsible for the closer of the approval, though their alternate approvers may
// close it on their behalf at any time.`;
