import React, { useState } from "react";
import styled from "@emotion/styled";

import {
  IconButton,
  Tooltip,
  Button,
  Modal,
  DialogContent,
} from "@mui/material";
import { ExitToApp, HelpOutline } from "@mui/icons-material";
import { log_error } from "../../../tools/logger";
import { LogoNoNav } from "../../ui/logos";
import { QueryStatusTag } from "../../project/query/query";
import { ModalPrompt, ModalContent, ModalFooter } from "../../ui/containers";

import q from "query-it";
import { useNavigate } from "react-router-dom";

const confirmationModalDefaults = {
  open: false,
  title: undefined,
  message: undefined,
  confirm: () => {},
};

export default ({ formData }) => {
  const navigate = useNavigate();

  const [confirmationModal, setConfirmationModal] = useState({
    ...confirmationModalDefaults,
  }); // add message to activate

  return (
    <HeaderContainer>
      <HeaderPartitionContainer>
        <LogoNoNav />
        <WelcomeText>Welcome, {formData?.user?.name}</WelcomeText>
      </HeaderPartitionContainer>
      <HeaderPartitionContainer
        style={{ flexDirection: "column", justifyContent: "space-evenly" }}
      >
        <TitleText>{formData?.title} Form</TitleText>
        <QueryStatusTag>{formData?.status}</QueryStatusTag>
      </HeaderPartitionContainer>
      <HeaderPartitionContainer style={{ justifyContent: "flex-end" }}>
        <Tooltip title="Help">
          <IconButton onClick={() => log_error("Unimplemented")}>
            <HelpOutline fontSize="large" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Exit">
          <IconButton
            onClick={() =>
              setConfirmationModal({
                open: true,
                title: "Confirm Logout",
                message:
                  "You are about to logout. Are you sure you'd like to proceed?",
                confirm: () => {
                  q.auth.signOut();
                  navigate("/"); // Return them to our website
                },
              })
            }
          >
            <ExitToApp fontSize="large" />
          </IconButton>
        </Tooltip>
      </HeaderPartitionContainer>
      <Modal
        open={confirmationModal.open}
        onClose={() => {
          setConfirmationModal({ ...confirmationModalDefaults });
        }}
      >
        <DialogContent>
          <ModalPrompt>
            <ModalContent>
              <h2>{confirmationModal.title}</h2>
              <p>{confirmationModal.message}</p>
            </ModalContent>
            <ModalFooter>
              <Button
                onClick={() => {
                  setConfirmationModal({ ...confirmationModalDefaults });
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  confirmationModal.confirm();
                  setConfirmationModal({ ...confirmationModalDefaults });
                }}
              >
                OK
              </Button>
            </ModalFooter>
          </ModalPrompt>
        </DialogContent>
      </Modal>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  height: 60px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background: ${(props) => props.theme.step50};
  color: ${(props) => props.theme.palette.text.primary};
  font-family: ${(props) => props.theme.palette.font};
`;

const HeaderPartitionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  max-width: 30%;

  align-items: center;
`;

const WelcomeText = styled.div`
  margin-left: 10px;
  font-size: 20px;
`;

const TitleText = styled.div`
  font-size: 22px;
`;
