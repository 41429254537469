import React, { useState, useContext, useCallback, useMemo } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { isMobile } from "react-device-detect";
import { IconButton, Box, Typography } from "@mui/material";
import { Button2 } from "../../../ui/buttons";
import {
  FormatBold,
  FormatUnderlined,
  Code,
  LooksOne,
  LooksTwo,
  FormatQuote,
  FormatListNumbered,
  FormatAlignLeft,
  FormatListBulleted,
  FormatAlignCenter,
  FormatAlignRight,
  FormatAlignJustify,
  FormatItalic,
  Add,
  Close,
} from "@mui/icons-material";

import { UserCircle } from "../../../ui/decorations";

import Ebound from "../../../ui/errorbounds";
import { UserDataContext } from "../../../../App";

import { useComments, useTaskFeed } from "../../../../hooks/queries";
import { parse_db_timestamp } from "../../../../tools";

// Import the Slate components and React plugin.
import { Slate, Editable, withReact } from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from "slate";
import { withHistory } from "slate-history";

const CommentingSidebar = ({ query, task = false, taskData, onClose }) => {
  const userData = useContext(UserDataContext);
  const comments = useComments(query);
  const taskFeed = useTaskFeed(task ? query : undefined, taskData?.id);

  const [newComment, setNewComment] = useState(initialValue);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  // TODO: Maybe use reverse flex instead? o.o
  const scrollToBottom = (id) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element.scrollTop = element.scrollHeight;
    }, 100);
  };

  const isCommentBlank = () => {
    return !newComment.some((comment) => {
      return comment.children.some((child) => {
        return !Object.hasOwn(child, "text") || child.text.trim().length !== 0;
      });
    });
  };

  const sendComment = (event) => {
    const baseComment = {
      user: {
        id: userData.id,
        name: {
          first: userData.name?.first,
          last: userData.name?.last,
        },
      },
      message: newComment,
      sent: new Date(),
    };

    if (task) {
      const tk = query.tasks.task(taskData.id);
      tk.feed.add({
        ...baseComment,
        type: "comment",
        timestamp: baseComment.sent,
      });
    } else {
      query.comments.add(baseComment);
    }
    event.preventDefault();
    // clears the old comment after it is sent
    Transforms.select(editor, Editor.start(editor, []));
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    });
    scrollToBottom("comment-scroller");
    clearAllFormatting(editor);
  };

  return (
    <CommentDiv>
      <Ebound area="Comments" userId={userData.id}>
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <IconButton size="small" onClick={onClose}>
              <Close />
            </IconButton>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                margin: "10px",
              }}
            >
              {task ? "Task Activity Feed" : "Comments"}
            </Typography>
          </div>
        </Box>
        <CommentScroller id="comment-scroller">
          {task
            ? taskFeed
                ?.sort((a, b) => b.timestamp - a.timestamp)
                .map((fd) => {
                  if (fd.type === "comment") {
                    return <Comment commentData={fd} key={`fd-${fd.id}`} />;
                  } else {
                    fd.message = [
                      {
                        type: "paragraph",
                        children: [{ text: fd.message }],
                      },
                    ];
                    fd.sent = fd.timestamp;
                    return (
                      <Comment
                        autoGenerated
                        commentData={fd}
                        key={`fd-${fd.id}`}
                      />
                    );
                  }
                })
            : comments
                ?.sort((a, b) => b.sent - a.sent)
                .map((comment) => {
                  return (
                    <Comment
                      commentData={comment}
                      key={`comment-${comment.id}`}
                    />
                  );
                })}
        </CommentScroller>
        <EditorInteractablesSection editor={editor} />
        <NewCommentDiv>
          <SlateDiv>
            <Slate
              editor={editor}
              value={newComment}
              onChange={(value) => {
                setNewComment(value);
              }}
            >
              <Editable
                placeholder="Comment"
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                spellCheck
                autoFocus
                onKeyDown={(event) => {
                  if (
                    (event.keyCode == 10 || event.keyCode == 13) &&
                    event.ctrlKey &&
                    !isCommentBlank()
                  ) {
                    sendComment(event);
                    return;
                  }
                  for (const hotkey in HOTKEYS) {
                    // NOTE: We were using a module here which was really cringe,
                    // But this solution is a non-lib equally cringe solution. Needs love <3
                    // NOTE: if you give this love, make sure admin popup editor is also changed, since it uses this implementation
                    const keys = hotkey.split("+");
                    const key = keys[keys.length - 1];
                    let mod = false;
                    if (keys.length == 2) {
                      // That means we're looking for a hotkey plus mod
                      mod = true;
                    }
                    // Then check if this hotkey is pressed
                    if (
                      event.key == key &&
                      (event.ctrlKey || event.metaKey) == mod
                    ) {
                      event.preventDefault();
                      const mark = HOTKEYS[hotkey];
                      toggleMark(editor, mark);
                    }
                  }
                }}
              />
            </Slate>
          </SlateDiv>
          <CommentButtonDiv>
            <Button2
              label="Comment"
              onClick={(event) => {
                if (!isCommentBlank()) sendComment(event);
              }}
              disabled={isCommentBlank()}
            />
          </CommentButtonDiv>
        </NewCommentDiv>
      </Ebound>
    </CommentDiv>
  );
};

const Comment = ({ autoGenerated = false, commentData }) => {
  const commentDateTime = parse_db_timestamp(commentData.sent);

  // Build renderers for slateJS
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);

  // If the comment isn't of the new format, just omit
  if (!Array.isArray(commentData.message)) {
    return;
  }

  return (
    <CommentContainer>
      <CommentUserCoinContainer>
        <UserCircle userData={commentData.user} noUserGuarantee />
      </CommentUserCoinContainer>
      <CommentTile autoGenerated={autoGenerated}>
        <CommentDate autoGenerated={autoGenerated}>
          {commentData.sent &&
            commentDateTime &&
            format(commentDateTime, "H:mm 'on' dd/M/yyyy")}
        </CommentDate>
        <Slate editor={editor} value={commentData.message}>
          <Editable
            readOnly
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck
            autoFocus
          />
        </Slate>
      </CommentTile>
    </CommentContainer>
  );
};

export const EditorInteractablesSection = ({ editor }) => (
  <ButtonContainer>
    <IconButton size="small" onClick={() => toggleMark(editor, "bold")}>
      <FormatBold />
    </IconButton>
    <IconButton size="small" onClick={() => toggleMark(editor, "italic")}>
      <FormatItalic />
    </IconButton>
    <IconButton size="small" onClick={() => toggleMark(editor, "underline")}>
      <FormatUnderlined />
    </IconButton>
    <IconButton size="small" onClick={() => toggleMark(editor, "code")}>
      <Code />
    </IconButton>
    <IconButton size="small" onClick={() => toggleBlock(editor, "heading-one")}>
      <LooksOne />
    </IconButton>
    <IconButton size="small" onClick={() => toggleBlock(editor, "heading-two")}>
      <LooksTwo />
    </IconButton>
    <IconButton size="small" onClick={() => insertLineBreak(editor)}>
      <Add />
    </IconButton>
    <IconButton size="small" onClick={() => toggleBlock(editor, "block-quote")}>
      <FormatQuote />
    </IconButton>
    <IconButton
      size="small"
      onClick={() => toggleBlock(editor, "numbered-list")}
    >
      <FormatListNumbered />
    </IconButton>
    <IconButton
      size="small"
      onClick={() => toggleBlock(editor, "bulleted-list")}
    >
      <FormatListBulleted />
    </IconButton>
    <IconButton size="small" onClick={() => toggleBlock(editor, "left")}>
      <FormatAlignLeft />
    </IconButton>
    <IconButton size="small" onClick={() => toggleBlock(editor, "center")}>
      <FormatAlignCenter />
    </IconButton>
    <IconButton size="small" onClick={() => toggleBlock(editor, "right")}>
      <FormatAlignRight />
    </IconButton>
    <IconButton size="small" onClick={() => toggleBlock(editor, "justify")}>
      <FormatAlignJustify />
    </IconButton>
  </ButtonContainer>
);

export default CommentingSidebar;

export const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const MARK_TYPES = ["bold", "italic, underline, code"];
const BLOCK_TYPES = [
  "heading-one",
  "heading-two",
  "block-quote",
  "numbered-list",
  "bulleted-list",
  "left",
  "center",
  "right",
  "justify",
];
const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const insertLineBreak = (editor) => {
  const newLine = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];

  Transforms.insertNodes(editor, newLine, { at: [editor.children.length] });
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const clearAllFormatting = (editor) => {
  MARK_TYPES.map((mark) => {
    const isActive = isMarkActive(editor, mark);
    if (isActive) {
      Editor.removeMark(editor, mark);
    }
  });
  BLOCK_TYPES.map((block) => {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(block),
      split: true,
    });
    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(block)) {
      newProperties = {
        align: undefined,
      };
    } else {
      newProperties = {
        type: "paragraph",
      };
    }
    Transforms.setNodes(editor, newProperties);
  });
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

// Add the initial value.
const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const CommentDiv = styled.div`
  position: absolute;

  height: ${isMobile ? "100%" : "100%"};
  width: ${isMobile ? "100%" : "42%"};
  background: white;

  right: 0;

  display: flex;
  flex-direction: column;
  flex-grow: 4;

  z-index: 1200;

  font-family: "Roboto", sans-serif;

  &.sidebar-enter {
    transform: translateX(100%);
  }
  &.sidebar-enter-active {
    transform: translateX(0);
    transition: transform 0.5s;
  }
  &.sidebar-exit {
    transform: translateX(0px);
  }
  &.sidebar-exit-active {
    transform: translateX(100%);
    transition: transform 0.2s;
  }
`;

const CommentScroller = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  height: 100%;
  min-width: 80%;
  overflow-y: scroll;
`;

const SlateDiv = styled.div`
  width: 70%;
  height: 60px;
  margin: 10px;
  padding-left: 8px;
  border: 1px solid grey;
  background-color: #eeeeee;
  border-radius: 6px;
  overflow-y: scroll;
  overflow-x: hidden;
  color: grey;
`;

const NewCommentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CommentButtonDiv = styled.div``;

const CommentContainer = styled.div`
  width: 90%;
  padding-left: 16px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 3px;
  display: flex;
`;

const CommentTile = styled.div`
  width: 100%;
  background-color: ${(props) => (props.autoGenerated ? "#a6a6a6" : "#d3d3d3")};
  padding: 10px;
  border-radius: 8px;
`;

const CommentDate = styled.span`
  font-size: 12px;
  color: ${(props) => (props.autoGenerated ? "#4c4c4c" : "grey")};
`;

const CommentText = styled.div`
  font-size: 13px;
  margin-top: 4px;
`;

const CommentUserCoinContainer = styled.div`
  position: relative;
  left: 85%;
  margin-top: 8px;
  margin-right: 3px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
  padding-left: 10px;
`;
