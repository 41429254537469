export const CHART_TYPES = {
  pie: "pie",
  line: "line",
  bar: "bar",
  multibar: "multibar",
  table: "table",
  matrix: "matrix",
  number: "number",
  heat: "heat",
  location: "location"
};
