import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { TextField, Tooltip, IconButton } from "@mui/material";
import { Add, Save, Delete } from "@mui/icons-material";
import { generate_tempid } from "../../../../../tools";
import AdvancedTable from "../../../../ui/tableadv";
import { AdvancedTableDictionaryConverter } from "../../../../ui/tableadv/converters";
import { is_random_insert_id, StatusContext } from ".";
import {
  BooleanField,
  SelectField,
  FileField,
  GenericField,
  PhotosField,
} from "../../../../ui/inputs2";

/*
  This file is a collection of table related subcomponents
*/

export const TableColumnEditor = ({ field, column, setChange }) => {
  const statusSet = useContext(StatusContext);
  const [tableColumnChanges, setTableColumnChanges] = useState(undefined);
  const [duplicateIdError, setDuplicateIdError] = useState(undefined);

  useEffect(() => {
    setTableColumnChanges({ ...column });
  }, [column]);

  const index = field.schema?.body?.columns.findIndex(
    (col) => col.id === column.id
  );
  if (column === undefined || index === -1) {
    return;
  }

  const onColumnChange = (e, key) => {
    if (key === "id") {
      setDuplicateIdError(undefined);
    }
    setTableColumnChanges((ex) => ({
      ...ex,
      [key]: e.target.value,
    }));
  };

  const saveChanges = () => {
    // check if column id is duplicated
    if (
      field.schema?.body?.columns.some(
        (col, i) => i !== index && col.id === tableColumnChanges.id
      )
    ) {
      setDuplicateIdError("You have duplicated column IDs");
      return;
    }

    // remove certain attributes from final update
    //    1. editableStatusSet if it is [] because when none are selected,
    //       all statuses are editable by default
    //    2. options if column type no longer uses options
    //    3. footer calculation if it is the empty string
    const {
      editableStatusSet,
      options,
      footerCalculation,
      ...requiredChanges
    } = tableColumnChanges;
    setChange((ex) => ({
      ...(ex?.schema || {}),
      body: {
        ...(ex?.schema?.body || [{}]),
        columns: [
          // All columns BEFORE it
          ...(ex?.schema?.body?.columns ?? [{}]).slice(0, index),
          // The column itself
          {
            ...requiredChanges,
            ...(editableStatusSet &&
              editableStatusSet.length > 0 && { editableStatusSet }),
            ...(["checkbox", "radio", "select", "mselect"].includes(
              requiredChanges.type
            ) && { options }),
            ...(footerCalculation && { footerCalculation }),
          },
          // All columns AFTER it
          ...(ex?.schema?.body?.columns ?? [{}]).slice(index + 1),
        ],
      },
    }));
  };

  const deleteColumn = () => {
    setChange((ex) => ({
      ...(ex?.schema || {}),
      body: {
        ...(ex?.schema?.body || [{}]),
        columns: [
          // All columns BEFORE it
          ...(ex?.schema?.body?.columns ?? [{}]).slice(0, index),
          // All columns AFTER it
          ...(ex?.schema?.body?.columns ?? [{}]).slice(index + 1),
        ],
      },
    }));
  };

  const insertColumnRight = () => {
    setChange((ex) => ({
      ...(ex?.schema || {}),
      body: {
        ...(ex?.schema?.body || [{}]),
        columns: [
          // All columns BEFORE and INCLUDING it
          ...(ex?.schema?.body?.columns ?? [{}]).slice(0, index + 1),
          // The new column
          {
            id: generate_tempid().substring(0, 10),
            title: "NEW COLUMN",
            type: "text",
          },
          // All columns AFTER it
          ...(ex?.schema?.body?.columns ?? [{}]).slice(index + 1),
        ],
      },
    }));
  };

  return (
    <TableEditorContainer>
      <TableEditorButtonContainer style={{ flexDirection: "column" }}>
        <div style={{ marginBottom: "4px", marginLeft: "10px" }}>
          Editing: {column.title}
        </div>
        <TableEditorButtonContainer>
          <Tooltip title="Save">
            <IconButton onClick={saveChanges}>
              <Save />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={deleteColumn}>
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Insert Column Right">
            <IconButton onClick={insertColumnRight}>
              <Add />
            </IconButton>
          </Tooltip>
        </TableEditorButtonContainer>
      </TableEditorButtonContainer>
      <TableEditorFieldsContainer>
        <GenericField
          label="Column ID"
          data={tableColumnChanges?.id}
          onChange={(e) => onColumnChange(e, "id")}
          error={duplicateIdError}
          style={{ paddingBottom: 0 }}
        />
        <GenericField
          label="Column Name"
          data={tableColumnChanges?.title}
          onChange={(e) => onColumnChange(e, "title")}
          style={{ paddingBottom: 0 }}
        />
        <SelectField
          label="Column Type"
          options={{
            text: "text",
            number: "number",
            select: "select",
            mselect: "multi select",
            date: "date",
            checkbox: "checkbox",
            radio: "radio",
            rowindex: "row index",
          }}
          allowNone={false}
          data={tableColumnChanges?.type}
          onChange={(e) => onColumnChange(e, "type")}
          style={{ paddingBottom: 0 }}
        />
        <SelectField
          label="Editable"
          options={statusSet.reduce(
            (acc, st) => ({ ...acc, [st.status]: st.name }),
            {}
          )}
          multi
          slim
          data={tableColumnChanges?.editableStatusSet}
          onChange={(e) => onColumnChange(e, "editableStatusSet")}
          style={{ paddingBottom: 0 }}
        />
        <GenericField
          label="Editable Condition"
          data={tableColumnChanges?.editableCondition}
          onChange={(e) => onColumnChange(e, "editableCondition")}
          style={{ paddingBottom: 0 }}
        />
        <GenericField
          label="Footer Calculation"
          data={tableColumnChanges?.footerCalculation}
          onChange={(e) => onColumnChange(e, "footerCalculation")}
          style={{ paddingBottom: 0 }}
        />
        {["checkbox", "radio", "select", "mselect"].includes(
          tableColumnChanges?.type
        ) && (
          <AdvancedTableDictionaryConverter
            data={tableColumnChanges?.options}
            setChange={(callback) =>
              setTableColumnChanges((ex) => ({
                ...ex,
                options: callback(ex?.options ?? [{}] ?? undefined),
              }))
            }
            targetColumns={["value", "label"]}
          >
            <AdvancedTable
              label="List Options"
              schema={{
                body: {
                  columns: [
                    { id: "value", title: "Value", type: "text" },
                    { id: "label", title: "Label", type: "text" },
                  ],
                },
                options: { allowImportExport: false },
              }}
            />
          </AdvancedTableDictionaryConverter>
        )}
      </TableEditorFieldsContainer>
      {/* TODO: Add editing for column style. May be use drag handles instead of a field. See AdvancedTableColumnScheme */}
    </TableEditorContainer>
  );
};

export const TableHeaderEditor = ({
  header,
  setChange,
  setTableHeaderEditor,
}) => {
  const [tableHeaderChanges, setTableHeaderChanges] = useState(undefined);

  useEffect(() => {
    setTableHeaderChanges({ ...header.details });
  }, [header]);

  const onHeaderChange = (e, key, style = false) => {
    if (style) {
      setTableHeaderChanges((ex) => ({
        ...ex,
        style: { ...(ex?.style ?? {}), [key]: e.target.value },
      }));
    } else {
      setTableHeaderChanges((ex) => ({
        ...ex,
        [key]: e.target.value,
      }));
    }
  };

  const saveChanges = () => {
    setChange((ex) => ({
      ...(ex?.schema || {}),
      header: [
        // All headers ABOVE it
        ...(ex?.schema?.header || []).slice(0, header.row),
        // The desired header
        {
          ...(ex?.schema?.header || [{}])[header.row],
          [header.col]: tableHeaderChanges,
        },
        // All headers BELOW it
        ...(ex?.schema?.header || []).slice(header.row + 1),
      ],
    }));
  };

  const deleteSection = () => {
    setChange((ex) => ({
      ...(ex?.schema || {}),
      header: [
        // All headers ABOVE it
        ...(ex?.schema?.header || []).slice(0, header.row),
        // The desired header
        Object.keys(ex?.schema?.header?.[header.row] ?? {}).reduce(
          (acc, col) => {
            col = parseInt(col);
            // ignore the deleted section
            if (col === header.col) {
              return acc;
            }
            const exHeader = ex.schema.header[header.row];
            // add rowOffset to section on deleted section's right
            if (col === header.col + 1) {
              return {
                ...acc,
                [col - 1]: {
                  ...exHeader[col],
                  rowOffset:
                    (exHeader[col - 1].rowOffset ?? 0) +
                    (exHeader[col - 1]?.style?.colSpan ?? 1) +
                    (exHeader[col].rowOffset ?? 0),
                },
              };
            }
            return {
              ...acc,
              [col < header.col ? col : col - 1]: exHeader[col],
            };
          },
          {}
        ),
        // All headers BELOW it
        ...(ex?.schema?.header || []).slice(header.row + 1),
      ],
    }));
    setTableHeaderEditor(undefined);
  };

  const insertSectionRight = () => {
    setChange((ex) => ({
      ...(ex?.schema || {}),
      header: [
        // All headers ABOVE it
        ...(ex?.schema?.header || []).slice(0, header.row),
        // The desired header
        Object.keys(ex?.schema?.header?.[header.row] ?? {}).reduce(
          (acc, col) => {
            col = parseInt(col);
            const exHeader = ex.schema.header[header.row];
            // add itself and the new section
            if (col === header.col) {
              return {
                ...acc,
                [col]: exHeader[col],
                [col + 1]: {
                  type: "text",
                },
              };
            }
            // decrease rowOffset of section on new section's right
            if (col === header.col + 1) {
              return {
                ...acc,
                [col + 1]: {
                  ...exHeader[col],
                  rowOffset: Math.max(0, (exHeader[col].rowOffset ?? 0) - 1),
                },
              };
            }
            return {
              ...acc,
              [col < header.col ? col : col + 1]: exHeader[col],
            };
          },
          {}
        ),
        // All headers BELOW it
        ...(ex?.schema?.header || []).slice(header.row + 1),
      ],
    }));
  };

  return (
    <TableEditorContainer>
      <TableEditorButtonContainer style={{ flexDirection: "column" }}>
        <div style={{ marginBottom: "4px", marginLeft: "10px" }}>
          Editing: {header?.details?.default ?? ""}
        </div>
        <TableEditorButtonContainer>
          <Tooltip title="Save">
            <IconButton onClick={saveChanges}>
              <Save />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={deleteSection}>
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Insert Header Right">
            <IconButton onClick={insertSectionRight}>
              <Add />
            </IconButton>
          </Tooltip>
        </TableEditorButtonContainer>
      </TableEditorButtonContainer>
      <TableEditorFieldsContainer>
        <SelectField
          label="Type"
          options={{
            text: "text",
            number: "number",
            static: "static",
          }}
          allowNone={false}
          data={tableHeaderChanges?.type}
          onChange={(e) => onHeaderChange(e, "type")}
          style={{ paddingBottom: 0 }}
        />
        <GenericField
          label="Default Value"
          data={tableHeaderChanges?.default}
          onChange={(e) => onHeaderChange(e, "default")}
          style={{ paddingBottom: 0 }}
        />
        <GenericField
          label="Row Offset"
          data={tableHeaderChanges?.rowOffset}
          onChange={(e) => onHeaderChange(e, "rowOffset")}
          style={{ paddingBottom: 0 }}
          number
        />
        <GenericField
          label="Column Span"
          data={tableHeaderChanges?.style?.colSpan}
          onChange={(e) => onHeaderChange(e, "colSpan", true)}
          style={{ paddingBottom: 0 }}
          number
        />
        <BooleanField
          label="Bold"
          data={tableHeaderChanges?.style?.bold}
          onChange={(e) => onHeaderChange(e, "bold", true)}
        />
      </TableEditorFieldsContainer>
    </TableEditorContainer>
  );
};

/*
  Initial Table Views, distinguisged with caps
*/
export const OPTIONS_LIST = ({ field, onFieldChange }) => {
  return (
    <AdvancedTableDictionaryConverter
      key="options"
      data={field.options}
      setChange={(callback) =>
        onFieldChange((ex) => ({ ...ex, options: callback(ex?.options) }))
      }
      targetColumns={["value", "label"]}
    >
      <AdvancedTable
        label="List Options"
        schema={{
          body: {
            columns: [
              { id: "value", title: "Value", type: "text" },
              { id: "label", title: "Label", type: "text" },
            ],
          },
          options: { allowImportExport: false },
        }}
      />
    </AdvancedTableDictionaryConverter>
  );
};

export const PREFIX_MAP_LIST = ({ field, onFieldChange, statusSet }) => {
  return (
    <AdvancedTableDictionaryConverter
      key="status-map"
      data={
        field?.versionMap ??
        statusSet.reduce((acc, st) => {
          acc[st.status] = undefined;
          return acc;
        }, {})
      }
      setChange={(callback) =>
        onFieldChange((ex) => ({
          ...ex,
          versionMap: callback(ex?.versionMap),
        }))
      }
      targetColumns={["status", "prefix"]}
    >
      <AdvancedTable
        label="Assign Version Number Prefixes"
        schema={{
          body: {
            columns: [
              {
                id: "status",
                title: "Status",
                type: "text",
              },
              { id: "prefix", title: "Prefix", type: "number" },
            ],
          },
          options: { allowImportExport: false },
        }}
      />
    </AdvancedTableDictionaryConverter>
  );
};

/*
  Components to be pushed for Table Nested Fields
*/
export const OptionsTableNested = ({ id, index, field, onFieldChange }) => {
  return (
    <AdvancedTableDictionaryConverter
      key={`table-options-${id}`}
      data={field.columns[index].options}
      setChange={(callback) =>
        onFieldChange((ex) => ({
          ...ex,
          columns: [
            // All indices BEFORE row
            ...(ex?.columns ?? [{}]).slice(0, index),
            // The row itself
            {
              ...(ex?.columns ?? [{}])[index],
              options: callback(
                (ex?.columns ?? [{}])[index].options ?? undefined
              ),
            },
            // All indices AFTER row
            ...(ex?.columns ?? [{}]).slice(index + 1),
          ],
        }))
      }
      targetColumns={["value", "label"]}
    >
      <AdvancedTable
        label={`List Options (${field.columns[index].name})`}
        schema={{
          body: {
            columns: [
              { id: "value", title: "Value", type: "text" },
              { id: "label", title: "Label", type: "text" },
            ],
          },
          options: { allowImportExport: false },
        }}
      />
    </AdvancedTableDictionaryConverter>
  );
};

export const VersionMapTableNested = ({
  id,
  index,
  field,
  onFieldChange,
  statusSet,
}) => {
  return (
    <AdvancedTableDictionaryConverter
      key={`table-versionmap-${id}`}
      data={
        field.columns[index]?.versionMap ??
        statusSet.reduce((acc, st) => {
          acc[st.status] = undefined;
          return acc;
        }, {})
      }
      setChange={(callback) =>
        onFieldChange((ex) => ({
          ...ex,
          columns: [
            // All indices BEFORE row
            ...(ex?.columns ?? [{}]).slice(0, index),
            // The row itself
            {
              ...(ex?.columns ?? [{}])[index],
              versionMap: callback(
                (ex?.columns ?? [{}])[index]?.versionMap ?? undefined
              ),
            },
            // All indices AFTER row
            ...(ex?.columns ?? [{}]).slice(index + 1),
          ],
        }))
      }
      targetColumns={["status", "prefix"]}
    >
      <AdvancedTable
        label={`Assign Version Prefixes (${field.columns[index].name})`}
        schema={{
          body: {
            columns: [
              { id: "status", title: "Status", type: "text" },
              { id: "prefix", title: "Prefix", type: "number" },
            ],
          },
          options: { allowImportExport: false },
        }}
      />
    </AdvancedTableDictionaryConverter>
  );
};

const TableEditorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid black;
  border-radius: 8px;
  width: fit-content;
`;

const TableEditorButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableEditorFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;
