import React, { useState, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import { isMobile } from "react-device-detect";

import { Notifications, AddBox } from "@mui/icons-material";

import { UserDataContext } from "../../../App";
import { useResponse, useTasks } from "../../../hooks/queries";
import { useForms } from "../../../hooks/forms";
import { useApprovals } from "../../../hooks/actions";
import { Button2 as Button } from "../../ui/buttons";
import { QueryStatusContext } from "./query";
import { ACTIONVIEWS } from "./actions";
import { TimerContext } from "../../../App";

import { useSearchParams } from "react-router-dom";

import { QUERYVIEWS, TEMPLATE_SCHEMA_STATUSSETS } from "../../../common/query";

// Workflow prompter (bottom button bar)
export default ({
  query,
  createQuery,
  queryData,
  schemaData,
  setConfirmationModal,
  setStatusMessage,
  beginClose,
  viewState,
  setShowErrors,
  queryValidationErrors,

  isNew,
}) => {
  // query will be defined on existing queries, and createQuery will be defined for new queries
  // We use boolean "isNew" to determine "new" status as its not a recorded status
  // setConfirmationModal is an inherited property because this is a child of the Query component

  const userData = useContext(UserDataContext);
  const queryStatus = useContext(QueryStatusContext);
  const forms = useForms(query);
  const { timer, setTimer } = useContext(TimerContext);

  // Also pull in all the actions
  const approvals = useApprovals(query);
  const tasks = useTasks(query);
  const [response, setResponse] = useResponse(query);

  // Now grap search state
  const [searchParams, setSearchParams] = useSearchParams();

  // And set render requirement values
  const [activeRequirement, setActiveRequirement] = useState(undefined);
  const [takeMeThere, setTakeMeThere] = useState(undefined);
  const [selfRemind, setSelfRemind] = useState(true); // Prevent remind from rendering for self

  // Extract route info for re-rendering take me there if we're already there :)
  const queryRoute = searchParams.get("qr");
  const actionsRoute = searchParams.get("ar");

  // We'll also use a state to determine if the user has already attempted to submit
  // This allows us to hold off on some of our workflow checks until after the user tries to submit
  // Which avoids random errors from being thrown at the user before they even try to submit
  const [submitAttempted, setSubmitAttempted] = useState(false);

  // When submit is attempted trigger the showing of errors if enabled
  useEffect(() => {
    if (submitAttempted && setShowErrors) {
      setShowErrors(true);
    }
  }, [submitAttempted, setShowErrors]);

  // Then define the "update query" function, which is run for submissions
  const updateQuery = (newStatus) => {
    let addins = {};
    if (newStatus === "closed") {
      addins = { closeTime: new Date() };
    }
    query.update({ ...queryData, status: newStatus, ...addins });

    setStatusMessage("Query Submitted");
    beginClose();
  };

  // add a "generateLinkedQuery" flag to the Query Data of the approved query (with the schemaId as the attribute)
  const addGenerateLinkedQueryFlag = () => {
    queryData.generateLinkedQuery = queryData.schemaId;
    queryData.linkedQueryCreator = userData.id;
    query.update({ ...queryData });
  };

  const addNotifyUserFlag = () => {
    queryData.notifyUser = true;
    query.update({ ...queryData });
  };

  useEffect(() => {
    // Obviously if our data isn't here, quit
    if (!schemaData || !queryData) {
      setActiveRequirement(undefined);
      return;
    }

    const requirementSet = queryStatus?.get_requirements_map();

    // check for linked query setting
    if (schemaData.settings?.generateLinkedQuery) {
      // check for correct status
      if (
        (queryData.status === "approved" && schemaData.type !== "custom") ||
        (schemaData.type === "custom" &&
          ["closed", "vended"].includes(queryData.status))
      ) {
        let requirement = { linkedQueryPrompt: true };
        setActiveRequirement(requirement);
        return;
      }
    }

    // This is a list of requirements to advance
    const statusRequirements = isNew
      ? requirementSet?.["new"]
      : requirementSet?.[queryData.status];

    // If there are no requirements, then we're done
    if (!statusRequirements) {
      setActiveRequirement(undefined);
      return;
    }

    // Before checking requirements, prepend the validation requirement (it's always run first)
    const checkedRequirements = [
      ...(submitAttempted
        ? [
            {
              type: "check",
              check: (data, actions) =>
                !Object.values(queryValidationErrors).some((q) => q !== false),
              guideText: "Some Query data is currently invalid",
            },
          ]
        : []),
      ...statusRequirements,
    ];

    // Otherwise it's time to select the requirement that is active
    let nextRequirement = checkedRequirements.find((requirement) => {
      if (requirement.type === "submit") {
        // Then this is the current requirement
        return true;
      } else if (requirement.type === "check") {
        // Then we need to check if the requirement is met
        // If the requirement check returns false, the find should return true!
        // Check is also allowed to query existing actions
        return !requirement.check(queryData, {
          response: response,
          tasks: [],
          approvals: [],
        });
      } else {
        // We don't support this requirement on the front-end which means it is not passed!
        return true;
      }
    });

    // Check that we found one (we should always)
    if (!nextRequirement) {
      setActiveRequirement(undefined);
      return;
    }

    // Set the active requirement
    setActiveRequirement(nextRequirement);
  }, [
    queryData,
    schemaData,
    isNew,
    queryValidationErrors,
    queryStatus,
    submitAttempted,
    response,
  ]);

  useEffect(() => {
    // DEFECT: This needs to be applied dynamically to custom schemas (maybe through an activeRequirement takemethere flag?)
    // See implementation for details on that <3
    if (schemaData?.type === "custom") {
      return;
    }
    // Whether or not we want to show the "Take me There" button. There are a few cases
    if (activeRequirement?.type === "approve") {
      // Approvals case
      let approvalUsers = approvals
        ?.filter((item) => item.status === "assigned")
        ?.map((item) => item.assignedTo);
      if (approvalUsers?.includes(userData.id)) {
        setTakeMeThere({ qr: QUERYVIEWS.ACTIONS, ar: ACTIONVIEWS.APPROVAL });
        setSelfRemind(approvalUsers.length === 1);
      } else {
        setTakeMeThere(undefined);
        setSelfRemind(false);
      }
    } else if (
      schemaData?.type === "response" &&
      queryData?.status === "open" &&
      activeRequirement?.type === "check"
    ) {
      // Response case
      if (response?.assignedTo === userData.id) {
        setTakeMeThere({ qr: QUERYVIEWS.ACTIONS, ar: ACTIONVIEWS.RESPONSE });
        setSelfRemind(true);
      } else {
        setTakeMeThere(undefined);
        setSelfRemind(false);
      }
    } else if (activeRequirement?.type === "trigger") {
      // Tasks blocking case
      const taskUsers = tasks?.map((item) =>
        item.status === "open" ? item.assignedTo : undefined
      );
      setTakeMeThere(
        taskUsers?.includes(userData.id)
          ? { qr: QUERYVIEWS.ACTIONS, ar: ACTIONVIEWS.TASK }
          : undefined
      );
    }
    // NOTE: The Form data missing case is handled in the same section that sets the error
  }, [queryData, schemaData, activeRequirement, approvals, tasks, response]);

  if (activeRequirement === undefined) {
    return null;
  }

  // NOTE: The Workflow manager should not render when printing (this is passed down from the query wrapper)
  //       It also should not render for tasks
  if (
    searchParams.get("qr") === QUERYVIEWS.PRINT ||
    (viewState === QUERYVIEWS.ACTIONS && searchParams.get("ar") === "task")
  ) {
    return null;
  }

  // Kind of a quirk of the workflow manager is that we can see viewstate
  // Which is useful to add an avoidance list
  // Because some viewstates don't need to be able to advance the Query
  if (
    [
      QUERYVIEWS.ADMIN_DEFAULTS,
      QUERYVIEWS.ADMIN_FIELDS,
      QUERYVIEWS.FIELDS,
      QUERYVIEWS.DEFAULTS,
      QUERYVIEWS.ADMIN_VALIDATION_CONTROLS,
      QUERYVIEWS.ADMIN_DYNAMIC_ID,
    ].includes(viewState)
  ) {
    return null;
  }

  const userIsPrivileged =
    activeRequirement?.privilege?.(queryData, userData, schemaData) ?? true;

  return (
    <QueryWorkflowBar>
      <QueryWorkflowText>
        {userIsPrivileged
          ? activeRequirement.guideText
          : activeRequirement.noPrivilegeText}
      </QueryWorkflowText>
      <QueryWorkflowButtons>
        {activeRequirement.remindUser && !selfRemind && (
          <Button
            label="Remind User"
            startIcon={<Notifications />}
            onClick={() => {
              setStatusMessage("User Notified");
              addNotifyUserFlag();
            }}
          />
        )}
        {activeRequirement.linkedQueryPrompt && (
          <Button
            label="Generate Query"
            startIcon={<AddBox />}
            onClick={() => {
              setStatusMessage("Linked Query Generated");
              addGenerateLinkedQueryFlag();
            }}
          />
        )}
        {activeRequirement.type === "submit" && userIsPrivileged && (
          <Button
            label={activeRequirement.buttonText}
            style={{ marginLeft: 10 }}
            onClick={() => {
              // First and foremost, they open the floodgate to submission validation here
              setSubmitAttempted(true);
              // Before we do any actual submission, check query validation
              if (
                Object.values(queryValidationErrors).some((q) => q !== false)
              ) {
                setStatusMessage(
                  `${activeRequirement.buttonText} failed because some Query fields are invalid`
                );
                // Also want to trigger the takeMeThere button here.
                setTakeMeThere({ qr: QUERYVIEWS.QUERY });
                return;
              } // If it fails, cancel, and the workflow is updated
              // First define the function to run here
              const submitFunction = isNew
                ? () => createQuery()
                : () => updateQuery(activeRequirement.moveTo);
              // Now check if we need to use a confirmation modal
              if (activeRequirement.confirmation) {
                setConfirmationModal({
                  open: true,
                  title: activeRequirement.confirmation.confirmationTitle,
                  message: activeRequirement.confirmation.confirmationText,
                  confirm: () => {
                    // add status change event to all forms
                    forms?.map((formData) => {
                      const form = query.forms.form(formData.id);
                      form.messages.add({
                        type: "formStatusChange",
                        message: `Form status changed to ${activeRequirement.moveTo}`,
                        sentTime: new Date(),
                        viewed: false,
                      });
                    });
                    submitFunction();
                  },
                });
              } else {
                submitFunction();
                forms?.map((formData) => {
                  const form = query.forms.form(formData.id);
                  form.messages.add({
                    type: "formStatusChange",
                    message: `Form status changed to ${activeRequirement.moveTo}`,
                    sentTime: new Date(),
                    viewed: false,
                  });
                });
              }
              // Finally, if for some reason there is a timer widget instance open, lets reset it
              if (timer.on && timer.queryId === queryData?.id) {
                setTimer((prev) => ({
                  ...prev,
                  start: false,
                  on: false,
                  startTime: Date.now(),
                  elapsed: 0,
                }));
              }
            }}
          />
        )}
        {takeMeThere &&
          activeRequirement.type !== "submit" &&
          ((takeMeThere.qr && takeMeThere.qr !== queryRoute) ||
            (takeMeThere.ar && takeMeThere.ar !== actionsRoute)) && (
            <Button
              style={{ marginLeft: 10 }}
              label="Take Me There!"
              onClick={() =>
                setSearchParams((ex) => {
                  ex.set("qr", takeMeThere["qr"]);
                  if (takeMeThere["ar"]) {
                    ex.set("ar", takeMeThere["ar"]);
                  }
                  return ex;
                })
              }
            />
          )}
      </QueryWorkflowButtons>
    </QueryWorkflowBar>
  );
};

// This is also a good place for a little component that uses the workflow bar as a floating save
// Primarily for use in administrative components in the Query pane
export const SaveQueryBar = ({ saveQuery }) => {
  return (
    <QueryWorkflowBar>
      <QueryWorkflowText></QueryWorkflowText>
      <QueryWorkflowButtons>
        <Button label="Save Changes" onClick={() => saveQuery()} />
      </QueryWorkflowButtons>
    </QueryWorkflowBar>
  );
};

const QueryWorkflowBar = styled.div`
  width: 100%;
  min-height: ${isMobile ? "72px" : "60px"};
  position: relative;
  display: flex;

  justify-content: space-between;
  align-items: center;

  bottom: 0;
  left: 0;

  background-color: #f2f2f2;
  border-top: 1px solid #c2c2c2;
`;

const QueryWorkflowText = styled.div`
  display: flex;
  flex-wrap: wrap;
  maxwidth: 50%;

  margin-left: 10px;
`;

const QueryWorkflowButtons = styled.div`
  display: flex;
  flex-direction: row;

  height: ${isMobile ? "56px" : "40px"};

  margin-right: 10px;
`;
