import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { get_field_by_id, parse_on_fields } from "../../../../../tools/forms";

import { QueryContent, QuerySection, QuerySectionHeader } from "../../query";
import { GenericField, Inputs, SelectField } from "../../../../ui/inputs2";
import { InteractiveTable } from "../../../../ui/table";
import { Typography, FormLabel, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";

import { SaveQueryBar } from "../../workflow";

import {
  useSchema,
  useSchemaCustomizations,
} from "../../../../../hooks/projects";
import { FieldGenerator } from "../../fields/generator";
import { UserContext } from "../../../../../App";

export default ({ project, schemaId, custom }) => {
  const [schema, schemaData] = useSchema(schemaId, project);
  const [schemaDefaults, setSchemaDefaults] = useState(undefined);
  const [fieldList, setFieldList] = useState(undefined);
  const [joinedFields, setJoinedFields] = useState(undefined);
  const [skipAdminDefaults, setSkipAdminDefaults] = useState(undefined);
  const [adminDefaultCopy, setAdminDefaultCopy] = useState(undefined);

  const user = useContext(UserContext);
  const userCustomSchema = useSchemaCustomizations(
    project,
    schema,
    user.ref.id
  );

  useEffect(() => {
    if (schemaData && userCustomSchema) {
      // If the user has any personal defaults set, we don't want to show any admin defaults
      // However, if the user has no personal defaults set (userCustomSchema?.customDefaults == null), then we want to fill in the admin defaults into their personal defaults
      // Note distinction between a user without personal defaults set (userCustomSchema?.customDefaults == null) vs personal defaults set to all be "No default" (userCustomSchema?.customDefaults == {})
      setSkipAdminDefaults(
        custom && userCustomSchema?.customDefaults ? true : false
      );
      let joined = [];
      if (schemaData.fields) {
        joined = joined.concat(schemaData.fields);
      }
      if (custom && userCustomSchema.customFields) {
        joined = joined.concat(userCustomSchema.customFields);
      }
      setJoinedFields(joined);
    }
  }, [schemaData, userCustomSchema, custom]);

  useEffect(() => {
    // The "schemaData" object needs to match the database when it changes
    // NOTE: Until feature #1zbw41k, this will be kinda quirky with concurrency
    if (joinedFields && skipAdminDefaults !== undefined) {
      // We'll need to map all defaults into this "schemaDefaults" object
      let defaults = {};
      let adminDefaults = {};
      let fields = {};
      // Now do the parse on all fields to collect active defaults & fields
      parse_on_fields(joinedFields, (fld) => {
        // First, add this field to the fields list for selection as conditional
        if (
          ![
            "table",
            "statictable",
            "files",
            "message",
            "link",
            "staticdoc",
            "staticimage",
            "signature",
            "tableadv",
          ].includes(fld.type)
        ) {
          // Some fields are inelligible for conditionalism
          fields[fld.id] = `${fld.name} (${fld.type})`;
        }
        // Now parse its default for insertion
        if (skipAdminDefaults) {
          // Then custom && userCustomSchema.customDefaults
          if (
            userCustomSchema.customDefaults[fld.id] ||
            (userCustomSchema.customDefaults[fld.id] === null &&
              fld.type === "date")
          ) {
            defaults[fld.id] = userCustomSchema.customDefaults[fld.id];
          }
        } else {
          if (fld.default || (fld.default === null && fld.type === "date")) {
            defaults[fld.id] = fld.default;
          }
        }
        // always create a copy of the admin defaults, since custom may need a reference
        if (fld.default || (fld.default === null && fld.type === "date")) {
          adminDefaults[fld.id] = fld.default;
        }
      });
      // Then finish with a set
      setSchemaDefaults(defaults);
      setAdminDefaultCopy(adminDefaults);
      setFieldList(fields);
    }
  }, [joinedFields, skipAdminDefaults, custom]);

  // Then we need a function to deconstruct the interface object and save!
  const saveChanges = () => {
    // Now we'll need to go the opposite way across the schema fields,
    // reconstructing them and applying defaults (deleting those that no longer exist)
    let cleanDefaults = Object.keys(schemaDefaults).reduce((acc, key) => {
      let cleanDefault;
      if (schemaDefaults[key]?._conditionalOn === null) {
        // Just continue (no default completed)
        return acc;
      } else {
        cleanDefault = schemaDefaults[key];
      }
      return { ...acc, [key]: cleanDefault };
    }, {});

    if (custom) {
      let updatedCustomDefaults = {};

      schemaData.fields.forEach((fld) => {
        let fldFinal = { ...fld };
        if (fld.section) {
          // We need to go through the section
          fld.section.forEach((secFld) => {
            let secFldFinal = { ...secFld };
            if (secFld.nested) {
              secFld.nested.forEach((nestedFld) => {
                // This is a regular field and we can update the default
                if (
                  cleanDefaults[nestedFld.id] ||
                  (cleanDefaults[nestedFld.id] === null &&
                    nestedFld.type === "date")
                ) {
                  // Second part of the OR statement is to accomodate the special case of empty date defaults
                  updatedCustomDefaults[nestedFld.id] =
                    cleanDefaults[nestedFld.id];
                }
              });
            } else {
              // This is a regular field and we can update
              if (
                cleanDefaults[secFldFinal.id] ||
                (cleanDefaults[secFldFinal.id] === null &&
                  secFldFinal.type === "date")
              ) {
                // Second part of the OR statement is to accomodate the special case of empty date defaults
                updatedCustomDefaults[secFldFinal.id] =
                  cleanDefaults[secFldFinal.id];
              }
            }
          });
        } else if (fld.nested) {
          // Then we'll write through the nested values
          fldFinal.nested.forEach((nestedFld) => {
            // This is a regular field and we can update the default
            if (
              cleanDefaults[nestedFld.id] ||
              (cleanDefaults[nestedFld.id] === null &&
                nestedFld.type === "date")
            ) {
              // Second part of the OR statement is to accomodate the special case of empty date defaults
              updatedCustomDefaults[nestedFld.id] = cleanDefaults[nestedFld.id];
            }
          });
        } else {
          // This is a regular field and we can update the default
          if (
            cleanDefaults[fld.id] ||
            (cleanDefaults[fld.id] === null && fld.type === "date")
          ) {
            // Second part of the OR statement is to accomodate the special case of empty date defaults
            updatedCustomDefaults[fld.id] = cleanDefaults[fld.id];
          }
        }
      });
      schema.users
        .user(user.ref.id)
        .set({ customDefaults: updatedCustomDefaults }, { merge: true });
    } else {
      // We'll use this object to update the defaults on every field obj
      let updatedFields = schemaData.fields.map((fld) => {
        let fldFinal = { ...fld };
        if (fld.section) {
          // We need to write through the section
          fldFinal.section = fld.section.map((secFld) => {
            let secFldFinal = { ...secFld };
            if (secFld.nested) {
              secFldFinal.nested = secFld.nested.map((nestedFld) => {
                // This is a regular field and we can update the default
                if (
                  cleanDefaults[nestedFld.id] ||
                  (cleanDefaults[nestedFld.id] === null &&
                    nestedFld.type === "date")
                ) {
                  // Second part of the OR statement is to accomodate the special case of empty date defaults
                  return { ...nestedFld, default: cleanDefaults[nestedFld.id] };
                } else {
                  // No default allowed
                  let cleanNestedFld = { ...nestedFld };
                  delete cleanNestedFld.default;
                  return cleanNestedFld;
                }
              });
              return secFldFinal;
            } else {
              // This is a regular field and we can update
              if (
                cleanDefaults[secFldFinal.id] ||
                (cleanDefaults[secFldFinal.id] === null &&
                  secFldFinal.type === "date")
              ) {
                // Second part of the OR statement is to accomodate the special case of empty date defaults
                return {
                  ...secFldFinal,
                  default: cleanDefaults[secFldFinal.id],
                };
              } else {
                // No default allowed
                delete secFldFinal.default;
                return secFldFinal;
              }
            }
          });
          return fldFinal;
        } else if (fld.nested) {
          // Then we'll write through the nested values
          fldFinal.nested = fldFinal.nested.map((nestedFld) => {
            // This is a regular field and we can update the default
            if (
              cleanDefaults[nestedFld.id] ||
              (cleanDefaults[nestedFld.id] === null &&
                nestedFld.type === "date")
            ) {
              // Second part of the OR statement is to accomodate the special case of empty date defaults
              return { ...nestedFld, default: cleanDefaults[nestedFld.id] };
            } else {
              // No default allowed
              let cleanNestedFld = { ...nestedFld };
              delete cleanNestedFld.default;
              return cleanNestedFld;
            }
          });
          return fldFinal;
        } else {
          // This is a regular field and we can update the default
          if (
            cleanDefaults[fld.id] ||
            (cleanDefaults[fld.id] === null && fld.type === "date")
          ) {
            // Second part of the OR statement is to accomodate the special case of empty date defaults
            return { ...fld, default: cleanDefaults[fld.id] };
          } else {
            delete fldFinal.default;
            return fldFinal;
          }
        }
      });

      // Now we'll do the update!
      schema.update({ fields: updatedFields });
    }
  };

  // We'll also need an indexed setter for the data
  const onFieldChange = (fieldId, value) => {
    setSchemaDefaults((ex) => ({ ...ex, [fieldId]: value }));
  };

  // Now we'll need to construct all of the fields
  // This view should match the QueryFields view quite closely
  // so we'll draw a lot of code from there

  const fields = [];
  const sections = [];

  if (!joinedFields || !schemaDefaults) {
    return null;
  }

  // Now build the view
  for (let field of joinedFields) {
    // In our schema, a nested array means they should appear side by side.
    // No section heading means to be included in the "Fields" header
    if (field.section) {
      // NOTE: We skip section conditionals here. Maybe they could be configured here as well?
      // Check if its a form section
      if (field.formId) {
        // Skip form section
        continue;
      }
      // Otherwise go to a regular section
      sections.push(
        <QuerySection
          key={`sec-${field.name.toLowerCase().replaceAll(" ", "-")}`}
        >
          <QuerySectionHeader>{field.name}</QuerySectionHeader>
          {field.section.map((fld) => {
            return (
              <Inputs
                key={`inp-wrapper-${
                  fld.nested ? `nst-${fld.nested[0].id}` : fld.id
                }`}
                style={{ flexWrap: "wrap", alignItems: "flex-start" }}
              >
                {(fld.nested ? fld.nested : [fld]).map((_field) => (
                  <ResolveFieldDefaultEditor
                    custom={custom}
                    key={`query-field-${_field.id}`}
                    field={_field}
                    conditionalField={
                      schemaDefaults[_field.id]?._conditionalOn
                        ? get_field_by_id(
                            joinedFields,
                            schemaDefaults[_field.id]?._conditionalOn
                          )
                        : undefined
                    }
                    defaultData={schemaDefaults[_field.id]}
                    adminDefault={adminDefaultCopy[_field.id]}
                    defaultDataOnChange={onFieldChange}
                    fieldOptions={fieldList}
                    setDefaultChange={setSchemaDefaults}
                  />
                ))}
              </Inputs>
            );
          })}
        </QuerySection>
      );
    } else {
      fields.push(
        <Inputs
          key={`inp-wrapper-${
            field.nested ? `nst-${field.nested[0].id}` : field.id
          }`}
          style={{ flexWrap: "wrap", alignItems: "flex-start" }}
        >
          {(field.nested ? field.nested : [field]).map((_field) => (
            <ResolveFieldDefaultEditor
              custom={custom}
              key={`query-field-${_field.id}`}
              field={_field}
              conditionalField={
                schemaDefaults[_field.id]?._conditionalOn
                  ? get_field_by_id(
                      joinedFields,
                      schemaDefaults[_field.id]?._conditionalOn
                    )
                  : undefined
              }
              defaultData={schemaDefaults[_field.id]}
              adminDefault={adminDefaultCopy[_field.id]}
              defaultDataOnChange={onFieldChange}
              fieldOptions={fieldList}
              setDefaultChange={setSchemaDefaults}
            />
          ))}
        </Inputs>
      );
    }
  }
  if (fields.length > 0) {
    sections.unshift(
      <QuerySection key="sec-fields-general">
        <QuerySectionHeader>Fields</QuerySectionHeader>
        {fields}
      </QuerySection>
    );
  }
  return (
    <>
      <QueryContent>{sections}</QueryContent>
      <SaveQueryBar saveQuery={saveChanges} />
    </>
  );
};

// There is a set of fields that will all map to a select field or multi-select field
const MSFIELDS = ["boxset"]; // all map to select
const SFIELDS = ["radio"]; // all map to multiselect

const ResolveFieldDefaultEditor = ({
  custom,
  field,
  conditionalField,
  defaultData,
  defaultDataOnChange,
  fieldOptions,
  setDefaultChange,
  adminDefault,
}) => {
  const [defaultSelection, setDefaultSelection] = useState(
    defaultData !== undefined
      ? defaultData?._conditionalOn !== undefined
        ? 2
        : defaultData?._format !== undefined
        ? 3
        : 1
      : field?.rows !== undefined ||
        (field.type === "date" && field.default === null)
      ? 1
      : 0
  );

  // Some field types disable defaulting:
  if (["files", "signature"].includes(field.type)) {
    return (
      <InputPartition>
        <FormLabel style={{ marginBottom: 4 }}>{field.name}</FormLabel>
        <Typography>No defaulting available for this field type.</Typography>
      </InputPartition>
    );
  }

  const defaultTypeOnChange = (e) => {
    const v = parseInt(e.target.value, 10); // for strict comparison
    switch (v) {
      case 0:
        // No more default
        defaultDataOnChange(field.id, undefined);
        setDefaultSelection(0);
        break;
      case 1:
        // Value default
        defaultDataOnChange(field.id, null); // Null is controlled but no value
        setDefaultSelection(1);
        break;
      case 2:
        // Conditional default
        defaultDataOnChange(field.id, { _conditionalOn: null });
        setDefaultSelection(2);
        break;
      case 3:
        // Functional default
        defaultDataOnChange(field.id, { _format: null });
        setDefaultSelection(3);
        break;
      case 4:
        // Schema default, only for custom
        defaultDataOnChange(field.id, adminDefault);
        setDefaultSelection(4);
        break;
      default:
        break;
    }
  };

  const defaultValueOnChange = () => (value) => {
    defaultDataOnChange(field.id, value);
  };

  // Then the regular default editor case
  return (
    <InputPartition>
      <FormLabel style={{ marginBottom: 4 }}>{field.name}</FormLabel>
      <SelectField
        options={
          custom ? { ...DEFAULT_SELECTS, 4: "Schema Default" } : DEFAULT_SELECTS
        }
        data={defaultSelection.toString()} //toString to satisfy invalid check, otherwise selection wont render
        onChange={defaultTypeOnChange}
        label="Default Type"
        allowNone={false}
      />
      {/* In the case where its a default value, show the field for value selection! */}
      {defaultSelection === 1 && (
        <FieldGenerator
          field={{
            ...field,
            type: MSFIELDS.includes(field.type)
              ? "multiselect"
              : SFIELDS.includes(field.type)
              ? "select"
              : field.type === "statictable"
              ? "table"
              : field.type,
            name: `Default ${field.name}`,
          }}
          data={{ [field.id]: defaultData }}
          onChangeGenerator={defaultValueOnChange}
          withLabel
          editable={true}
          editabilityConditions={{ forceEdit: true }}
          status={"forceditable"}
          setFieldChange={setDefaultChange}
          isDefault
        />
      )}
      {/* The following is all applied to the 2 case (conditional default) */}
      {defaultSelection === 2 && (
        <ConfigurationLine>
          <SelectField
            label="Condition On"
            options={fieldOptions ?? {}}
            data={defaultData?._conditionalOn}
            onChange={(e) => {
              const { value } = e.target;
              defaultDataOnChange(field.id, { _conditionalOn: value });
            }}
          />
        </ConfigurationLine>
      )}
      {defaultSelection === 2 && (
        <ConditionalDefaultFieldEditor
          field={field}
          defaultData={defaultData}
          defaultDataOnChange={defaultDataOnChange}
          conditionalField={conditionalField}
        />
      )}
      {/* Now we apply the 3 case, which is always a text field where a function is entered */}
      {defaultSelection === 3 && (
        <GenericField
          label="Functional Default"
          data={defaultData._format}
          onChange={(e) => {
            const { value } = e.target;
            defaultDataOnChange(field.id, { _format: value });
          }}
          type="text"
        />
      )}
    </InputPartition>
  );
};

const ConditionalDefaultFieldEditor = ({
  field,
  conditionalField,
  defaultData,
  defaultDataOnChange,
}) => {
  // Before anything else, setup the view state
  const [newConditionalPair, setNewConditionalPair] = useState({
    condition: undefined,
    default: undefined,
  });
  const [selectedTableViewCondition, setSelectedTableViewCondition] =
    useState(undefined);

  const commitConditionalPair = () => {
    if (
      newConditionalPair.condition !== undefined &&
      newConditionalPair.default !== undefined
    ) {
      defaultDataOnChange(field.id, {
        ...defaultData,
        [newConditionalPair.condition]: newConditionalPair.default,
      });
      setNewConditionalPair({ condition: undefined, default: undefined });
    }
  };

  const setDefaultChange = (callback) => {
    setNewConditionalPair((ex) => ({
      ...ex,
      default: callback({ [field.id]: ex.default } ?? {})[field.id],
    }));
  };

  return (
    <>
      {/* If there is no conditionalField available, just leave it as is and let them sort it out */}
      {conditionalField && (
        <Typography style={{ fontSize: 12 }}>
          Add new Conditional Value Pair
        </Typography>
      )}
      {conditionalField && (
        <ConfigurationLine
          style={{ backgroundColor: "#F2F2F2", borderRadius: 6 }}
        >
          <ConfigurationLineFields style={{ flex: "2 1 auto" }}>
            {/* This one is for the field we're conditional on */}
            <FieldGenerator
              field={{
                ...conditionalField,
                type: MSFIELDS.includes(conditionalField.type)
                  ? "multiselect"
                  : SFIELDS.includes(conditionalField.type)
                  ? "select"
                  : conditionalField.type,
                name: `${conditionalField.name} Value`,
              }}
              data={{ [conditionalField.id]: newConditionalPair.condition }}
              onChangeGenerator={() => (v) => {
                setNewConditionalPair((ex) => ({ ...ex, condition: v }));
              }}
              withLabel
              editable={true}
              editabilityConditions={{ forceEdit: true }}
            />
            {/* This one is for the value in our current field */}
            {!TABLE_FIELD_SET.includes(field.type) && (
              <FieldGenerator
                field={{
                  ...field,
                  type: MSFIELDS.includes(field.type)
                    ? "multiselect"
                    : SFIELDS.includes(field.type)
                    ? "select"
                    : field.type,
                  name: `Default ${field.name}`,
                }}
                data={{ [field.id]: newConditionalPair.default }}
                onChangeGenerator={() => (v) => {
                  setNewConditionalPair((ex) => ({ ...ex, default: v }));
                }}
                withLabel
                editable={true}
              />
            )}
          </ConfigurationLineFields>
          <IconButton
            style={{ height: "fit-content" }}
            onClick={commitConditionalPair}
          >
            <Add />
          </IconButton>
        </ConfigurationLine>
      )}
      {/* We add a second field generator down here to render a table if that's the field type */}
      {conditionalField && TABLE_FIELD_SET.includes(field.type) && (
        <FieldGenerator
          field={{
            ...field,
            type: field.type === "statictable" ? "table" : field.type,
            name: `Default ${field.name} for the conditional value`,
          }}
          data={{ [field.id]: newConditionalPair.default }}
          onChangeGenerator={() => (v) => {
            setNewConditionalPair((ex) => ({ ...ex, default: v }));
          }}
          withLabel
          editable={true}
          status={"forceditable"}
          setFieldChange={setDefaultChange}
        />
      )}
      <Typography style={{ fontSize: 12 }}>
        Browse Conditional Value Pairs
      </Typography>
      {!TABLE_FIELD_SET.includes(field.type) && (
        <ConditionalDefaultBrowser>
          <InteractiveTable
            data={Object.keys(defaultData)
              .map((k) =>
                ["_default", "_conditionalOn"].includes(k)
                  ? undefined
                  : {
                      condition: k,
                      default: Array.isArray(defaultData[k])
                        ? defaultData[k].join(", ")
                        : defaultData[k],
                    }
              )
              .filter((v) => v !== undefined)}
            columns={[
              { name: "Condition", field: "condition" },
              { name: "Default Value", field: "default" },
            ]}
          />
        </ConditionalDefaultBrowser>
      )}
      {conditionalField && TABLE_FIELD_SET.includes(field.type) && (
        <ConditionalDefaultTableBrowser style={{ height: "fit-content" }}>
          <Typography>
            Select a conditional value to view its associated default table
          </Typography>
          <FieldGenerator
            field={{
              ...conditionalField,
              type: MSFIELDS.includes(conditionalField.type)
                ? "multiselect"
                : SFIELDS.includes(conditionalField.type)
                ? "select"
                : conditionalField.type,
              name: `${conditionalField.name} Value`,
            }}
            data={{ [conditionalField.id]: selectedTableViewCondition }}
            onChangeGenerator={() => (v) => {
              setSelectedTableViewCondition(v);
            }}
            withLabel
            editable={true}
          />
        </ConditionalDefaultTableBrowser>
      )}
      {conditionalField && TABLE_FIELD_SET.includes(field.type) && (
        <ConditionalDefaultTableBrowser>
          {defaultData[selectedTableViewCondition] && (
            <FieldGenerator
              field={{
                ...field,
                type: field.type === "statictable" ? "table" : field.type,
                name: `Default ${field.name} for the conditional value`,
              }}
              data={{ [field.id]: defaultData[selectedTableViewCondition] }}
              onChangeGenerator={() => () => {}}
              withLabel
              editable={true}
              // status={'forceditable'}
            />
          )}
          {!defaultData[selectedTableViewCondition] && (
            <Typography>No default table found for this value</Typography>
          )}
        </ConditionalDefaultTableBrowser>
      )}
    </>
  );
};

const TABLE_FIELD_SET = ["table", "statictable", "tableadv"];

const DEFAULT_SELECTS = {
  0: "No Default",
  1: "Default Value",
  2: "Conditional Default",
  3: "Functional Default",
};

export const InputPartition = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 20%;
  margin: 10px 8px;
`;

const ConfigurationLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ConfigurationLineFields = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConditionalDefaultBrowser = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
`;

const ConditionalDefaultTableBrowser = styled.div`
  width: 100%;
  height: 300px;
  overflow-y: scroll;
`;
