import React, { useState, useCallback, useContext } from "react";
import { useSearchParams } from "react-router-dom";

import Query, { QueryContainer } from "./query";
import {
  useProject,
  useSchema,
  useCurrentUsers,
  useProjectLogo,
} from "../../../hooks/projects";
import {
  useNewQueryBatch,
  // useNewQuery,
  useQueries,
  useQueryStatus,
  useQueryUnified,
} from "../../../hooks/queries";
import { useUserBuiltPermissions } from "../../../hooks/users";

import QueryWorkflow from "./workflow";
import { UserContext, UserDataContext } from "../../../App";
import { QueryContextProvider } from "./provider";

// Unified Query Content Components
import NewQueryContent from "./queryc_new";
import CommonQueryContent from "./queryc_common";

export const QueryBatchContext = React.createContext(undefined);
export const NewQueryTasksContext = React.createContext(undefined);

/** Used to generate a new query of any kind. */
const NewQuery = ({ lookupIndex }) => {
  // Get IDs from URL
  const [projectId, schemaId] = lookupIndex.split("-"); // Of the form PROJECTID-SCHEMAID

  // Get search state
  const [searchParams, setSearchParams] = useSearchParams();

  // And let's check if we're doing a query copy (from the URL) "cp" value
  const copyQueryIndex = searchParams.get("cp");

  // Get common data
  const user = useContext(UserContext);
  const userData = useContext(UserDataContext);

  // Now get the Data for the specific IDs
  const [project, projectData] = useProject(projectId);
  const projectUsers = useCurrentUsers(project);
  const [schema, schemaData] = useSchema(schemaId, project);
  const queries = useQueries(project, projectData?.settings?.isAnonymized); // This may cause problems or slowdowns on large projects? May need a limiter
  const [_1, queryData, setQueryData, dirty, clean, _2] = useQueryUnified(
    { schemaId },
    { userData, copyQueryIndex },
    project
  );
  const queryStatus = useQueryStatus(queryData, schemaData);

  // NOTE: newQueryRef cannot be passed into children as "query" prop because the ref exists despite having no data which bypasses undefined checks
  const [queryBatch, newQueryRef] = useNewQueryBatch(project);

  // Also fetch the user's permissions based on the schemaData & userData
  const userPermissions = useUserBuiltPermissions(
    user,
    schemaData,
    projectUsers
  );

  // Then some viewstate management
  const [queryValidationErrors, setQueryValidationErrors] = useState({});
  const [showQueryErrors, setShowQueryErrors] = useState(false);

  // A couple of user feedback and pretty states
  const [statusMessage, setStatusMessage] = useState("");
  const projectLogo = useProjectLogo(projectData);

  // Tasks state for new queries
  const [newQueryTasks, setNewQueryTasks] = useState([]);

  const saveQuery = useCallback(() => {
    // if (!runValidator('new')) { return; }
    // Update queryRef with queryData and then commit batch
    queryBatch.current.update(newQueryRef.current, queryData);
    queryBatch.current.commit().then((_) => {
      // After opening the query, navigate to it.
      searchParams.delete("nq");
      setSearchParams({
        qq: `${project.ref.id}-${newQueryRef.current.ref.id}`,
      });
      clean();
    });
  }, [project, queryData, clean, setSearchParams, searchParams]);

  /** Require that the schema & queryData have loaded. */
  if (!schemaData || !queryData) {
    return null;
  }

  return (
    <QueryContextProvider
      projectUsers={projectUsers}
      projectLogo={projectLogo}
      projectQueries={queries}
      projectReference={project}
      userPermissions={userPermissions}
      showFieldErrors={showQueryErrors}
      autoCompleteUserlist={schemaData?.settings?.selectUserAutoComplete}
      queryStatus={queryStatus}
      queryBatch={queryBatch}
      newQueryTasks={newQueryTasks}
      setNewQueryTasks={setNewQueryTasks}
    >
      <QueryContainer>
        <Query
          title={`New ${schemaData.name}`}
          status={"new"}
          statusMessage={statusMessage}
          setStatusMessage={setStatusMessage}
          schemaData={schemaData}
          dirty={dirty}
          clean={clean}
          saveQuery={saveQuery}
        >
          <NewQueryContent
            queryData={queryData}
            schema={schema}
            schemaData={schemaData}
            setQueryData={setQueryData}
            setQueryValidationErrors={setQueryValidationErrors}
            project={project}
            projectLogo={projectLogo}
            queries={queries}
            setStatusMessage={setStatusMessage}
          />
          <CommonQueryContent
            queryData={queryData}
            setQueryData={setQueryData}
            schemaData={schemaData}
            projectLogo={projectLogo}
            project={project}
            setStatusMessage={setStatusMessage}
            newQueryRef={newQueryRef}
            isNew
          />
          <QueryWorkflow
            createQuery={saveQuery}
            queryData={queryData}
            schemaData={schemaData}
            setStatusMessage={setStatusMessage}
            setShowErrors={setShowQueryErrors}
            queryValidationErrors={queryValidationErrors}
            isNew
          />
        </Query>
      </QueryContainer>
    </QueryContextProvider>
  );
};

export default NewQuery;
