import React, { useCallback, useContext, useState } from "react";
import styled from "@emotion/styled";
import { ProjectQueriesContext } from "../..";
import { useFetchQuery } from "../../../../hooks/queries";
import { Button2 } from "../../../ui/buttons";
import { InteractiveTable } from "../../../ui/table";
import { StatusDot } from "../../../ui/decorations";
import { QueryContent } from "../query";

export default ({
  setQueryData,
  project,
  schemaData,
  queryData,
  setPromptQueryLinking,
}) => {
  const queries = useContext(ProjectQueriesContext);

  const [savedQueryData, setSavedQueryData] = useState(queryData);
  const [potentialQueryData, setPotentialQueryData] = useState(undefined);

  const [previousCallData, setFetchQueryCallback] = useFetchQuery(project);

  const setByDotNotation = (object, notation, value) => {
    notation = notation.split(".");
    while (notation.length > 1) {
      let val = notation.shift();
      if (object[val] === undefined) {
        object[val] = {};
      }
      object = object[val];
    }
    object[notation[0]] = value;
  };

  const getByDotNotation = (t, path) =>
    path.split(".").reduce((r, k) => r?.[k], t);

  const linkQuery = useCallback(
    (linkedQuery) => {
      setFetchQueryCallback(linkedQuery.id, (data) => {
        // Now populate the linked query data using the map
        let queryMap = schemaData.linkedQueries.from.autoPopulate;
        if (queryMap) {
          let newDataObj = {};
          // Setup the object with current info for the form as well
          Object.keys(queryMap).forEach((fromId) => {
            let toId = queryMap[fromId];
            if (toId.split(".")?.[0] == "formInvitees") {
              const formIndex = parseInt(toId.split(".")?.[1] ?? 0);
              let fromIdData = getByDotNotation(data, fromId);
              if (fromIdData) {
                if (!newDataObj.formInvitees) {
                  newDataObj.formInvitees = [];
                }
                while (newDataObj.formInvitees?.length < formIndex) {
                  newDataObj.formInvitees.push({});
                }
                newDataObj.formInvitees[formIndex] = {
                  body: [
                    ...(newDataObj.formInvitees[formIndex]?.body ?? []),
                    ...(fromIdData?.body ? fromIdData.body : [fromIdData]),
                  ],
                };
              }
            } else {
              let fromIdData = getByDotNotation(data, fromId);
              if (fromIdData) {
                setByDotNotation(newDataObj, toId, fromIdData);
              }
            }
          });
          setPotentialQueryData({
            id: linkedQuery.id,
            dynamicId: linkedQuery.dynamicId,
            ...newDataObj,
          });
        }
      });
    },
    [schemaData, setFetchQueryCallback]
  );

  return (
    <QueryContent style={{ marginTop: 6 }}>
      <LinkingExplainers>
        <LinkingInfo>
          <LinkingTitle>{schemaData.linkedQueries.from.title}</LinkingTitle>
          <div>
            Select a Query below from the source schema to pass its relevant
            data to the new Query
          </div>
        </LinkingInfo>
        <div>
          <Button2
            label="Skip"
            style={{ marginRight: 10 }}
            onClick={() => {
              setQueryData(savedQueryData);
              setPromptQueryLinking(false);
            }}
          />
          <Button2
            label="Confirm"
            style={{ marginRight: 10 }}
            onClick={() => {
              setQueryData((ex) => ({
                ...ex,
                formInvitees: [
                  ...(ex?.formInvitees ?? []),
                  ...(potentialQueryData?.formInvitees ?? []),
                ],
                data: { ...ex.data, ...potentialQueryData.data },
                linkedQueries: {
                  from: schemaData.linkedQueries.from.multiple
                    ? []
                    : [
                        {
                          id: potentialQueryData.id,
                          dynamicId: potentialQueryData.dynamicId,
                        },
                      ],
                },
              }));
              setPromptQueryLinking(false);
            }}
            disabled={!potentialQueryData}
          />
        </div>
      </LinkingExplainers>
      <div>
        <InteractiveTable
          columns={QUERY_LINK_DEFAULT_COLS.concat([
            {
              name: "Selected",
              component: (row) => (row._selected ? <StatusDot cmplt /> : null),
            },
          ])}
          data={[
            ...queries.filter(
              (dt) =>
                dt.schemaId == schemaData.linkedQueries.from.id &&
                ["closed", "approved"].includes(dt.status)
            ),
          ]
            .sort((a, b) => (a.dynamicId > b.dynamicId ? 1 : -1))
            .map((dt) => ({
              ...dt,
              _selected: dt.id === potentialQueryData?.id,
            }))}
          onRowClick={(row) => {
            linkQuery(row._bin);
          }}
          hover
        />
      </div>
    </QueryContent>
  );
};

export const QUERY_LINK_DEFAULT_COLS = [
  {
    name: "ID",
    index: "id",
    format: "return (data.dynamicId) ? data.dynamicId : data.id",
  },
  {
    name: "Status",
    format: "return data.status.toUpperCase()",
  },
];

const LinkingExplainers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 8px;
`;

const LinkingInfo = styled.div`
  display: flex;
  flex-direction: column;

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;

const LinkingTitle = styled.div`
  font-size: 17px;
  font-weight: 500;
`;
