import React, { useEffect, useState } from "react";

import { IconButton, Tooltip } from "@mui/material";
import { AddBox, Description } from "@mui/icons-material";
import styled from "styled-components";
import { useMessages } from "../../../../hooks/forms";
import { Button2 } from "../../../ui/buttons";
import { format } from "date-fns";

import EBound from "../../../ui/errorbounds";
import { SelectField, GenericField, FileField } from "../../../ui/inputs2";

import q, { APIStorage } from "query-it";
import { LoadingBoundary } from "../../../../App";

export default ({ form, formData, messager, negotiateFiles, filePath }) => {
  const messages = useMessages(form);

  // Setup new message states
  const [newMessage, setNewMessage] = useState("");
  const [newFileMessage, setNewFileMessage] = useState(undefined);

  if (!messages) {
    return <LoadingBoundary />;
  }

  return (
    <EBound area="Form Messenger Interal">
      <ClarifierDiv>
        {newFileMessage && (
          <MessageFileDiv>
            <MessageFileConfigContainer>
              <div>
                <FileField
                  label="File Revision"
                  path={filePath}
                  bucket="projects"
                  onChange={(value) => {
                    setNewFileMessage((ex) => ({ ...ex, file: value }));
                  }}
                  files={
                    newFileMessage.file != "" ? newFileMessage.file : undefined
                  }
                />
                <SelectField
                  label="Element Revised"
                  data={newFileMessage.relatedFieldId}
                  options={negotiateFiles.reduce(
                    (acc, fl) => ({ ...acc, [fl.id]: fl.title }),
                    {}
                  )}
                  onChange={(e) => {
                    const { value } = e.target;
                    setNewFileMessage((ex) => ({
                      ...ex,
                      relatedFieldId: value,
                    }));
                  }}
                />
              </div>
              <GenericField
                label="Attachment Comment"
                data={newFileMessage.message}
                onChange={(e) => {
                  const { value } = e.target;
                  setNewFileMessage((ex) => ({ ...ex, message: value }));
                }}
                multiLine
              />
              <Buttons>
                <Button2
                  label="Cancel"
                  onClick={() => setNewFileMessage(undefined)}
                  style={{ minWidth: "fit-content", width: "20%" }}
                />
                <Button2
                  label="Submit"
                  disabled={
                    newFileMessage.file == "" ||
                    newFileMessage.relatedFieldId == ""
                  }
                  style={{ minWidth: "fit-content", width: "75%" }}
                  onClick={() => {
                    const lastRevision =
                      formData?.negotiableRevisions?.[
                        newFileMessage.relatedFieldId
                      ]?.lastRevision ?? undefined;
                    form.messages
                      .add({
                        ...newFileMessage,
                        revision: lastRevision ? lastRevision + 1 : 1,
                        sentTime: new Date(),
                      })
                      .then((res) =>
                        form
                          .update({
                            negotiableRevisions: {
                              ...formData.negotiableRevisions,
                              [newFileMessage.relatedFieldId]: {
                                ...formData.negotiableRevisions?.[
                                newFileMessage.relatedFieldId
                                ],
                                lastRevision: lastRevision ? lastRevision + 1 : 1,
                              },
                            },
                          })
                          .then((_) => setNewFileMessage(undefined))
                      );
                  }}
                />
              </Buttons>
            </MessageFileConfigContainer>
          </MessageFileDiv>
        )}
        {!newFileMessage && (
          <ClarifierMessages>
            {messages
              .sort((a, b) => b.sentTime - a.sentTime)
              .map((msg) => {
                if (!msg.type && (!msg.sentBy || (!msg.message && !msg.file))) {
                  return null;
                }
                return (
                  <ClarifierMessage key={`msg-${msg.id}`}>
                    <MessageMetadata
                      style={{
                        alignSelf: !msg.sentBy
                          ? "center"
                          : msg.sentBy.side == messager.side
                            ? "flex-end"
                            : "flex-start",
                      }}
                    >
                      {!msg.sentBy ? msg.message : msg.sentBy.name} on{" "}
                      {format(msg.sentTime.toDate(), "MMM do")} at{" "}
                      {format(msg.sentTime.toDate(), "h:mm a")}
                    </MessageMetadata>
                    {msg.sentBy ? (
                      <GeneralMessage
                        message={msg}
                        negotiateFiles={negotiateFiles}
                        viewerSent={msg.sentBy.side == messager.side}
                      />
                    ) : null}
                    {/* {msg.sentBy.side == messager.side && <SenderMessage>
            
          </SenderMessage>}
          {msg.sentBy.side != messager.side && <ReceiverMessage>
            <GeneralMessage message={msg} negotiateFiles={negotiateFiles} />
          </ReceiverMessage>} */}
                  </ClarifierMessage>
                );
              })}
            {messages.length < 1 && (
              <ClarifierMessage>
                <SysMessage>
                  Send a message to privately clarify query details with the
                  sender
                </SysMessage>
              </ClarifierMessage>
            )}
          </ClarifierMessages>
        )}
        <ClarifierInteract>
          {negotiateFiles && negotiateFiles.length > 0 && (
            <Tooltip title="Add File">
              <IconButton
                onClick={() =>
                  setNewFileMessage({
                    file: "",
                    relatedFieldId: "",
                    sentBy: messager,
                    viewed: false,
                  })
                }
                style={{ padding: 6 }}
              >
                <AddBox />
              </IconButton>
            </Tooltip>
          )}
          <SendBox
            placeholder="Message the Sender"
            onChange={(e) => {
              setNewMessage(e.target.value);
            }}
            value={newMessage}
          />
          <Button2
            label="Send"
            style={{ marginRight: 15 }}
            onClick={() => {
              form.messages.add({
                sentBy: messager,
                message: newMessage,
                sentTime: new Date(),
                viewed: false, // To tag whether the other party has seen the message
              });
              setNewMessage("");
            }}
          />
        </ClarifierInteract>
      </ClarifierDiv>
    </EBound>
  );
};

const GeneralMessage = ({ message, negotiateFiles, viewerSent }) => {
  const [url, setURL] = useState(undefined);

  useEffect(() => {
    if (message.file) {
      APIStorage.getDownloadURL(
        q.getProjectStorage("projects").ref(message.file[0].location)
      ).then(setURL);
    }
  }, [message]);

  const MessageComponent = viewerSent ? SenderMessage : ReceiverMessage;

  const mashStyle = { marginTop: "-6px" };

  if (message.file) {
    const negFile = negotiateFiles?.find(
      (fl) => fl.id === message.relatedFieldId
    );
    // If the file is non-negotiable on this messenger side, show a small error
    if (!negFile) {
      return <>File Message Hidden</>;
    }
    // Now build final file message
    return (
      <>
        <MessageComponent>
          <FileMessage>
            <Tooltip title="Download">
              <IconButton
                style={{ width: 40, height: 40 }}
                target="_blank"
                href={url}
              >
                <Description />
              </IconButton>
            </Tooltip>
            <FileMessageDescripton>
              <FileMessageName>{negFile.title}</FileMessageName>
              <FileMessageRev>
                Rev. {message.revision ? message.revision : "0"}
              </FileMessageRev>
            </FileMessageDescripton>
          </FileMessage>
        </MessageComponent>
        {/* Make sure to include a message as well if one exists */}
        {message.message && message.message.length > 0 && (
          <MessageComponent style={mashStyle}>
            {message.message}
          </MessageComponent>
        )}
      </>
    );
  } else {
    return <MessageComponent>{message.message}</MessageComponent>;
  }
};

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ClarifierDiv = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  // border: 1px solid grey;
  border-radius: 12px;
`;

const ClarifierMessages = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column-reverse;

  max-height: 100%;
  overflow-y: auto;
  padding-top: 20px;
`;

const ClarifierMessage = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;

  font-family: ${(props) => props.theme.font};
`;

const Message = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 6px;
  margin: 10px;
  border-radius: 8px;
`;

const SenderMessage = styled(Message)`
  align-self: flex-end;
  background-color: #8ec0ff;
`;

const ReceiverMessage = styled(Message)`
  align-self: flex-start;
  background-color: #cacaca;
`;

const SysMessage = styled(Message)`
  color: grey;
  align-self: center;
  font-size: 14px;
`;

const MessageMetadata = styled(Message)`
  font-size: 10px;
  color: grey;
  margin: 0 10px 0 10px;
  padding: 0;
`;

const ClarifierInteract = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SendBox = styled.textarea`
  height: 1.1rem;
  width: 90%;
  margin: 8px;
  padding: 5px;
  resize: none;
  border-radius: 2px;

  font-family: ${(props) => props.theme.font};

  &:focus {
    outline: none;
  }
`;

const MessageFileDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

const MessageFileConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileMessage = styled.div`
  display: flex;
  width: 160px;
  height: 60px;
  align-items: center;
`;

const FileMessageDescripton = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileMessageName = styled.div`
  font-size: 14px;
`;

const FileMessageRev = styled.div`
  font-size: 12px;
`;
