import React, { useState } from "react";
import styled from "@emotion/styled";

import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Delete, Edit, FileCopy, DragIndicator } from "@mui/icons-material";

export const FloatingFieldEditButtons = ({
  onFieldChange,
  onFieldDelete,
  onFieldDuplicate,
  types,
}) => {
  const [showFieldList, setShowFieldList] = useState(false);

  return (
    <FloatingButtonContainer>
      {/* Hidden Menu */}
      <FloatingSelectList
        setField={(field) => onFieldChange((ex) => ({ ...ex, type: field }))}
        show={showFieldList}
        onHide={() => setShowFieldList(false)}
        types={types}
      />
      {/* Rendered view */}
      <strong>
        <Tooltip title="Drag Field">
          <IconButton size="small">
            <DragIndicator />
          </IconButton>
        </Tooltip>
      </strong>
      <Tooltip title="Change Type">
        <IconButton size="small" onClick={() => setShowFieldList(true)}>
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Duplicate">
        <IconButton size="small" onClick={onFieldDuplicate ?? (() => {})}>
          <FileCopy />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton size="small" onClick={onFieldDelete ?? (() => {})}>
          <Delete />
        </IconButton>
      </Tooltip>
    </FloatingButtonContainer>
  );
};

export const FloatingSelectList = ({
  show,
  onHide,
  setField,
  types,
  placeholder,
  data,
  position = "left", // whether the dropdown appears to the left or the right of the buttons
}) => {
  if (!show) {
    return null;
  }

  // Before handing off to autocomplete, the options need to be visibly unique, so we'll append numbers on duplicates
  const uniqueOptionsReverse = {};
  Object.keys(types).forEach((key) => {
    let label = types[key].label;
    let i = 1;
    while (uniqueOptionsReverse[label]) {
      label = types[key].label + " (" + i + ")";
      i++;
    }
    uniqueOptionsReverse[label] = key;
  });
  const uniqueOptions = {};
  Object.keys(uniqueOptionsReverse).forEach((key) => {
    const originalKey = uniqueOptionsReverse[key];
    uniqueOptions[originalKey] = { ...types[originalKey], label: key };
  });

  return (
    <SelectListContainer position={position}>
      {/* Full screen container to keep dropdown showing until a click to dismiss */}
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "fixed",
          top: 0,
          left: 0,
        }}
        onClick={onHide}
      ></div>
      {/* Autocomplete field to select a field type for the field */}
      <Autocomplete
        size="small"
        options={Object.keys(uniqueOptions)
          .map((key) => ({
            id: key,
            label: uniqueOptions[key].label,
            category: uniqueOptions[key].category,
          }))
          .sort((a, b) => a.category - b.category)}
        groupBy={(option) => option.category}
        getOptionLabel={(option) => option.label}
        openOnFocus={true}
        value={data ?? null}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            placeholder={placeholder ?? "Field Type..."}
          />
        )}
        renderGroup={(params, i) => (
          <Box key={`${params.key}=${i}`}>
            <Typography
              variant="body1"
              color="primary"
              sx={{ pt: 0.5, pb: 0.5, pl: 1, fontWeight: "bold" }}
            >
              {params.group}
            </Typography>
            {params.children}
          </Box>
        )}
        onChange={(e, v) => {
          setField(v?.id);
          onHide();
        }}
      />
    </SelectListContainer>
  );
};

export const FloatingButtonContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px;
  border-radius: 6px;
  background: #f2f2f2;
`;

const SelectListContainer = styled.div`
  position: absolute;
  top: 0;
  ${(props) => (props.position === "left" ? "right: 110%" : "left: 110%")};
  width: 140px;
  background: white;
  padding: 6px 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 10;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px dashed grey;
  padding: 4px 6px;
  margin-right: 5px;
  min-height: 60px;
  flex: 1 1 100%;
  // Hover effect to condense rows when not in focus
  // This is a cool concept but it feels bad
  // max-height: 160px;
  // overflow-y: hidden;
  // &:hover {
  //   max-height: unset;
  // }
`;

export const InputShim = styled.div`
  width: 8px;

  display: flex;
  align-self: stretch;

  cursor: pointer;
`;

export const DropInputContainer = styled.div`
  display: block;

  align-items: "center";
  justify-content: "center";
  cursor: "pointer";

  position: relative;
  display: flex;
  flex-direction: column;

  border: 1px dashed grey;
  padding: 4px 6px;
  margin-right: 5px;

  flex: 1 1 100%;
`;
