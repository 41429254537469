import React, { useState, useLayoutEffect } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import { GenericField } from "../../ui/inputs2";
import { Button2 } from "../../ui/buttons";
import q from "query-it";
import { clean_phone_number } from "../../../tools";

export default ({ userData, nextState }) => {
  const [phone, setPhone] = useState(null);
  const [confirmCode, setConfirmCode] = useState(null);
  const [verifierReady, setVerifierReady] = useState(false);
  const [confirmationObject, setConfirmationObject] = useState(undefined);

  const [authError, setAuthError] = useState(undefined);

  // Prep verifier
  useLayoutEffect(
    () =>
      q.auth.useRecaptchaComponent(
        "visible-recaptcha",
        () => setVerifierReady(true),
        () => setVerifierReady(false)
      ),
    []
  );

  const checkAndSendPhoneNumber = () => {
    setAuthError(undefined);
    // First, check that this number matches the one on file
    const candidateNumber = clean_phone_number(phone);
    if (candidateNumber !== userData.phone) {
      setAuthError(
        "The phone number provided does not match the one invited by this invitation"
      );
      return;
    }
    // Now let's send the code
    q.auth
      .linkWithPhoneNumber(candidateNumber)
      .then((confirmationResult) => {
        // Now we'll need to pass the code, so set the stage to stage 3 and save the confirmation result object for submission
        setConfirmationObject(confirmationResult);
      })
      .catch((err) => setAuthError("Phone number not eligible for Query-It"));
  };

  const handleCodeConfirmation = () => {
    if (confirmCode === null) {
      setAuthError("No code provided");
      return;
    }
    // Now let's confirm the code
    confirmationObject
      .confirm(confirmCode)
      .then(() => {
        nextState();
      })
      .catch((err) => {
        setAuthError("The code provided does not match the one sent to you");
      });
  };

  return (
    <>
      <Typography style={{ marginBottom: 3 }}>
        Because this is your first time with us, we need to confirm your mobile
        number for authentication.
      </Typography>
      {authError && (
        <Typography style={{ color: "red" }}>{authError}</Typography>
      )}
      {/* Stage 1 getting phone number ready */}
      {confirmationObject === undefined && (
        <>
          <GenericField
            label="Phone Number"
            contrast
            data={phone}
            onChange={(e) => {
              const { value } = e.target;
              setPhone(value);
            }}
          />
        </>
      )}
      {/* Stage 2 getting code to confirm */}
      {confirmationObject !== undefined && (
        <>
          <Typography style={{ marginBottom: 3 }}>
            Please enter the code we just sent to your mobile number.
          </Typography>
          <GenericField
            label="Enter your code"
            contrast
            data={confirmCode}
            onChange={(e) => {
              const { value } = e.target;
              setConfirmCode(value);
            }}
          />
        </>
      )}
      <br />
      <Captcha
        id="visible-recaptcha"
        style={{ display: confirmationObject ? "none" : undefined }}
      />
      {/* Now buttons for each stage (to keep captcha permanently rendered) */}
      {confirmationObject === undefined && (
        <>
          <br />
          <Button2
            disabled={!verifierReady}
            onClick={checkAndSendPhoneNumber}
            label="Send Code"
          />
        </>
      )}
      {confirmationObject !== undefined && (
        <>
          <br />
          <Button2 onClick={handleCodeConfirmation} label="Confirm Code" />
        </>
      )}
    </>
  );
};

const Captcha = styled.div`
  width: 304px;
  height: 78px;
  margin: auto;
  margin-top: 16px;
`;
