import { Tooltip } from "@mui/material";
import { Check, Assignment, QuestionAnswer } from "@mui/icons-material";
import { format } from "date-fns";
import React from "react";
import styled from "@emotion/styled";
import { is_overdue, parse_db_timestamp } from "../../tools";

import { QueryStatusTag } from "../project/query/query";
import { isMobile } from "react-device-detect";

export const ActionTileScroller = ({ actions, onTileClick }) => {
  const isOverdue = (action) => {
    let dueDate;
    if (action.type === "tasks") {
      dueDate = parse_db_timestamp(action.dueDate);
    } else {
      // Due date is Query due date
      dueDate = parse_db_timestamp(action.query?.dueDate);
    }
    return is_overdue(dueDate);
  };

  return (
    <ActionTileScrollWay>
      {actions.map((act) => {
        if (isMobile && act.type === "tasks") {
          return;
        }
        return (
          <ActionTile
            key={act.id}
            title={act.query.dynamicId ?? act.query.id}
            status={act.status}
            type={act.type}
            due={
              act.type === "tasks"
                ? parse_db_timestamp(act.dueDate)
                : parse_db_timestamp(act.query?.dueDate)
            }
            description={
              act.type === "tasks" && act.dynamicId ? act.dynamicId : ""
            }
            onClick={() => onTileClick(act.projectsId, act.queriesId, act.type)}
            overdue={isOverdue(act)}
          />
        )
      })}
    </ActionTileScrollWay>
  );
};

export const ActionTile = ({
  title,
  status,
  overdue,
  description,
  type,
  onClick,
  due,
}) => {
  return (
    <ActionTileBox onClick={onClick}>
      <ActionTileTop>
        <ActionTileTitling>
          <ActionTileTitle>{title}</ActionTileTitle>
          {overdue && (
            <QueryStatusTag style={{ margin: "8px 0px 0px 0px" }} color="red">
              OVERDUE
            </QueryStatusTag>
          )}
        </ActionTileTitling>
        {/* Then resolve the icon based on type */}
        {type === "approvals" && (
          <Tooltip title="Approval">
            <Check />
          </Tooltip>
        )}
        {type === "tasks" && (
          <Tooltip title="Task">
            <Assignment />
          </Tooltip>
        )}
        {type === "responses" && (
          <Tooltip title="Response">
            <QuestionAnswer />
          </Tooltip>
        )}
      </ActionTileTop>
      <ActionDescription>
        Due Date: {due ? format(due, "dd-MMM-yyyy") : "N/A"}
        <br />
        {description}
      </ActionDescription>
    </ActionTileBox>
  );
};

const ActionTileBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  min-width: 200px;
  height: 100px;
  margin: 0 8px;
  padding: 6px;
  border-radius: 3px;

  cursor: pointer;

  background: ${(props) => props.theme.palette.background.step100};
  color: ${(props) => props.theme.palette.text.primary};
`;

const ActionTileTop = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  justify-content: space-between;
`;

const ActionTileTitling = styled.div`
  max-width: 88%;
`;

const ActionTileTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 1.1rem;
  margin-right: 8px;
`;

const ActionTileScrollWay = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 0 4px 0;
  margin: 8px 0 16px 0;
  max-width: 95%;

  overflow-x: auto;
`;

const ActionDescription = styled.div`
  font-size: 14px;
`;
