import React, { useState, useContext, useEffect } from "react";
import styled from "@emotion/styled";
import { TableChart, Toc } from "@mui/icons-material";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { useSearchParams } from "react-router-dom";

import { UserContext } from "../../App";
import { FullPageContainer } from "../ui/containers";
import { DataTable } from "../ui/table";
import { ActionTileScroller } from "./tiles";
import { CircularProgress } from "@mui/material";

import FilterSelector from "../project/filter/panel";
import { atomicUseFilterableElement } from "../../hooks/filters";
import { SearchBar } from "../project/controls";
import { useProjects } from "../../hooks/projects";
import { polish_for_freeze } from "../../tools";
import { StatusFilterDropdown } from "../project/controls/statuspicker";
import { ProjectUsersContext } from "../project";
import { QUERYVIEWS } from "../../common/query";
import { ACTIONVIEWS } from "../project/query/actions";

const ActionsState = atom({
  key: "globalActions",
  default: [],
});
export const [FilteredActionsState, actionFilterManager] =
  atomicUseFilterableElement(ActionsState);

export default () => {
  const user = useContext(UserContext);
  const projects = useProjects(user);
  const [viewState, setViewState] = useState(0); // 0 = tiles, 1 = table

  const [actions, setActions] = useState(undefined); // Actions pulled from db
  const [users, setUsers] = useState(undefined); // Users pulled from db
  const [displayActions, setDisplayActions] = useState(undefined); // Actions filtered and prepped for display
  const [displayData, setDisplayData] = useState(undefined); // Data to show off actions properly

  // Get search params state
  const [searchParams, setSearchParams] = useSearchParams();

  // Filters
  const [filtersPaneVisible, setFiltersPaneVisible] = useState(false);
  actionFilterManager.init();
  const filteringActive = actionFilterManager.useFilterActive();

  useEffect(() => {
    // Setup the const filterscheme
    actionFilterManager.setFilterScheme(ACTIONS_FILTER_SCHEME(projects ?? []));
  }, [projects]);

  // This useEffect will get the actions for the user
  // NOTE: THIS EXECUTION IS HORRIBLE. IT IS THE BEST WE CAN DO WITH THIS DATA STRUCTURE, BUT THE STRUCTURE SHOULD CHANGE
  useEffect(() => {
    if (!user) {
      return;
    } // Guard until user ready
    // Return so that unsub can run on unmount to cleanup
    let childSubUnsubs = [];
    let parentSubUnsub = user.projects.forEach((project) => {
      // Retrieve actions
      let sb = project.actions.listen(async (projectActions) => {
        // Assume no other execution will touch these actions, so we can pre-fetch their queries in peace
        // This is untrue, but if we don't assume it, we'll tear our hair out
        // Before rendering, filter away actions not assigned to us!

        let filterFromListen = projectActions.filter(
          (action) => action.assignedTo === user.ref.id
        );

        // Now let's render and compile them
        let newActions = await Promise.all(
          filterFromListen
            .filter((a) => actions?.find((b) => b.id === a.id) === undefined)
            .map(async (a) => {
              let query = await project.queries.query(a.queriesId).get();
              return {
                ...a,
                query:
                  Object.keys(query).length > 0
                    ? polish_for_freeze([query])[0]
                    : undefined,
              };
            })
        );
        newActions = newActions.filter((a) => a.query !== undefined);

        setActions((ex) => ({
          ...(ex ?? {}), // Allow all existing projects to keep existing
          ...{
            [project.ref.id]: {
              // Iterate over a unique set of schemaIds which is the set of newAction schemas
              ...newActions
                .map((a) => a.query?.schemaId)
                .filter((val, ind, self) => self.indexOf(val) === ind)
                .reduce(
                  (acc, schemaKey) => ({
                    ...acc,
                    [schemaKey]: newActions
                      .filter((a) => a.query?.schemaId === schemaKey)
                      .map((a) => ({ ...a, schemasId: schemaKey })),
                  }),
                  {}
                ),
            },
          },
        }));
      });
      childSubUnsubs.push(sb);
      // Retrieve users
      let us = project.users.listen(async (users) => {
        // SetUsers needs to be a list because that's what filtering expects
        // But using the uniqueness of an object is really convenient, so map back and forth :)
        setUsers((ex) =>
          Object.values({
            ...(ex?.reduce((acc, user) => ({ ...acc, [user.id]: user }), {}) ??
              {}),
            ...(users?.reduce(
              (acc, user) => ({ ...acc, [user.id]: user }),
              {}
            ) ?? {}),
          })
        );
      });
      childSubUnsubs.push(us);
    });
    return () => {
      parentSubUnsub();
      childSubUnsubs.forEach((unsub) => unsub());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // We'll also need a useEffect to get all of the Projects and Schemas for the user
  // So that we can use their titles and such for the view
  useEffect(() => {
    if (!user) {
      return;
    } // Guard until user ready
    // Return so that unsub can run on unmount to cleanup
    let parentSubUnsub = user.projects.forEach(async (project) => {
      // First get project name
      let dt = await project.get();
      // Now get all the schema names as a {schemaId: schemaName} object
      let schemas = {};
      (await project.schemas.get()).forEach((schemaQ) => {
        schemas[schemaQ.id] = schemaQ.name;
      });
      setDisplayData((ex) => ({
        ...ex,
        [project.ref.id]: { __name__: dt.name, schemas: schemas },
      }));
    });
    return () => {
      parentSubUnsub();
    };
  }, [user]);

  // Now pickup actions into the actions filter state, spitting it back out again for rendering
  const setActionsState = useSetRecoilState(ActionsState);
  useEffect(
    () =>
      setActionsState(
        polish_for_freeze(
          // Cut actions down to a list from a 2d dict (project => schema => actionArray)
          Object.values(actions ?? {})
            .map((schmObj) =>
              Object.values(schmObj).reduce((acc, cur) => [...cur, ...acc], [])
            )
            .reduce((acc, cur) => [...cur, ...acc], [])
        )
      ),
    [actions, setActionsState]
  );

  // And we'll listen for the state of the filter to use that in render here
  const filteredActions = useRecoilValue(FilteredActionsState);
  useEffect(() => {
    // Reconstruct a 2d dict from the actions after filtering
    setDisplayActions(
      filteredActions?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.projectsId]: {
            ...(acc?.[cur.projectsId] ?? {}),
            [cur.schemasId]: [
              ...(acc?.[cur.projectsId]?.[cur.schemasId] ?? []),
              cur, // The current action gets appended here :)
            ],
          },
        }),
        {}
      )
    );
  }, [filteredActions]);

  return (
    <FullPageContainer style={{ overflowX: "hidden", flexDirection: "column" }}>
      <ProjectUsersContext.Provider value={users}>
        {/* Start with top bar, holds search, filtering and then view toggle on right side */}
        <ViewEditors>
          <FiltersContainer>
            {/* Search goes here */}
            <SearchBar
              filteringActive={filteringActive}
              toggleFilters={() => setFiltersPaneVisible((ex) => !ex)}
            />
            <StatusFilterDropdown filterManager={actionFilterManager} />
          </FiltersContainer>
          <ViewScroller>
            <ViewScrollerButton
              onClick={() => setViewState(0)}
              selected={viewState === 0}
            >
              <Toc />
            </ViewScrollerButton>
            <ViewScrollerButton
              onClick={() => setViewState(1)}
              selected={viewState === 1}
            >
              <TableChart />
            </ViewScrollerButton>
          </ViewScroller>
        </ViewEditors>
        {/* TILE VIEW */}
        {/* Now we do the list of actions based on their Project, then further by their schema */}
        {viewState == 0 && (
          <DataDisplay>
            {displayActions &&
              displayData &&
              Object.keys(displayActions).map((projectKey) =>
                Object.keys(displayActions[projectKey])
                  .filter((k) => k !== "undefined")
                  .map((schemaKey) => {
                    return (
                      <ActionTileSection
                        key={`tile-sec-${projectKey}-${schemaKey}`}
                      >
                        <ActionTileTitle>{`${displayData[projectKey]?.__name__} - ${displayData[projectKey]?.schemas[schemaKey]}`}</ActionTileTitle>
                        <ActionTileScroller
                          actions={displayActions[projectKey][schemaKey]}
                          onTileClick={(projectId, queryId, type) => {
                            let ar = ACTIONVIEWS.QUERY;
                            switch (type) {
                              case "responses":
                                ar = ACTIONVIEWS.RESPONSE;
                                break;
                              case "approvals":
                                ar = ACTIONVIEWS.APPROVAL;
                                break;
                              case "tasks":
                                ar = ACTIONVIEWS.TASK;
                                break;
                            }
                            setSearchParams({
                              qq: `${projectId}-${queryId}`,
                              qr: QUERYVIEWS.ACTIONS,
                              ar,
                            });
                          }}
                        />
                      </ActionTileSection>
                    );
                  })
              )}
            {/* TODO: Add a 'No more schemas with open actions' message at the bottom */}
            {(!displayActions || !displayData) && (
              <Centerer>
                <CircularProgress />
              </Centerer>
            )}
            {displayActions &&
              displayData &&
              !Object.values(displayActions).some(
                (s) => Object.values(s).length > 0
              ) && (
                <CenteredStatusMessage>
                  All caught up! <br />
                  New actions assigned to you will appear here
                </CenteredStatusMessage>
              )}
          </DataDisplay>
        )}
        {/* TABLE VIEW */}
        {/* This will be a single table, and project, schema, etc will be columns that can be sorted */}
        {viewState == 1 && (
          <DataDisplay>
            <DataTable
              data={Object.keys(displayActions ?? {}).reduce(
                (acc, projectKey) => [
                  ...acc,
                  ...Object.keys(displayActions[projectKey]).reduce(
                    (acc2, schemaKey) => [
                      ...acc2,
                      ...displayActions[projectKey][schemaKey].map(
                        (action) => ({
                          ...action,
                          project: displayData?.[projectKey]?.__name__,
                          schema: displayData?.[projectKey]?.schemas[schemaKey],
                        })
                      ),
                    ],
                    []
                  ),
                ],
                []
              )}
              columns={ACTION_TABLE_COLUMNS}
              loading={!displayActions || !displayData}
              onRowClick={({ row }) =>
                setSearchParams({
                  qq: `${row.col_projectid}-${row.col_queryid}`,
                })
              }
            />
          </DataDisplay>
        )}

        {filtersPaneVisible && (
          <FilterSelector
            setOpen={setFiltersPaneVisible}
            filterManager={actionFilterManager}
          />
        )}
      </ProjectUsersContext.Provider>
    </FullPageContainer>
  );
};

const ACTIONS_FILTER_SCHEME = (projects) => [
  {
    name: "Project",
    index: "projectsId",
    type: "select",
    options: projects
      .map((p) => ({
        value: p.id,
        label: p.name,
      }))
      .reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {}),
  },
  {
    name: "Status",
    index: "status",
    type: "select",
    options: {
      open: "Open",
      closed: "Closed",
      assigned: "Assigned",
    },
  },
  {
    name: "Action Type",
    index: "type",
    type: "select",
    options: {
      approvals: "Approval",
      tasks: "Task",
      responses: "Response",
    },
  },
  {
    name: "Action Creator",
    index: "creator",
    type: "user",
  },
  {
    name: "Created Date",
    index: "created",
    type: "date",
  },
  {
    name: "Due Date",
    index: "due",
    type: "date",
  },
];

const ACTION_TABLE_COLUMNS = [
  {
    headerName: "Type",
    index: "type",
  },
  {
    headerName: "Project",
    index: "project",
  },
  {
    headerName: "Schema",
    index: "schema",
  },
  {
    headerName: "Query",
    format: (row) => row.query?.dynamicId?.toUpperCase() ?? row.query.id,
  },
  {
    headerName: "Action Title",
    index: "data.title",
    format: (row) => row.data.title ?? "",
  },
  {
    headerName: "Status",
    index: "status",
    decorate: "badge",
  },
  {
    headerName: "projectId",
    index: "projectsId",
    hide: true,
  },
];

const ViewEditors = styled.div`
  display: flex;
  margin: 10px 0;
  height: 40px;
  width: 100%;
  justify-content: space-between;
`;

const ViewScroller = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.palette.background.step100};
  border-radius: 3px;
  height: 40px;
  width: 76px;
  margin-right: 10px;
`;

const ViewScrollerButton = styled.div`
  padding: 2px 4px;
  margin: 3px;
  font-size: 14px;

  cursor: pointer;
  border-radius: 5px;
  background: ${(props) =>
    props.selected ? props.theme.palette.background.step50 : "unset"};
  &:hover {
    background: ${(props) => props.theme.palette.background.step50};
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  height: 40px;
  margin-left: 10px;
  color: ${(props) => props.theme.palette.text.primary};
`;

const DataDisplay = styled.div`
  display: flex;
  flex-direction: column;

  margin: 8px 16px;
  font-family: "Roboto", sans-serif;
  height: 100%;
`;

const ActionTileSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  width: 100%;
`;

const ActionTileTitle = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
`;

const Centerer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CenteredStatusMessage = styled(Centerer)`
  color: ${(props) => props.theme.palette.text.primary};
  text-align: center;
  font-size: 14px;
`;
